import * as React from 'react';
import { useSetState } from 'react-use';

export interface State {
  [key: string]: boolean;
}

interface LoaderContextState {
  loader?: State;
  setLoader: (loader: State) => void;
}

const defaultContextValue: LoaderContextState = {
  loader: undefined,
  setLoader: () => undefined,
};

export const LoaderContext = React.createContext<LoaderContextState>(defaultContextValue);

export const LoaderContextProvider: React.FC = ({ children }) => {
  const [loader, setLoader] = useSetState<State>();

  return (
    <LoaderContext.Provider value={{ loader: loader, setLoader }}>
      <>{children}</>
    </LoaderContext.Provider>
  );
};
