import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { Card, Space, ButtonGroup, Button, Loader } from 'ebs-design';
import { Tooltip as CustomTooltip } from 'components/molecules/Charts';
import { getColor } from 'components/molecules/Charts/utils';
import { useDashboardAPI } from 'api';
import { format, dateFormat } from 'libs';
import { abbreviateNumber } from 'utils';
import { DashboardProps, ChartType } from 'types';

const color = getColor(4);
const thirdColor = getColor(4, 'third');

export const ChartReports: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const { getReportsChart } = useDashboardAPI();
  const [chart_type, setChartType] = React.useState<ChartType>('disbursements');
  const [activeDotIndex, setActiveDotIndex] = React.useState<string>();

  const { data = [], isLoading } = useQuery<any>(
    ['reports-chart', { ...params, chart_type }],
    getReportsChart,
    {
      select: (response) =>
        response.map(({ value, date, count_value }) => ({
          value,
          count_value,
          date: format(
            date,
            dateFormat
              .split('.')
              .slice(0, !params.currentYear ? 3 : 2)
              .join('.'),
          ),
        })),
    },
  );

  return (
    <Card>
      <Card.Header>
        <Space justify="space-between">
          {t('reports.graphicReports')}

          <ButtonGroup>
            <Button
              type={chart_type === 'disbursements' ? 'primary' : 'ghost'}
              onClick={() => setChartType('disbursements')}
            >
              {t('reports.disbursements')}
            </Button>
            <Button
              type={chart_type === 'applications' ? 'primary' : 'ghost'}
              onClick={() => setChartType('applications')}
            >
              {t('menu.applications')}
            </Button>
            <Button
              type={chart_type === 'calls' ? 'primary' : 'ghost'}
              onClick={() => setChartType('calls')}
            >
              {t('menu.calls')}
            </Button>
            <Button
              type={chart_type === 'visits' ? 'primary' : 'ghost'}
              onClick={() => setChartType('visits')}
            >
              {t('reports.visits')}
            </Button>
          </ButtonGroup>
        </Space>
      </Card.Header>

      <Loader loading={isLoading}>
        <Card.Body className="chart__container pb-0 pl-0">
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <defs>
                <linearGradient id="colorLine1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient id="colorLine2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={thirdColor} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={thirdColor} stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid vertical={false} strokeDasharray="4 0" />
              <XAxis dataKey="date" scale="band" />
              <YAxis
                yAxisId="left"
                tick={{ fill: color }}
                orientation="left"
                scale="auto"
                type="number"
                tickFormatter={(value) => abbreviateNumber(value)}
              />
              <YAxis
                yAxisId="right"
                tick={{ fill: thirdColor }}
                orientation="right"
                scale="auto"
                type="number"
                dataKey="count_value"
              />
              <Tooltip
                content={({ active, payload }) =>
                  active && payload ? (
                    <>
                      {activeDotIndex ? (
                        <CustomTooltip
                          title={payload[activeDotIndex].value}
                          content={payload[activeDotIndex].payload.date}
                        />
                      ) : null}
                    </>
                  ) : null
                }
              />
              <Legend />
              <Area
                type="monotone"
                yAxisId="left"
                dataKey="value"
                name={t('report1C.disbursementsSum')}
                stroke={color}
                fillOpacity={1}
                fill="url(#colorLine1)"
                activeDot={{
                  stroke: color,
                  strokeWidth: 4,
                  onMouseOver: () => setActiveDotIndex('0'),
                  onMouseLeave: () => setActiveDotIndex(undefined),
                }}
                dot={{ stroke: 'white', strokeWidth: 3, r: 6, fill: color }}
              />
              <Area
                type="monotone"
                yAxisId="right"
                dataKey="count_value"
                name={t('report1C.disbursementsNum')}
                stroke={thirdColor}
                fillOpacity={1}
                fill="url(#colorLine2)"
                activeDot={{
                  stroke: thirdColor,
                  strokeWidth: 4,
                  onMouseOver: () => setActiveDotIndex('1'),
                  onMouseLeave: () => setActiveDotIndex(undefined),
                }}
                dot={{ stroke: 'white', strokeWidth: 3, r: 6, fill: thirdColor }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Card.Body>
      </Loader>
    </Card>
  );
};
