import * as React from 'react';
import { default as queryString } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Properties } from 'types';

export const useQueryParams = (): Properties => {
  const { search } = useLocation();
  return React.useMemo(() => queryString.parse(search, { arrayFormat: 'comma' }), [search]);
};

export const useQueryUpdate = (): Properties => {
  const history = useHistory();
  const { push, location } = history;

  const updateQuery = React.useCallback(
    (filters) => {
      const { pathname, search } = location;
      const pushURL = `${pathname}?${queryString.stringify(filters, {
        skipEmptyString: true,
        skipNull: true,
        arrayFormat: 'comma',
      })}`;
      if (pathname + search !== pushURL.replace(/\?+$/g, '')) {
        push(pushURL);
      }
    },
    [push, location],
  );

  return {
    updateQuery,
  };
};
