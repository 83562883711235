import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Textarea,
  useForm,
  useNotify,
} from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { SmartSelect, DocumentForm } from 'components/organisms';

import {
  useContactAPI,
  useCurrenciesAPI,
  useOpportunitiesAPI,
  useProductsAPI,
  useUsersAPI,
  useLeadsAPI,
  useDocumentsAPI,
} from 'api';
import { SessionContext } from 'contexts/SessionContext';
import { listTransformResults, userTransformResults } from 'utils';
import { dateFormat, dateFormatAPI, dateFormatInput, format } from 'libs';
import { ValidationError } from 'errors';
import { Contact, Opportunity, OpportunityStatus, Lead } from 'types';
import { ConfirmModal } from 'components/molecules';
import { Report1C } from './Report1C';
import { DocumentItem } from '../DocumentCard/DocumentItem';
import { Check } from 'resources/icons';

interface OpportunityFormProps {
  id?: number;
  formId: string;
  closeModal: () => void;
}

const formDocumentId = 'add-document-opportunity';

export const OpportunityForm: React.FC<OpportunityFormProps> = ({ id, formId, closeModal }) => {
  const { t } = useTranslation();
  const { id: leadId } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { session } = React.useContext(SessionContext);
  const { loader } = React.useContext(LoaderContext);
  const { getUsers } = useUsersAPI();
  const { getOpportunityById, postOpportunity, patchOpportunity } = useOpportunitiesAPI();
  const { getCurrenciesList } = useCurrenciesAPI();
  const { deleteDocument } = useDocumentsAPI();
  const { getLeadContacts, sendViber } = useContactAPI();
  const { getProducts } = useProductsAPI();
  const { getLeadById } = useLeadsAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);
  const [report1CId, setReport1CId] = React.useState();
  const [editId, setEditId] = React.useState();
  const [deleteId, setDeleteId] = React.useState();
  const [sendId, setSendId] = React.useState();

  const { data } = useQuery<Opportunity>(['opportunity', { id }], getOpportunityById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: lead } = useQuery<Lead>(['lead', { id: leadId }], getLeadById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: contacts, isLoading: isLoadingContact } = useQuery<Contact[]>(
    ['lead-contacts', { id: leadId }],
    getLeadContacts,
    {
      enabled: Boolean(leadId),
    },
  );

  React.useEffect(() => {
    if (data) {
      const { currency, owner, product, close_date, contact, ...formData } = data;
      form.setFieldsValue({
        ...formData,
        contact: contact?.id,
        currency: currency?.id,
        product: product?.id,
        owner: owner?.id,
        close_date: close_date && format(close_date, dateFormat),
      });
    } else {
      form.setFieldsValue({
        contact: lead?.contact?.id,
        owner: session?.data?.user.sso_id,
      });
    }
  }, [data]);

  const mutation = useMutation(id ? patchOpportunity : postOpportunity, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['opportunities']);
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      closeModal();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const mutationDeleteGraph1C = useMutation<unknown, unknown, number>(deleteDocument, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['opportunity']);

      setDeleteId(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const mutationSend = useMutation(sendViber, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      handleCancelSend();
      notify.success({ title: t('success.sendDocument') });
    },
  });

  const handleSubmit = (): void => {
    const { close_date, ...rest } = form.getFieldsValue();
    mutation.mutate({
      ...rest,
      close_date: close_date && format(close_date, dateFormatAPI, dateFormat),
      id,
      lead: leadId,
    });
  };

  const handleSend = (values): void => {
    mutationSend.mutate({ ...values, attachment: sendId, id: data?.contact?.id });
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  const onEdit = (id): void => {
    setEditId(id);
  };

  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const onSend = (id): void => {
    setSendId(id);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutationDeleteGraph1C.mutate(deleteId);
    }
  };

  const handleClose = (): void => {
    setEditId(undefined);
  };

  const handleCancelSend = (): void => {
    setSendId(undefined);
  };

  const buttonLoading = React.useMemo(() => {
    if (loader) {
      return loader[formDocumentId];
    }
    return false;
  }, [loader]);

  const generateForm1C = (id): void => {
    setReport1CId(id);
  };

  return (
    <>
      <Form id={formId} form={form} onFinish={handleSubmit}>
        <Row>
          <Col size={6} className="mt-10">
            <Form.Field name="value" label={t('task.value')} rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="currency" label={t('task.currency')} rules={[{ required: true }]}>
              <SmartSelect
                search
                api={getCurrenciesList}
                mode="single"
                transform={listTransformResults}
                placeholder={t('main.select')}
                selected={data?.currency && { value: data.currency.id, text: data.currency.name }}
              />
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="contact" label={t('task.contact')}>
              <Select loading={isLoadingContact} placeholder={t('main.select')}>
                <Select.Options>
                  {contacts?.map((item, key) => (
                    <Select.Options.Item value={item.id} key={`contact-${key}`}>
                      {item?.first_name || item.last_name
                        ? [item?.first_name, item.last_name].join(' ')
                        : '---'}
                    </Select.Options.Item>
                  ))}
                </Select.Options>
              </Select>
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="owner" label={t('task.owner')}>
              <SmartSelect
                search
                api={getUsers}
                mode="single"
                placeholder={t('main.select')}
                transform={userTransformResults}
                selected={
                  data
                    ? data?.owner && {
                        value: data.owner.id,
                        text: `${data.owner?.first_name} ${data.owner.last_name}`,
                      }
                    : {
                        value: session?.data?.user.sso_id,
                        text:
                          session?.data?.user?.first_name || session?.data?.user.last_name
                            ? `${session?.data?.user?.first_name} ${session?.data?.user.last_name}`
                            : session?.data?.user.email,
                      }
                }
              />
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="product" label={t('task.product')}>
              <SmartSelect
                search
                api={getProducts}
                mode="single"
                placeholder={t('main.select')}
                selected={data?.product && { value: data.product.id, text: data.product.name }}
              />
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="close_date" label={t('task.close_date')} rules={[{ required: true }]}>
              <DatePicker dateFormat={dateFormatInput} />
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="status" label={t('task.status')}>
              <Select placeholder={t('main.select')}>
                <Select.Options>
                  {Object.values(OpportunityStatus).map((item, key) => (
                    <Select.Options.Item value={item} key={`status-${key}`}>
                      {t(`opportunities.${item}`)}
                    </Select.Options.Item>
                  ))}
                </Select.Options>
              </Select>
            </Form.Field>
          </Col>
          <Col size={6} className="mt-10">
            <Form.Field name="term" label={t('task.term')} rules={[{ required: true }]}>
              <Input type="number" />
            </Form.Field>
          </Col>
          <Col size={12} className="mt-10">
            <Form.Field name="notes" label={t('task.notes')} className="textarea-static-width">
              <Textarea />
            </Form.Field>
          </Col>
        </Row>
      </Form>
      <Row className="mt-10">
        {data?.id && (
          <Col size={6}>
            <Button onClick={() => generateForm1C(id)} type="ghost">
              {t('opportunities.generate1CGraph')}
            </Button>
          </Col>
        )}
        {data?.documents?.length ? (
          <Col size={6}>
            {data?.documents.map((document, key) => (
              <DocumentItem
                lead={lead}
                key={key}
                className="document-opportunity"
                document={document}
                onEdit={onEdit}
                onDelete={onDelete}
                onSend={onSend}
              />
            ))}
          </Col>
        ) : null}
      </Row>
      {editId && (
        <Modal closeOnClickOutside={false} open header={t('lead.documents')} onClose={handleClose}>
          <Modal.Content>
            <DocumentForm id={editId} formId={formDocumentId} closeModal={handleClose} />
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
              <Button
                type="primary"
                submit
                form={formDocumentId}
                loading={buttonLoading}
                prefix={<Icon className="action-card__save-button" component={Check} />}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}
      {report1CId && (
        <Report1C onCancel={() => setReport1CId(undefined)} opportunityId={data?.id} />
      )}

      {sendId && (
        <Modal
          closeOnClickOutside={false}
          open
          header={t('document.sendDocumentToViber')}
          onClose={handleCancelSend}
        >
          <Form onFinish={handleSend}>
            <Modal.Content>
              <Form.Field name="text_message" label={t('main.text')}>
                <Textarea />
              </Form.Field>
            </Modal.Content>
            <Modal.Footer>
              <Space justify="space-between">
                <Button onClick={handleCancelSend}>{t('buttons.cancel')}</Button>
                <Button
                  type="primary"
                  submit
                  loading={mutationSend.isLoading}
                  prefix={<Icon className="action-card__save-button" component={Check} />}
                >
                  {t('buttons.send')}
                </Button>
              </Space>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};
