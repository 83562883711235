import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'ebs-design';
import { KanbanItem } from './KanbanItem';
import { LeadSalesKanbanResponse } from 'types';

interface KanbanColumnProps {
  column: LeadSalesKanbanResponse;
}

export const KanbanColumn: React.FC<KanbanColumnProps> = ({ column }) => {
  const { t } = useTranslation();
  return (
    <div className="kanban-column">
      <Space justify="space-between" align="start" className="kanban-column__header">
        <div className="kanban-column__title">{column.title}</div>
        <div className="kanban-column__count">{column.data.length}</div>
      </Space>
      <Space justify="space-between" className="kanban-column__value">
        <span className="kanban-column__value__label">{t('lead.value')}</span>
        <Space>
          <span className="kanban-column__value__label">
            {column.sum ? column.sum.toFixed(2) : '0'}
          </span>
          <span className="kanban-column__value__label">{[column.currency].join(' ')}</span>
        </Space>
      </Space>
      <div className="kanban-column__body">
        {column.data.map((lead, key) => (
          <KanbanItem key={key} leadView={lead} />
        ))}
      </div>
    </div>
  );
};
