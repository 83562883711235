import {
  ServiceConfig,
  ServiceTypes,
  SessionData,
  StatusTypes,
  Tokens,
} from 'hooks/useFetcher/interfaces';
import { BootstrapConfig, BootstrapOptions, BootstrapState } from './interface';
import { getTokenFromUrl } from 'utils';

type CheckCookieResult = [Error | null, Tokens | null];
type FetchUserResult = [Error | null, SessionData | null];
type StateHandler = React.Dispatch<React.SetStateAction<BootstrapState>>;

export const checkCookie = async (config: ServiceConfig): Promise<CheckCookieResult> => {
  const url = `${config.baseUrl}/authorization/token/cookie/`;
  let tokens: Tokens | null = null;
  let error: Error | null = null;

  try {
    const res = await fetch(url, { method: 'GET', credentials: 'include' });
    const data = await res.json();

    if (res.ok) {
      tokens = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e: any) {
    error = e;
  }

  return [error, tokens];
};

export const refreshToken = async (
  config: ServiceConfig,
  token: string,
): Promise<CheckCookieResult> => {
  const SaasAppToken = getTokenFromUrl();
  const url = `${config.baseUrl}/authorization/token/refresh/`;
  let tokens: Tokens | null = null;
  let error: Error | null = null;

  try {
    const res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ['Saas-app-token']: SaasAppToken,
      },
      body: JSON.stringify({
        refresh: token,
      }),
    });
    const data = await res.json();

    if (res.ok) {
      tokens = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e: any) {
    error = e;
  }

  return [error, tokens];
};

export const fetchUser = async (
  config: ServiceConfig,
  tokens: Tokens,
): Promise<FetchUserResult> => {
  const url = `${config.baseUrl}/users/login/user/`;
  let session: SessionData | null = null;
  let error: Error | null = null;
  const subdomain = getUrlSubDomain();
  const SaasAppToken = getTokenFromUrl();

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${tokens.access}`,
        ['Saas-app-token']: SaasAppToken,
      },
      body: JSON.stringify({
        domain_name: subdomain,
      }),
    });
    const data = await res.json();

    if (res.ok) {
      session = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e: any) {
    error = e;
  }

  return [error, session];
};

/**
 * This will call the check cookie API, and
 * depending on the response will set the state.
 *
 * @param config - the SSO service config.
 * @param setState - Bootstrap's session seter function.
 * @param options - Bootstrap's additional options
 */
export const handleSessionCheckEffect = (
  config: BootstrapConfig,
  setState: StateHandler,
  options: BootstrapOptions,
): void => {
  (async (): Promise<void> => {
    const [error, tokens] = await checkCookie(config[ServiceTypes.SSO]);

    // FIXME: Find a better way to deal with it
    // WARNING: Refactor this code and the data from hooks
    if (tokens && !options.hasOrganization) {
      setState({
        session: { tokens, data: null },
        status: StatusTypes.RESOLVED,
        error: null,
      });
    } else if (tokens && options.hasOrganization) {
      const [error, data] = await fetchUser(config[ServiceTypes.ORGANIZATION], tokens);

      if (data) {
        setState({
          session: { tokens, data },
          status: StatusTypes.RESOLVED,
          error: null,
        });
      } else if (error) {
        setState((state) => ({
          ...state,
          error,
          status: StatusTypes.REJECTED,
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        error,
        status: StatusTypes.REJECTED,
      }));
    }
  })();
};

export const logout = async (config: ServiceConfig): Promise<CheckCookieResult> => {
  const SaasAppToken = getTokenFromUrl();
  const url = `${config.baseUrl}/authorization/token/logout/`;
  let tokens: Tokens | null = null;
  let error: Error | null = null;

  try {
    const res = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ['Saas-app-token']: SaasAppToken,
      },
    });
    const data = await res.json();

    if (res.ok) {
      tokens = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e: any) {
    error = e;
  }

  return [error, tokens];
};

export const getUrlSubDomain = (): string => {
  return (
    process.env.REACT_APP_ORGANIZATION_DOMAIN_NAME ||
    window.location.hostname.split('.').slice(0, 1).join('.')
  );
};

export const getUrlDomain = (): string => {
  return window.location.hostname;
};
