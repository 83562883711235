import { useLocation } from 'react-router-dom';

const IS_PUBLIC_URL = !!process.env.PUBLIC_URL;

export const isCurrentURl = (url: string): boolean => {
  const location = useLocation();
  return url === location.pathname;
};

export const getTokenFromUrl = (): string => {
  let token = '';

  if (IS_PUBLIC_URL) {
    token = window.location.pathname
      .split('/')
      .filter((i) => i !== '')
      .slice(2, 3)
      .join();
  } else {
    token = window.location.pathname.split('/').filter((i) => i !== '')?.[0];
  }

  return token;
};

/**
 * getBaseUrl is used to get the base url from window
 * location pathname for routes to work properly
 */
export const getBaseUrl = (): string => {
  let path = '';

  if (IS_PUBLIC_URL) {
    path = window.location.pathname
      .split('/')
      .filter((i) => i !== '')
      .slice(0, 3)
      .join('/');
  } else {
    path = !process.env.REACT_APP_SAAS_APP_TOKEN ? getTokenFromUrl() : '';
  }

  return `/${path || ''}`;
};
