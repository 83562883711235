export const labelStatus = {
  1: 'danger',
  2: 'info',
  3: 'success',
};

export const labelText = {
  1: 'task.high',
  2: 'task.medium',
  3: 'task.low',
};
