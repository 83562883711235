import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader, useNotify, Radio, Label } from 'ebs-design';
import { ConfirmModal } from 'components/molecules';
import { ActionCard } from 'features/leads/ActionCard';
import { OpportunityItem } from './OpportunityItem';
import { OpportunityForm } from 'features/leads/DetailsCol/Cards/OpportunityCard';
import { useContactAPI, useOpportunitiesAPI } from 'api';
import { ValidationError } from 'errors';
import { Lead, Opportunity, OpportunityStatus } from 'types';

const formId = 'add-opportunity';

interface OpportunityCardProps {
  lead: Lead;
}

export const OpportunityCard: React.FC<OpportunityCardProps> = ({ lead }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { postInitCall } = useContactAPI();
  const { getLeadOpportunities, deleteOpportunity } = useOpportunitiesAPI();
  const [editId, setEditId] = React.useState();
  const [deleteId, setDeleteId] = React.useState();

  const [isCheckStatus, setIsCheckStatus] = React.useState(false);
  const [isOpenStatusModal, setIsOpenStatusModal] = React.useState(false);
  const [countLastOpportunity, setCountLastOpportunity] = React.useState<number | undefined>();

  const { data, isLoading } = useQuery<Opportunity[]>(
    ['opportunities', { id }],
    getLeadOpportunities,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
      onSuccess: (data) => {
        if (data.length) {
          return setIsCheckStatus(
            ![
              OpportunityStatus.DECLINED,
              OpportunityStatus.REQUEST_APPROVED,
              OpportunityStatus.REQUEST_DECLINED,
              OpportunityStatus.REQUEST_ANNULLED,
            ].includes(data[0]?.status),
          );
        }
      },
    },
  );

  const mutation = useMutation(deleteOpportunity, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['opportunities']);
      setDeleteId(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const initCall = useMutation(postInitCall, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        e?.fields?.map((i) => {
          notify.error({ title: t(i.errors) });
        });
      } else {
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      notify.success({ title: t('success.callWasInit') });
      queryClient.invalidateQueries(['logs']);
    },
  });

  const onCall = (data): void => {
    if (data) {
      initCall.mutate(data);
    }
  };

  const onEdit = (id): void => {
    setEditId(id);
  };

  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutation.mutate(deleteId);
    }
  };

  const handleCheckStatus = (): void => {
    setIsOpenStatusModal(false);

    if (!countLastOpportunity) {
      setEditId(data && (data[0].id as any));
    }
  };

  const handleCloseCheckStatus = (): void => {
    setIsOpenStatusModal(false);
    setCountLastOpportunity(undefined);
  };

  return (
    <>
      <ActionCard
        header={t('lead.opportunities')}
        className="mt-20"
        backgroundColor="#A9152C"
        formId={formId}
        count={data?.length}
        editId={editId}
        unsetEdit={() => setEditId(undefined)}
        FormComponent={OpportunityForm}
        isCheckStatus={isCheckStatus}
        isOpenStatusModal={isOpenStatusModal}
        setIsOpenStatusModal={setIsOpenStatusModal}
        countLastOpportunity={countLastOpportunity}
        setCountLastOpportunity={setCountLastOpportunity}
      >
        <Loader loading={isLoading}>
          {data?.length
            ? data.map((opportunity, key) => (
                <OpportunityItem
                  key={key}
                  opportunity={opportunity}
                  lead={lead}
                  onCall={onCall}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))
            : t('main.noData')}
        </Loader>
      </ActionCard>
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}
      {isOpenStatusModal && (
        <ConfirmModal
          onCancel={handleCloseCheckStatus}
          onSuccess={handleCheckStatus}
          header={<h3>{t('opportunities.whatDoWithOpportunityCreated')}</h3>}
          content={
            <Radio
              className="opportunity__radio-button"
              value={countLastOpportunity}
              onChange={(v) => setCountLastOpportunity(Number(v))}
              options={[
                {
                  text: (
                    <Label type="fill" status="warning" text={t('opportunities.makeChanges')} />
                  ),
                  value: 0,
                },
                {
                  text: (
                    <Label
                      type="fill"
                      status="success"
                      text={t('opportunities.createNewOpportunity')}
                    />
                  ),
                  value: 1,
                },
              ]}
            />
          }
        />
      )}
    </>
  );
};
