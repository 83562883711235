import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useIncomesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getIncomes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/incomes`, query), getRequest);
    },
    getIncomeById: ({ queryKey: [, { id }] }) => fetcher(`/incomes/${id}`, getRequest),
    createIncome: (data) => {
      return fetcher('/incomes', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateIncome: ({ id, ...data }) => {
      return fetcher(`/incomes/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteIncome: (id: number) => {
      return fetcher(`/incomes/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
