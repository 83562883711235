import axios from 'axios';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { useCallback, useContext } from 'react';
import { SessionContext } from 'contexts/SessionContext';
import { Properties } from 'types';

export const useFileAPI = (): Properties => {
  const { session, apiConfig } = useContext(SessionContext);

  if (!apiConfig) {
    throw new Error('Services configuration was not set, please check Bootstrap component');
  }

  const service = apiConfig[ServiceTypes.FS];
  if (!service) {
    throw new Error(`Service config missing for ${ServiceTypes.FS}`);
  }

  const fetcher = useCallback(
    async <T>(url: string, opts): Promise<T> => {
      const { data: body } = opts;
      const tokenHeaders = {
        // Authorization: `Token ${session?.tokens.access}`,
        // 'Saas-app-token': service?.token,//todo
      };

      const config = {
        headers: { ...tokenHeaders, 'content-type': 'multipart/form-data' },
      };

      return await axios.post(`${service.baseUrl}${url}`, body, config);
    },
    [session, service],
  );

  return {
    uploadFile: async (file) => {
      const { data } = await fetcher(`/file/upload/`, {
        data: file,
      });
      return data;
    },
  };
};
