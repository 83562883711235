import * as React from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Space } from 'ebs-design';
import { CustomPeriod, Filters, PeriodType } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Calendar, List, UserAlone } from 'resources/icons';
import { useUsersAPI } from 'api';
import { defaultNonPagination, getPeriodByName } from 'utils';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { CallType, Results, User } from 'types';

interface CallsFilterProps {
  count?: number;
}

interface FiltersProps {
  search?: string;
  created_at?: string;
  created_at_start?: string;
  created_at_end?: string;
  type?: string;
  agent?: string;
}

export const CallsFilter: React.FC<CallsFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { getUsers } = useUsersAPI();
  const { updateQuery } = useQueryUpdate();
  const [openCalendarPicker, setOpenCalendarPicker] = React.useState(false);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  const { data: users } = useQuery<Results<User>>(['users', usersFilter], getUsers);

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const handleFilters = (value): void => {
    if (value?.created_at !== undefined) {
      if (value?.created_at === 'custom') {
        setOpenCalendarPicker(true);
      } else {
        const [created_at_start, created_at_end] = getPeriodByName(value.created_at);
        setFilters({ created_at_start, created_at_end, ...value });
      }
    } else if ('created_at' in value) {
      setFilters({ created_at_start: undefined, created_at_end: undefined, ...value });
    } else {
      setFilters(value);
    }
  };

  const handleCustomPeriod = (created_at_start: string, created_at_end: string): void => {
    setFilters({ created_at_start, created_at_end, created_at: 'custom' });
    setOpenCalendarPicker(false);
  };

  return (
    <>
      {openCalendarPicker && (
        <CustomPeriod
          onClose={() => setOpenCalendarPicker(false)}
          onChange={handleCustomPeriod}
          initialValue={
            [
              params.created_at_start && format(params.created_at_start, dateFormatAPI, dateFormat),
              params.created_at_end && format(params.created_at_end, dateFormatAPI, dateFormat),
            ] as PeriodType
          }
        />
      )}
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`calls.calls`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
        </Space>
        <Space justify="end">
          <Filters.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('filter.creditExpert')}
            value={params.agent}
            field="agent"
          >
            <Select.Options>
              {(users?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={users?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={List} />}
            placeholder={t('filter.type')}
            value={params.type}
            field="type"
          >
            <Select.Options>
              {Object.values(CallType).map((item, key) => (
                <Select.Options.Item key={`${key}-type`} value={item}>
                  {t(`calls.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
          <Filters.Select
            className="created-at-drop-list"
            prefix={<Icon component={Calendar} />}
            placeholder={t('filter.created_at')}
            field="created_at"
            value={params.created_at}
          >
            <Select.Options>
              <Select.Options.Item value="today">{t('filter.today')}</Select.Options.Item>
              <Select.Options.Item value="week">{t('filter.this_week')}</Select.Options.Item>
              <Select.Options.Item value="month">{t('filter.this_month')}</Select.Options.Item>
              <Select.Options.Item value="quarter">{t('filter.this_quarter')}</Select.Options.Item>
              <Select.Options.Item value="year">{t('filter.this_year')}</Select.Options.Item>
              <Select.Options.Item value="yesterday" className="period-drop-list__separator">
                {t('filter.yesterday')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_week">
                {t('filter.previous_week')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_month">
                {t('filter.previous_month')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_year">
                {t('filter.previous_year')}
              </Select.Options.Item>
              <Select.Options.Item value="all_time">{t('filter.all_time')}</Select.Options.Item>
              <Select.Options.Item
                value="custom"
                className="period-drop-list__custom period-drop-list__separator"
              >
                {t('filter.custom')}
              </Select.Options.Item>
            </Select.Options>
          </Filters.Select>
        </Space>
      </Filters>
    </>
  );
};
