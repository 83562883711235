import * as React from 'react';
import { Button, ButtonGroup, Space } from 'ebs-design';
import { ButtonsFilterProps } from '../interface';
import { FiltersContext } from '../Filters';

export const ButtonsFilter: React.FC<ButtonsFilterProps> = ({
  field,
  options,
  value,
  className,
}) => {
  const { onChange } = React.useContext(FiltersContext);

  const handleChange = (v: string | number): void => {
    onChange({ [field]: v });
  };

  return (
    <Space className={className}>
      <ButtonGroup className="filter-item">
        {options.map((item, key) => (
          <Button
            key={key}
            onClick={() => handleChange(item.value)}
            type={item.value === value ? 'primary' : 'ghost'}
            disabled={item.disabled}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
    </Space>
  );
};
