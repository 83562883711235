import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Row, Col, Loader, Modal, Space, Button } from 'ebs-design';
import { useDashboardAPI } from 'api';
import { UserTasksReport, DashboardProps } from 'types';
import { TaskCard } from 'components/atoms';
import { TaskForm } from 'components/organisms';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';

export const TasksCards: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<number | undefined>();

  const { getTasks } = useDashboardAPI();

  const { data, isLoading } = useQuery<UserTasksReport>(['tasks-cards', params], getTasks);

  return (
    <>
      <Loader loading={isLoading}>
        <Row className="mb-15">
          <Col size={4}>
            <TaskCard
              type="expired"
              title={data?.user?.past_tasks.total_tasks}
              description={t('logs.expired')}
              data={data?.user?.past_tasks.tasks}
              onClick={setOpen}
            />
          </Col>
          <Col size={4}>
            <TaskCard
              type="future"
              title={data?.user?.future_tasks.total_tasks}
              description={t('logs.future')}
              data={data?.user?.future_tasks.tasks}
              onClick={setOpen}
            />
          </Col>
          <Col size={4}>
            <TaskCard
              type="current"
              title={data?.user?.current_tasks.total_tasks}
              description={t('logs.current')}
              data={data?.user?.current_tasks.tasks}
              onClick={setOpen}
            />
          </Col>
        </Row>
        {useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin]) && (
          <Row className="mb-15">
            <Col size={4}>
              <TaskCard
                type="expired"
                title={data?.admin?.past_tasks.total_tasks}
                description={t('logs.expired')}
                isAdmin={!!data?.admin}
                data={data?.admin?.past_tasks.tasks}
                onClick={setOpen}
              />
            </Col>
            <Col size={4}>
              <TaskCard
                type="future"
                title={data?.admin?.future_tasks.total_tasks}
                description={t('logs.future')}
                isAdmin={!!data?.admin}
                data={data?.admin?.future_tasks.tasks}
                onClick={setOpen}
              />
            </Col>
            <Col size={4}>
              <TaskCard
                type="current"
                title={data?.admin?.current_tasks.total_tasks}
                description={t('logs.current')}
                isAdmin={!!data?.admin}
                data={data?.admin?.current_tasks.tasks}
                onClick={setOpen}
              />
            </Col>
          </Row>
        )}
      </Loader>
      {open && (
        <Modal
          closeOnClickOutside={false}
          open
          header={t('task.task')}
          onClose={() => setOpen(undefined)}
        >
          <Modal.Content>
            {<TaskForm id={open} closeModal={() => setOpen(undefined)} readOnly />}
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={() => setOpen(undefined)}>{t('buttons.cancel')}</Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
