import * as React from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Space } from 'ebs-design';
import { CustomPeriod, Filters, PeriodType } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Calendar, TaskList, UserAlone } from 'resources/icons';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useTaskTypesAPI, useUsersAPI } from 'api';
import { defaultNonPagination, getPeriodByName } from 'utils';
import { List, Results, User } from 'types';

interface TaskFilterProps {
  count?: number;
  rightComponents?: React.ReactNode;
}

interface FiltersProps {
  search?: string;
  owner?: string;
  created_by?: string;
  type?: string;
  completed?: string;
  deadline?: string;
  deadline_start?: string;
  deadline_end?: string;
}

export const TaskFilter: React.FC<TaskFilterProps> = ({ count, rightComponents }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { getUsers } = useUsersAPI();
  const { getAllTaskTypes } = useTaskTypesAPI();
  const { updateQuery } = useQueryUpdate();
  const [taskTypeFilter, setTaskTypeFilter] = useSetState(defaultNonPagination);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [openCalendarPicker, setOpenCalendarPicker] = React.useState(false);

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  const { data: users } = useQuery<Results<User>>(['users', { ...usersFilter }], getUsers);
  const { data: listTaskTypes } = useQuery<Results<List>>(
    ['list-task-type', { ...taskTypeFilter }],
    getAllTaskTypes,
  );

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const handleFilters = (value): void => {
    if (value?.deadline !== undefined) {
      if (value?.deadline === 'custom') {
        setOpenCalendarPicker(true);
      } else {
        const [deadline_start, deadline_end] = getPeriodByName(value.deadline);
        setFilters({ deadline_start, deadline_end, ...value });
      }
    } else if ('deadline' in value) {
      setFilters({ deadline_start: undefined, deadline_end: undefined, ...value });
    } else {
      setFilters(value);
    }
  };

  const handleCustomPeriod = (deadline_start: string, deadline_end: string): void => {
    setFilters({ deadline_start, deadline_end, deadline: 'custom' });
    setOpenCalendarPicker(false);
  };

  const statusOptions = React.useMemo(
    () => [
      {
        label: t('filter.uncompleted'),
        value: '',
      },
      { label: t('filter.completed'), value: '1' },
      { label: t('filter.all'), value: 'all' },
    ],
    [t],
  );

  return (
    <>
      {openCalendarPicker && (
        <CustomPeriod
          onClose={() => setOpenCalendarPicker(false)}
          onChange={handleCustomPeriod}
          initialValue={
            [
              params.deadline_start && format(params.deadline_start, dateFormatAPI, dateFormat),
              params.deadline_end && format(params.deadline_end, dateFormatAPI, dateFormat),
            ] as PeriodType
          }
        />
      )}
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`task.task`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
        </Space>
        <Space justify="end">
          <Filters.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('filter.agent')}
            value={params.owner}
            field="owner"
          >
            <Select.Options>
              {(users?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={users?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>

          <Filters.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('task.taskAuthor')}
            value={params.created_by}
            field="created_by"
          >
            <Select.Options>
              {(users?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={users?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={TaskList} />}
            placeholder={t('filter.type')}
            value={params?.type && parseInt(params.type)}
            field="type"
          >
            <Select.Options>
              {(listTaskTypes?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-list-task-type`} value={item.key}>
                  {item.label}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={listTaskTypes?.count || 0}
              limit={taskTypeFilter.per_page}
              page={taskTypeFilter.page}
              setPage={(page) => setTaskTypeFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            className="created-at-drop-list"
            prefix={<Icon component={Calendar} />}
            placeholder={t('filter.deadline')}
            field="deadline"
            value={params.deadline}
          >
            <Select.Options>
              <Select.Options.Item value="today">{t('filter.today')}</Select.Options.Item>
              <Select.Options.Item value="week">{t('filter.this_week')}</Select.Options.Item>
              <Select.Options.Item value="month">{t('filter.this_month')}</Select.Options.Item>
              <Select.Options.Item value="quarter">{t('filter.this_quarter')}</Select.Options.Item>
              <Select.Options.Item value="year">{t('filter.this_year')}</Select.Options.Item>
              <Select.Options.Item value="yesterday" className="period-drop-list__separator">
                {t('filter.yesterday')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_week">
                {t('filter.previous_week')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_month">
                {t('filter.previous_month')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_year">
                {t('filter.previous_year')}
              </Select.Options.Item>
              <Select.Options.Item value="all_time">{t('filter.all_time')}</Select.Options.Item>
              <Select.Options.Item
                value="custom"
                className="period-drop-list__custom period-drop-list__separator"
              >
                {t('filter.custom')}
              </Select.Options.Item>
            </Select.Options>
          </Filters.Select>
          <Filters.ButtonGroup
            options={statusOptions}
            field="completed"
            value={params.completed || 'all'}
          />
          {rightComponents}
        </Space>
      </Filters>
    </>
  );
};
