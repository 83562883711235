import { useContext } from 'react';

import { UserRoleCRM } from './useFetcher/interfaces';
import { useRolePermission } from './useRolePermission';
import { SessionContext } from 'contexts/SessionContext';

interface UseExpertPermissionProps {
  ownerId?: string | number;
}

export const useExpertPermission = ({ ownerId }: UseExpertPermissionProps): boolean => {
  const { session } = useContext(SessionContext);

  return useRolePermission([UserRoleCRM.expert]) && session?.data?.user?.sso_id === ownerId;
};
