import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Col, Form, Input, Row, useForm, useNotify } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { useContactAPI } from 'api';
import { ValidationError } from 'errors';
import { Contact } from 'types';

interface ContactFormProps {
  id?: number;
  formId: string;
  closeModal: () => void;
}

export const ContactForm: React.FC<ContactFormProps> = ({ id, formId, closeModal }) => {
  const { t } = useTranslation();
  const { id: leadId } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getContactById, postContact, patchContact } = useContactAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);

  const { data } = useQuery<Contact>(['contacts', { id }], getContactById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const mutation = useMutation(id ? patchContact : postContact, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts']);
      closeModal();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({ ...form.getFieldsValue(), lead: leadId, id });
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Row>
        <Col size={6} className="mt-10">
          <Form.Field name="first_name" label={t('contacts.firstName')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="last_name" label={t('contacts.lastName')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={12} className="mt-10">
          <Form.Field name="email" label={t('contacts.email')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="phone_number" label={t('contacts.phoneNumber')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="landline_number" label={t('contacts.landlineNumber')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="job_title" label={t('contacts.jobTitle')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="social_url" label={t('contacts.socialUrl')}>
            <Input />
          </Form.Field>
        </Col>
      </Row>
    </Form>
  );
};
