import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Icon, Loader, Select } from 'ebs-design';
import { useSetState } from 'react-use';
import { Filters } from 'components/molecules';
import { Map, UserAlone } from 'resources/icons';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { User, Results, List } from 'types';
import { useBranchesAPI, useUsersAPI } from 'api';
import { defaultFilters, defaultNonPagination, formatSelectValueToNumber } from 'utils';

interface FiltersProps {
  branch?: string;
  owner?: string;
  date?: string;
  date_from?: string;
  date_to?: string;
}
interface ReportFilterProps {
  count?: number;
}

export const ReportOfflineFilter: React.FC<ReportFilterProps> = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getBranchesMarketingReportList, getBranches } = useBranchesAPI();
  const { getUsersMarketingReportList, getUsers } = useUsersAPI();
  const [branchesFilter, setBranchesFilter] = useSetState(defaultNonPagination);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);

  const isMarketingRole = useRolePermission([UserRoleCRM.marketing]);

  const { data: branches, isLoading: isLoadingBranches } = useQuery<Results<List>>(
    ['branches', { ...defaultFilters, ...branchesFilter }],
    isMarketingRole ? getBranchesMarketingReportList : getBranches,
  );

  const { data: users, isLoading: isLoadingUsers } = useQuery<Results<User>>(
    ['users', { ...defaultFilters, ...usersFilter }],
    isMarketingRole ? getUsersMarketingReportList : getUsers,
  );

  const [filters, setFilters] = useSetState<FiltersProps>({
    ...params,
  });

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    if (value?.date) {
      const [date_from, date_to] = value?.date;

      if (date_from && date_to) {
        setFilters({ date_from, date_to });
      }
      return;
    }
    if (value?.date === null) {
      setFilters({
        date_from: undefined,
        date_to: undefined,
      });
      return;
    }
    setFilters(value);
  };

  return (
    <Filters onChange={handleFilters}>
      <Filters.Select
        prefix={<Icon component={Map} />}
        placeholder={t('filter.branch')}
        value={formatSelectValueToNumber(params.branch)}
        field="branch"
      >
        <Select.Options>
          {(branches?.results || []).map((item, key) => (
            <Select.Options.Item key={`${key}-branch`} value={item.key}>
              {item.label}
            </Select.Options.Item>
          ))}
        </Select.Options>

        {isLoadingBranches && <Loader.Inline />}

        <Select.Pagination
          count={branches?.count || 0}
          limit={branchesFilter.per_page}
          page={branchesFilter.page}
          setPage={(page) => setBranchesFilter({ page })}
          mode="scroll"
        />
      </Filters.Select>

      <Filters.Select
        prefix={<Icon component={UserAlone} />}
        placeholder={t('filter.creditExpert')}
        value={params.owner}
        field="owner"
      >
        <Select.Options>
          {(users?.results || []).map((item, key) => (
            <Select.Options.Item key={`${key}-user`} value={item.id}>
              {item?.first_name || item?.last_name
                ? `${item?.first_name} ${item?.last_name}`
                : item.email}
            </Select.Options.Item>
          ))}
        </Select.Options>

        {isLoadingUsers && <Loader.Inline />}

        <Select.Pagination
          count={users?.count || 0}
          limit={usersFilter.per_page}
          page={usersFilter.page}
          setPage={(page) => setUsersFilter({ page })}
          mode="scroll"
        />
      </Filters.Select>

      <Filters.DatePickerFilter
        value={[filters.date_from, filters.date_to]}
        field="date"
        type="range"
        placeholder={t('reports.sortByDate')}
      />
    </Filters>
  );
};
