import * as React from 'react';
import { Log } from 'types';
import { AvatarInline, Button, Icon, Space } from 'ebs-design';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { Trash } from 'resources/icons';
import { dateTimeFormat, format } from 'libs';

interface ActivityLogDefaultProps {
  log: Log;
  onDelete: (id) => void;
}

export const ActivityLogDefault: React.FC<ActivityLogDefaultProps> = ({ log, onDelete }) => {
  return (
    <Space justify="space-between">
      <div className="activity-log-item__content">{log.description}</div>
      <Space className="activity-log-item__right-side">
        <span className="activity-log-item__created-at">
          {format(log.created_at, dateTimeFormat)}
        </span>
        {log.created_by && (
          <AvatarInline
            type="dynamic"
            shortAlt={[
              (log.created_by?.first_name || '')[0],
              (log.created_by?.last_name || '')[0],
            ].join('')}
            alt={[log.created_by?.first_name, log.created_by?.last_name].join(' ') || '---'}
            circle
          />
        )}

        {useRolePermission([UserRoleCRM.admin]) && (
          <Button
            type="ghost"
            size="small"
            className="activity-log-item__delete"
            onClick={() => onDelete(log.id)}
            prefix={<Icon className="activity-log-item__delete__icon" component={Trash} />}
          />
        )}
      </Space>
    </Space>
  );
};
