import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Loader, Space, useNotify } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { ConfirmModal } from 'components/molecules';
import { Pagination, AudioPlayer } from 'components/organisms';
import { CompleteTask } from 'features/leads/DetailsCol/Cards/TaskCard/CompleteTaks';
import { CreateMeeting, CreateNote } from './ExtraComponents';
import { ActivityFilters } from './ActivityFilters';
import { ActivityLogItem } from './ActivityLogItem';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { defaultNonPagination } from 'utils';
import { useLogsAPI } from 'api';
import { Call, Log, Results, Task } from 'types';

export const ActivityCol: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getLeadLogs, deleteLog } = useLogsAPI();

  const [deleteItemsId, setDeleteItemsId] = React.useState();
  const [completeTask, setOpenCompleteTask] = React.useState<Task>();
  const [callItem, setCallItem] = React.useState<Call>();
  const [openCreateNote, setOpenCreateNote] = React.useState<boolean>(false);
  const [openCreateMeeting, setOpenCreateMeeting] = React.useState<boolean>(false);

  const { data: logs, isLoading } = useQuery<Results<Log>>(
    ['lead-logs', { id, ...defaultNonPagination, ...params }],
    getLeadLogs,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const mutation = useMutation(deleteLog, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      setDeleteItemsId(undefined);
    },
  });

  const handleDelete = (): void => {
    if (deleteItemsId) {
      mutation.mutate(deleteItemsId);
    }
  };

  const handleToggleCreateNote = (): void => {
    setOpenCreateNote((s) => !s);
  };
  const handleToggleCreateMeeting = (): void => {
    setOpenCreateMeeting((s) => !s);
  };

  const handleOpenPlayer = (call: Call): void => {
    setCallItem(call);
  };

  const handleOpenCompleteTask = (task: Task): void => {
    setOpenCompleteTask(task);
  };

  return (
    <LogsPanelContext.Provider
      value={{
        isOpenCreateNote: openCreateNote,
        toggleCreateNote: handleToggleCreateNote,
        isOpenCreateMeeting: openCreateMeeting,
        toggleCreateMeeting: handleToggleCreateMeeting,
        callItem,
        openPlayer: handleOpenPlayer,
        closePlayer: () => setCallItem(undefined),
        openCompleteTask: handleOpenCompleteTask,
      }}
    >
      <ActivityFilters />

      <Card className="mt-10 overflow-visible">
        <Card.Body>
          {openCreateNote && <CreateNote />}
          {openCreateMeeting && <CreateMeeting />}
          <Loader loading={isLoading}>
            {logs &&
              logs.results.map((item, key) => (
                <ActivityLogItem key={key} log={item} onDelete={setDeleteItemsId} />
              ))}
            {logs?.results.length === 0 && <Space justify="center">{t('main.noData')}</Space>}
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination
            size={logs?.total_pages || 0}
            defaultPerPage={defaultNonPagination.per_page}
          />
        </Card.Footer>
      </Card>
      {completeTask && (
        <CompleteTask
          task={completeTask}
          leadId={id}
          onCancel={() => setOpenCompleteTask(undefined)}
        />
      )}
      {deleteItemsId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteItemsId(undefined)} />
      )}
      <div className="player-wrapper">
        {callItem && <AudioPlayer record={callItem} onClose={() => setCallItem(undefined)} />}
      </div>
    </LogsPanelContext.Provider>
  );
};
