import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useSetState from 'react-use/esm/useSetState';
import { Card, Space, Loader, Table, Icon, Select, Button, useNotify } from 'ebs-design';
import type { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { Filters, InlineEdit } from 'components/molecules';
import { useExpensesAPI, useSourcesAPI } from 'api';
import { format, getYear, dateFormatAPI } from 'libs';
import { Properties, Expenses, SOURCES_TYPE } from 'types';
import { Calendar } from 'resources/icons';
import { months } from 'locales/months';
import { defaultNonPagination } from 'utils';

const queryKey = 'expenses';
const currentYear = parseInt(getYear(new Date()));

export const ReportExpenses: React.FC = () => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const { getSources } = useSourcesAPI();
  const { getExpenses, postExpenses } = useExpensesAPI();

  const [selectedYear, setSelectedYear] = React.useState(currentYear);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [yearOptions, setYearOptions] = useSetState<Properties>({
    min: 10,
    max: 10,
  });

  React.useEffect(() => {
    setOptions([
      ...new Array(yearOptions.max).fill(null).map((_, i) => ({
        value: currentYear - i + yearOptions.max,
        text: currentYear - i + yearOptions.max,
      })),
      { value: currentYear, text: currentYear },
      ...new Array(yearOptions.min)
        .fill(null)
        .map((_, i) => ({
          value: currentYear + i - yearOptions.min,
          text: currentYear + i - yearOptions.min,
        }))
        .reverse(),
    ]);
  }, [yearOptions]);

  const { data, isLoading } = useQuery<Expenses>([queryKey], getExpenses, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: dataSources, isLoading: isLoadingSources } = useQuery<Properties>(
    ['sources', { ...defaultNonPagination }],
    getSources,
    {
      enabled: !!data,
      select: ({ results }) => {
        const items = {};

        results.forEach(({ id, name, source_type }) => {
          items[id] = { id, name, type: source_type };

          if (data) {
            data[source_type].forEach(({ source: dataSource, date, ...params }) => {
              if (dataSource === id) {
                items[id][date] = params;
              }
            });
          }
        });

        return items;
      },
    },
  );

  const { mutate } = useMutation<unknown, unknown, Properties>(postExpenses, {
    onMutate: async (params: Properties) => {
      const query = [queryKey];
      const prevData = queryClient.getQueryData(query) as Properties;

      if (dataSources) {
        queryClient.setQueryData(query, {
          ...prevData,
          [dataSources[params.source].type]: params.id
            ? prevData[dataSources[params.source].type].map((item) => {
                if (params.id === item.id) {
                  return { ...item, ...params };
                }

                return item;
              })
            : [...prevData[dataSources[params.source].type], params],
        });
      }

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: (err, _, rollback: any) => rollback(),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const onChangeValue = ({ id, current, budget, date, source }: Properties): void => {
    mutate({
      id,
      date,
      source,
      current: current ? parseInt(current) : 0,
      budget: budget ? parseInt(budget) : 0,
    });
  };

  const columns = React.useMemo(
    () => [
      {
        title: t('nomenclature.leadSource'),
        children: [
          {
            title: t('nomenclature.online'),
            render: ({ name }) => {
              return name;
            },
          },
        ],
      },
      ...new Array(12).fill(null).map((_, i) => {
        const month = i + 1;
        const date = format(
          new Date(`${selectedYear}-${month < 10 ? '0' : ''}${month}-01`),
          dateFormatAPI,
        );

        return {
          title: `${months[`month${month}`][i18n.language]} ${selectedYear}`,
          children: [
            {
              title: t('nomenclature.actual'),
              render: ({ id: source, current, ...params }) => {
                const expense = params[date];

                return source ? (
                  <InlineEdit
                    defaultValue={expense ? expense.current : undefined}
                    onChange={(value) =>
                      onChangeValue({
                        id: expense?.id ? expense.id : undefined,
                        current: value,
                        budget: expense?.budget || 0,
                        date,
                        source,
                      })
                    }
                  />
                ) : (
                  current
                );
              },
            },
            {
              title: t('nomenclature.budget'),
              render: ({ id: source, budget, ...params }) => {
                const expense = params[date];

                return source ? (
                  <InlineEdit
                    defaultValue={expense ? expense.budget : undefined}
                    onChange={(value) =>
                      onChangeValue({
                        id: expense?.id ? expense.id : undefined,
                        budget: value,
                        current: expense?.current || 0,
                        date,
                        source,
                      })
                    }
                  />
                ) : (
                  budget
                );
              },
            },
          ],
        };
      }),
    ],
    [t, i18n.language, data, selectedYear, dataSources],
  );

  const dataTable = React.useMemo(
    () => [
      ...(dataSources
        ? Object.values(dataSources).filter(({ type }) => type === SOURCES_TYPE.ONLINE)
        : []),
      {
        name: <b>{t('nomenclature.offline').toUpperCase()}</b>,
        current: <b>{t('nomenclature.actual').toUpperCase()}</b>,
        budget: <b>{t('nomenclature.budget').toUpperCase()}</b>,
      },
      ...(dataSources
        ? Object.values(dataSources).filter(({ type }) => type === SOURCES_TYPE.OFFLINE)
        : []),
    ],
    [t, dataSources],
  );

  return (
    <Card className="mt-20">
      <Card.Header>
        <Space justify="space-between">
          {t('reports.reportExpenses')}
          <Filters onChange={({ year }) => setSelectedYear(year as number)}>
            <Filters.Select
              prefix={<Icon component={Calendar} />}
              placeholder={t('filter.year')}
              value={selectedYear}
              field="year"
              isClearable={false}
            >
              <Button
                type="text"
                size="small"
                className="full-width"
                onClick={() => setYearOptions(({ max }) => ({ max: max + 10 }))}
              >
                <Icon type="arrow-outline-top" />
              </Button>
              <Select.Options>
                {options.map(({ value, text }, i) => (
                  <Select.Options.Item key={i} value={value}>
                    {text}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Button
                type="text"
                size="small"
                className="full-width"
                onClick={() => setYearOptions(({ min }) => ({ min: min + 10 }))}
              >
                <Icon type="arrow-outline-bottom" />
              </Button>
            </Filters.Select>
          </Filters>
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <Loader loading={isLoading || isLoadingSources} size="regular">
          <Table
            data={dataTable}
            columns={columns}
            className="contacts-table reports-table no-border"
            onRow={() => ({
              className: 'table-cell--clickable',
            })}
          />
        </Loader>
      </Card.Body>
    </Card>
  );
};
