import { Contact } from './contacts';
import { User } from './user';

export interface Call {
  id: number;
  file: string;
}

export interface FullCall extends Call {
  talk_duration: number;
  call_duration: number;
  answered: boolean;
  from_phone_number: number;
  to_phone_number: number;
  file: string;
  start_at: string;
  end_at: string;
  agent: User;
  contact: Contact;
  type: CallType;
  created_at: string;
  modified_at: string;
}

export enum CallType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  MISSED = 'missed',
}

export enum CallStatus {
  incoming = 'success',
  outgoing = 'info',
  missed = 'danger',
}

export enum CallCategoryType {
  outstanding = 'outstanding',
  sales = 'sales',
}
