import * as React from 'react';
import cn from 'classnames';
import { Icon } from 'ebs-design';
import {
  ActivityLogCall,
  ActivityLogEmail,
  ActivityLogLeadChange,
  ActivityLogMeeting,
  ActivityLogNote,
  ActivityLogOpportunity,
  ActivityLogTask,
  ActivityLogDefault,
  ActivityLogChat,
} from './ActivityLogsTypes';
import {
  Call,
  Change,
  Email,
  MeetingRoom,
  MessageIncoming,
  MessageOutgoing,
  Pen,
  PenChange,
  Task,
} from 'resources/icons';
import { Log, LogMessageDirectionType, LogType } from 'types';

interface ActivityLogItemProps {
  log: Log;
  onDelete: (id) => void;
}

const messageIconType = {
  [LogMessageDirectionType.INCOMING]: MessageIncoming,
  [LogMessageDirectionType.OUTGOING]: MessageOutgoing,
};

const typeIcon = {
  [LogType.TASK]: Task,
  [LogType.OPPORTUNITY_CHANGE]: PenChange,
  [LogType.LEAD_CHANGE]: Change,
  [LogType.CALL]: Call,
  [LogType.EMAIL]: Email,
  [LogType.MEETING]: MeetingRoom,
  [LogType.NOTE]: Pen,
  [LogType.CHAT]: messageIconType,
};

const activityTypeComponent = {
  [LogType.TASK]: ActivityLogTask,
  [LogType.OPPORTUNITY_CHANGE]: ActivityLogOpportunity,
  [LogType.LEAD_CHANGE]: ActivityLogLeadChange,
  [LogType.CALL]: ActivityLogCall,
  [LogType.EMAIL]: ActivityLogEmail,
  [LogType.MEETING]: ActivityLogMeeting,
  [LogType.NOTE]: ActivityLogNote,
  [LogType.CHAT]: ActivityLogChat,
};

export const ActivityLogItem: React.FC<ActivityLogItemProps> = ({ log, onDelete }) => {
  const TypeComponent = React.useMemo(
    () => activityTypeComponent[log.type] || ActivityLogDefault,
    [log],
  );

  return (
    <div className="activity-log-item">
      <div className="activity-log-item__icon-box">
        <div className={cn('activity-log-item__icon', log.type)}>
          <Icon
            component={
              log.type === LogType.CHAT && log?.direction
                ? typeIcon[log.type][log.direction]
                : typeIcon[log?.type]
            }
          />
        </div>
      </div>
      <div className="activity-log-item__body">
        {TypeComponent && <TypeComponent log={log} onDelete={onDelete} />}
      </div>
    </div>
  );
};
