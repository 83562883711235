import * as React from 'react';
import { Space, Tooltip } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { Nomenclatures } from 'types';

interface AddressCellProps {
  address_country?: Nomenclatures;
  address_region?: Nomenclatures;
  address_city?: Nomenclatures;
  address_street?: Nomenclatures;
}

export const AddressCell: React.FC<AddressCellProps> = ({
  address_country,
  address_region,
  address_city,
  address_street,
}) => {
  const { t } = useTranslation();
  const address =
    address_city?.name || address_region?.name || address_country?.name || address_street;

  return (
    <Tooltip
      placement="bottom"
      trigger="click"
      tooltip={
        <div className="location-tooltip">
          <Space justify="space-between">
            <span>{t('contacts.country')}:</span>
            <span>{address_country?.name}</span>
          </Space>
          <Space justify="space-between">
            <span>{t('contacts.region')}:</span>
            <span>{address_region?.name}</span>
          </Space>
          <Space justify="space-between">
            <span>{t('contacts.city')}:</span>
            <span>{address_city?.name}</span>
          </Space>
          <Space justify="space-between">
            <span>{t('contacts.street')}:</span>
            <span>{address_street}</span>
          </Space>
        </div>
      }
    >
      <span className={address && 'pseudo-link'}>{address || '---'}</span>
    </Tooltip>
  );
};
