import * as React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'ebs-design';
import { LeadSalesKanban } from 'types';

interface KanbanItemProps {
  leadView: LeadSalesKanban;
}

export const KanbanItem: React.FC<KanbanItemProps> = ({ leadView }) => {
  return (
    <div className="kanban-item">
      <div className="kanban-item__title">
        <Link to={`/lead/${leadView.id}`}>{leadView.name}</Link>
      </div>
      <div className="kanban-item__body">
        <Link to={`/lead/${leadView.id}`}>
          <Avatar
            circle
            className="kanban-item__avatar"
            type="dynamic"
            size="big"
            shortAlt={leadView?.name
              ?.split(' ')
              .map((i) => i.charAt(0))
              .join('')}
            alt={[leadView?.opportunity?.value, leadView?.opportunity?.currency.name].join(' ')}
          />
        </Link>
        <div className="kanban-item__avatar-description">
          <span className="kanban-item__avatar-description__item kanban-item__avatar-description__item--phone">
            {leadView?.opportunity?.contact?.phone_number}
          </span>
          <span className="kanban-item__avatar-description__item kanban-item__avatar-description__item--value">
            {[leadView?.opportunity?.value, leadView?.opportunity?.currency.name].join(' ')}
          </span>
        </div>
      </div>
    </div>
  );
};
