import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';

export const useCustomersAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getCustomers: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/contracts/clients`, query), getRequest);
    },
  };
};
