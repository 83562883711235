import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Space, Card, Form, Row, Col, Input, Icon, DatePicker, Button } from 'ebs-design';
import { FormInstance } from 'ebs-design/dist/components/organisms/Form';
import { SmartSelect } from 'components/organisms';
import {
  useUsersAPI,
  useBranchesAPI,
  useActivityDomainsAPI,
  useSourcesAPI,
  useCitiesAPI,
  useRegionsAPI,
  useCountriesAPI,
} from 'api';
import { Properties, LeadStatus } from 'types';
import {
  defaultFilters,
  userTransformResults,
  listTransformResults,
  defaultNonPagination,
} from 'utils';

export const customFilters = {
  search: '',
  address_country: undefined,
  address_region: undefined,
  address_city: undefined,
  address_street: '',
  email: '',
  owner: undefined,
  branch: undefined,
  activity_domain: undefined,
  source: undefined,
  status: undefined,
  period: '',
  type: undefined,
  value_from: '',
  value_to: '',
  created_at_from: '',
  created_at_to: '',
};

interface Props {
  form: FormInstance;
  data: Properties;
  filters: Properties;
  onSearch: (values: Properties) => void;
}

export const Filters: React.FC<Props> = ({ data, form, filters, onSearch }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = React.useState(data.period || 0);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!loaded) {
      const { period, ...rest } = data;
      const fields = { ...defaultFilters, ...customFilters };
      form.setFieldsValue({ ...rest, period: period | 0 });
      onSearch(fields);
      setLoaded(true);
    }
  }, [loaded, data]);

  const { getUsers } = useUsersAPI();
  const { getCities } = useCitiesAPI();
  const { getRegionsList } = useRegionsAPI();
  const { getCountries } = useCountriesAPI();
  const { getBranches } = useBranchesAPI();
  const { getActivityDomainList } = useActivityDomainsAPI();
  const { getSources } = useSourcesAPI();

  const onValuesChange = (field): void => {
    if (field.period) {
      setPeriod(field.period);
    }
  };

  const onReset = (): void => {
    const fields = { ...defaultFilters, ...customFilters };
    form.setFieldsValue({ ...fields, period: 0 });
    onSearch(fields);
    setPeriod(0);
  };

  return (
    <Card>
      <Card.Body>
        <Form
          form={form}
          initialValues={filters}
          id="lead-edit"
          onValuesChange={onValuesChange}
          onFinish={onSearch}
          className="contact-create_form"
        >
          <Row g={4}>
            <Col size={12}>
              <Form.Field name="search" label={t('filter.search')}>
                <Input suffix={<Icon type="search" />} />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="address_country" label={t('contacts.country')}>
                <SmartSelect
                  search
                  queryKey="countries"
                  api={getCountries}
                  filters={defaultNonPagination}
                  placeholder={t('contacts.country')}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="address_region" label={t('contacts.region')}>
                <SmartSelect
                  search
                  queryKey="regions"
                  api={getRegionsList}
                  filters={defaultNonPagination}
                  placeholder={t('contacts.region')}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="address_city" label={t('contacts.city')}>
                <SmartSelect
                  search
                  queryKey="cities"
                  api={getCities}
                  filters={defaultNonPagination}
                  placeholder={t('contacts.city')}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="address_street" label={t('contacts.street')}>
                <Input isClearable />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="owner" label={t('contacts.creditExpert')}>
                <SmartSelect
                  api={getUsers}
                  filters={defaultNonPagination}
                  transform={userTransformResults}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="email" label={t('contacts.email')}>
                <Input isClearable />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="branch" label={t('contacts.branch')}>
                <SmartSelect
                  api={getBranches}
                  filters={defaultNonPagination}
                  transform={listTransformResults}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="activity_domain" label={t('contacts.activityDomain')}>
                <SmartSelect
                  api={getActivityDomainList}
                  filters={defaultNonPagination}
                  queryKey="activity-domain"
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="source" label={t('contacts.source')}>
                <SmartSelect api={getSources} filters={defaultNonPagination} />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="status" label={t('lead.status')}>
                <SmartSelect
                  options={Object.values(LeadStatus).map((value) => ({
                    value,
                    text: t(`statuses.${value}`),
                  }))}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="type" label={t('contacts.leadType')}>
                <SmartSelect
                  options={[
                    { text: t('contacts.individual'), value: 'individual' },
                    { text: t('contacts.company'), value: 'company' },
                  ]}
                />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Form.Field name="period" label={`${t('filter.period')} (${period})`}>
                <input type="range" min={0} max={48} className="full-width" />
              </Form.Field>
            </Col>
            <Col size={3}>
              <Row>
                <Col size={12}>
                  <div className="ebs-form__field__label align-items--center justify-content--start">
                    {t('lead.contractValue')}
                  </div>
                </Col>
                <Col size={12} className="ebs-form__field__control">
                  <Row>
                    <Col size={6}>
                      <Form.Field name="value_from" label={null}>
                        <Input type="number" placeholder={t('from')} />
                      </Form.Field>
                    </Col>
                    <Col size={6}>
                      <Form.Field name="value_to" label={null}>
                        <Input type="number" placeholder={t('to')} />
                      </Form.Field>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col size={3}>
              <Form.Field label={t('lead.created_at')}>
                <Row>
                  <Col size={6}>
                    <Form.Field name="created_at_from" label={null}>
                      <DatePicker placeholderText={t('main.from')} isClearable />
                    </Form.Field>
                  </Col>
                  <Col size={6}>
                    <Form.Field name="created_at_to" label={null}>
                      <DatePicker placeholderText={t('main.to')} isClearable />
                    </Form.Field>
                  </Col>
                </Row>
              </Form.Field>
            </Col>
            <Col size={6}>
              <Space className="full-height" justify="end" align="end">
                <Button onClick={onReset}>{t('filter.reset')}</Button>
                <Button type="primary" submit>
                  {t('main.search')}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};
