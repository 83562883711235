import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader, useNotify } from 'ebs-design';
import { ActionCard } from 'features/leads/ActionCard';
import { ConfirmModal } from 'components/molecules';
import { DocumentForm } from 'components/organisms';
import { DocumentItem } from './DocumentItem';
import { useDocumentsAPI } from 'api';
import { Document, Lead } from 'types';

const formId = 'add-document';

interface DocumentCardProps {
  lead: Lead;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ lead }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getLeadDocuments, deleteDocument } = useDocumentsAPI();
  const [editId, setEditId] = React.useState();
  const [deleteId, setDeleteId] = React.useState();

  const { data, isLoading } = useQuery<Document[]>(['documents', { id }], getLeadDocuments, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const mutation = useMutation(deleteDocument, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
      setDeleteId(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const onEdit = (id): void => {
    setEditId(id);
  };
  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutation.mutate(deleteId);
    }
  };

  return (
    <>
      <ActionCard
        header={t('lead.documents')}
        className="mt-20"
        backgroundColor="#3DC343"
        formId={formId}
        count={data?.length}
        editId={editId}
        unsetEdit={() => setEditId(undefined)}
        FormComponent={DocumentForm}
      >
        <Loader loading={isLoading}>
          {data?.length
            ? data.map((document, key) => (
                <DocumentItem
                  key={key}
                  document={document}
                  lead={lead}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))
            : t('main.noData')}
        </Loader>
      </ActionCard>
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}
    </>
  );
};
