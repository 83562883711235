import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, Space, ButtonGroup, Button, Loader } from 'ebs-design';
import { useDashboardAPI } from 'api';
import { Column, CreditExpert, DashboardProps, ChartType } from 'types';

export const TopCreditExperts: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const { getTopExperts } = useDashboardAPI();
  const [expert_type, setExpertType] = React.useState<ChartType>('disbursements');

  const { data = [], isLoading } = useQuery<CreditExpert[]>(
    ['top-credit-experts', { ...params, expert_type }],
    getTopExperts,
  );

  const columns: Column<CreditExpert>[] = React.useMemo(
    () => [
      {
        title: '№',
        render: (item, row, i): React.ReactNode => i + 1,
      },
      {
        title: t('nomenclature.name'),
        render: ({ name }): React.ReactNode => name,
      },
      {
        title: t('reports.disbursements'),
        render: ({ count }): React.ReactNode => count,
      },
    ],
    [t],
  );

  return (
    <Card>
      <Card.Header>
        <Space justify="space-between">
          {t('reports.topCreditExperts')}

          <ButtonGroup>
            <Button
              type={expert_type === 'disbursements' ? 'primary' : 'ghost'}
              onClick={() => setExpertType('disbursements')}
            >
              {t('reports.disbursements')}
            </Button>
            <Button
              type={expert_type === 'applications' ? 'primary' : 'ghost'}
              onClick={() => setExpertType('applications')}
            >
              {t('menu.applications')}
            </Button>
            <Button
              type={expert_type === 'calls' ? 'primary' : 'ghost'}
              onClick={() => setExpertType('calls')}
            >
              {t('menu.calls')}
            </Button>
            <Button
              type={expert_type === 'visits' ? 'primary' : 'ghost'}
              onClick={() => setExpertType('visits')}
            >
              {t('reports.visits')}
            </Button>
          </ButtonGroup>
        </Space>
      </Card.Header>
      <Card.Body className="p-0">
        <Loader loading={isLoading}>
          <Table
            emptyText={t('main.noData')}
            columns={columns}
            data={data}
            className="sales-funnel-table__table no-border"
          />
        </Loader>
      </Card.Body>
    </Card>
  );
};
