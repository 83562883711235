import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useRegionsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getRegions: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/regions`, query), getRequest);
    },
    getRegionsList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/regions/list`, query), getRequest);
    },
    getRegion: ({ queryKey: [, { id }] }) => {
      return fetcher(`/regions/${id}`, getRequest);
    },
  };
};
