import * as React from 'react';
import { AvatarInline } from 'ebs-design';
import { LeadSimilar } from 'types';

interface AffiliateContactsItemProps {
  lead: LeadSimilar;
}

export const AffiliateContactsItem: React.FC<AffiliateContactsItemProps> = ({ lead }) => {
  const [first_name, last_name] = lead?.name ? lead?.name?.split(' ') : [''];

  return (
    <AvatarInline
      className="pt-5 pb-5"
      type="dynamic"
      shortAlt={[(first_name || '')[0], (last_name || '')[0]].join('')}
      alt={lead?.name || '---'}
      circle
    />
  );
};
