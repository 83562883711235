import { Session } from './interfaces';
import { Properties } from 'types';
import { ResponseError, SessionInvalidError, ValidationError } from 'errors';

export const defaultMethod = (request: RequestInit = {}): RequestInit => {
  const method = request.body ? 'POST' : 'GET';

  return { method };
};

export const headersInit: HeadersInit = {
  'Content-Type': 'application/json',
};

type RequestBuilderOption = {
  req?: RequestInit;
  session?: Session | null;
  serviceToken?: string;
};

type FetcherResponseError = any;

/**
 * This build a `RequestInit` object to use in a `fetch` request.
 *
 * Note that this makes a copy of values of the option object.
 *
 * @param opt options
 */
export const buildRequestInit = (opt: RequestBuilderOption): RequestInit => {
  const tokenHeaders: Record<string, string> = {};

  if (opt.session) {
    tokenHeaders['Authorization'] = `Token ${opt.session.tokens.access}`;
  }

  if (opt.serviceToken) {
    tokenHeaders['Saas-app-token'] = opt.serviceToken;
  }

  tokenHeaders['Accept-Language'] = localStorage.getItem('language') || 'ro';

  const init: RequestInit = {
    ...defaultMethod(opt.req),
    ...opt.req,
    headers: { ...headersInit, ...opt.req?.headers, ...tokenHeaders },
  };

  return init;
};

/**
 * This will generate an error instance depending on the req and res objects.
 *
 * @param req the `Request` instance used
 * @param res - the `Response` instance.
 * @param data the parsed response body, if any, this is used for fields validation error.
 */
export const generateResponseErrorFrom = (
  req: { url: string; method: string; body?: BodyInit | null },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res: { status: number; data: any },
): FetcherResponseError => {
  switch (res.status) {
    case 400: {
      if (req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH') {
        const body =
          typeof req.body === 'string'
            ? JSON.parse(req.body) // this will fail if string is not valid JSON.
            : req.body;

        return new ValidationError(body, res.data, res.data.non_field_errors);
      }

      return new ResponseError(req.url, res.status, req.body, res.data); //todo if is not validation error do some things
    }
    case 401:
      return new SessionInvalidError(); //todo remove token and redirect to login page
    default:
      return new ResponseError(req.url, res.status, req.body, res.data); //todo make 500 or another error page/concept
  }
};

export const removeEmpty = (obj?: Properties | null | Properties[]): any => {
  if (!obj) return obj;

  if (Array.isArray(obj)) {
    return obj.map((v) => (v && typeof v === 'object' ? removeEmpty(v) : v === '' ? null : v));
  } else {
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [
        k,
        v === Object(v) ? removeEmpty(v) : v === '' ? null : v,
      ]),
    );
  }
};
