import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useCitiesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getCities: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/cities`, query), getRequest);
    },

    getCitiesList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/cities/list`, query), getRequest);
    },

    getCityById: ({ queryKey: [, { id }] }) => fetcher(`/cities/${id}`, getRequest),
    createCity: (data) => {
      return fetcher('/cities', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateCity: ({ id, ...data }) => {
      return fetcher(`/cities/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteCity: (id: number) => {
      return fetcher(`/cities/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
