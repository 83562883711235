import { Dashboard } from 'features/dashboard/pages';
import {
  Contacts,
  ContactImport,
  ContactImportSteps,
  ContactCreate,
} from 'features/contacts/pages';
import { SalesFunnel } from 'features/salesFunnel/pages';
import { SalesFunnelKanban } from 'features/salesFunnelKanban/pages';
import { Leads, LeadsDetails } from 'features/leads/pages';
import { Tasks } from 'features/tasks/pages';
import { Calls } from 'features/calls/pages';
import { Documents } from 'features/documents/pages';
import { Report } from 'features/reports/pages';
import { NomenclaturesLayout } from 'features/nomenclatures/NomenclaturesLayout';
import { Agents } from 'features/agents/pages';
import { Settings } from 'features/settings/pages';
import { GlobalSearch } from 'features/globalSearch/pages';
import { Route } from 'types';
import { Visits, VisitForm, VisitContacts } from 'features/visits/pages';

export const routes: Route[] = [
  { name: 'Dashboard', path: '/', exact: true, Component: Dashboard },

  { name: 'Contacts', path: '/contacts', exact: true, Component: Contacts },
  { name: 'ContactCreate', path: '/contacts/create', exact: true, Component: ContactCreate },
  { name: 'ContactImport', path: '/contacts/import', exact: true, Component: ContactImport },
  {
    name: 'ContactImportSteps',
    path: '/contacts/import/:id/:step',
    Component: ContactImportSteps,
  },

  { name: 'SalesFunnel', path: '/sales-funnel', exact: true, Component: SalesFunnel },
  {
    name: 'SalesFunnelKanban',
    path: '/sales-funnel-kanban',
    exact: true,
    Component: SalesFunnelKanban,
  },
  { name: 'Leads', path: '/leads', exact: true, Component: Leads },
  { name: 'LeadsDetails', path: '/lead/:id', exact: true, Component: LeadsDetails },
  { name: 'Tasks', path: '/tasks', exact: true, Component: Tasks },

  { name: 'Calls', path: '/calls', exact: true, Component: Calls },

  { name: 'Documents', path: '/documents', exact: true, Component: Documents },

  { name: 'Reports', path: '/report/:report', exact: true, Component: Report },

  { name: 'Nomenclatures', path: '/nomenclatures', Component: NomenclaturesLayout },

  { name: 'Agents', path: '/agents', exact: true, Component: Agents },

  { name: 'Settings', path: '/settings', exact: true, Component: Settings },

  { name: 'GlobalSearch', path: '/global-search', exact: true, Component: GlobalSearch },

  { name: 'Visits', path: '/visits', exact: true, Component: Visits },
  { name: 'VisitCreate', path: '/visits/create', exact: true, Component: VisitForm },
  { name: 'VisitEdit', path: '/visits/:id', exact: true, Component: VisitForm },
  { name: 'VisitContacts', path: '/visits/:id/contacts', exact: true, Component: VisitContacts },
];
