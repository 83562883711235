import React from 'react';
import { Space, Tooltip } from 'ebs-design';
import type { TooltipProps } from 'ebs-design/dist/components/atoms/Tooltip/Tooltip';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface CallsTableTooltipProps extends TooltipProps {
  data: string;
  outData: string;
  inData: string;
  activeTooltip?: boolean;
}

export const CallsTableCellTooltip: React.FC<CallsTableTooltipProps> = ({
  outData,
  inData,
  data,
  activeTooltip,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="bottom"
      trigger={activeTooltip ? 'hover' : null}
      tooltip={
        <>
          <Space justify="space-between">
            {`${t('calls.outstanding')}:`}
            {inData}
          </Space>

          <Space justify="space-between">
            {`${t('calls.sales')}:`}
            {outData}
          </Space>
        </>
      }
      {...props}
    >
      <div className={classNames({ ['pointer']: activeTooltip })}>{data}</div>
    </Tooltip>
  );
};
