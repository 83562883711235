import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space } from 'ebs-design';
import { PerPage } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { defaultFilters } from 'utils';

interface Props {
  size: number;
  perPageOption?: number[];
  defaultPerPage?: number;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
}

export const Pagination: React.FC<Props> = ({
  size,
  perPageOption = [50, 100, 200],
  defaultPerPage,
  suffix,
  prefix,
}) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const handleNextPage = (): void => {
    updateQuery({
      ...params,
      page: parseInt(params.page || defaultPerPage || defaultFilters.page) + 1,
    });
  };
  const handlePrevPage = (): void => {
    updateQuery({
      ...params,
      page: parseInt(params.page || defaultPerPage || defaultFilters.page) - 1,
    });
  };

  const handleSetPerPage = (value): void => {
    updateQuery({ ...params, page: 1, per_page: value });
  };

  return (
    <Space justify="space-between">
      <Space>
        {prefix}
        {`${params.page || 1} ${t('main.of')} ${size}`}
      </Space>
      <Space>
        <Space>
          <span className="label">{t('main.perPage')}: </span>
          <PerPage
            options={perPageOption}
            value={parseInt(params.per_page || defaultPerPage || defaultFilters.per_page)}
            onChange={(value) => handleSetPerPage(value)}
            size="small"
          />
        </Space>
        <Space>
          <Button size="small" onClick={handlePrevPage} disabled={parseInt(params.page || 1) <= 1}>
            {t('buttons.prev')}
          </Button>
          <Button
            size="small"
            onClick={handleNextPage}
            disabled={parseInt(params.page || 1) >= size}
          >
            {t('buttons.next')}
          </Button>
        </Space>
        {suffix}
      </Space>
    </Space>
  );
};
