import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Loader } from 'ebs-design';
import { SessionContext } from 'contexts/SessionContext';
import { useDomain } from 'hooks/useDomain';
import * as ApplicationTypeIcons from 'resources/icons/applications';
import { Forward } from 'resources/icons';

const iconsType = {
  auth: {
    icon: ApplicationTypeIcons.Auth,
    className: 'auth',
  },
  cms: {
    icon: ApplicationTypeIcons.CMS,
    className: 'cms',
  },
  chat: {
    icon: ApplicationTypeIcons.Chat,
    className: 'chat',
  },
  calendar: {
    icon: ApplicationTypeIcons.Calendar,
    className: 'calendar',
  },
  notifications: {
    icon: ApplicationTypeIcons.Notifications,
    className: 'notifications',
  },
  storage: {
    icon: ApplicationTypeIcons.Storage,
    className: 'storage',
  },
  management: {
    icon: ApplicationTypeIcons.Documentation,
    className: 'management',
  },
  tasks: {
    icon: ApplicationTypeIcons.Tasks,
    className: 'tasks',
  },
  faas: {
    icon: ApplicationTypeIcons.FAAS,
    className: 'faas',
  },
  server: {
    icon: ApplicationTypeIcons.Server,
    className: 'server',
  },
  monitoring: {
    icon: ApplicationTypeIcons.Monitoring,
    className: 'monitoring',
  },
  analytics: {
    icon: ApplicationTypeIcons.Analytics,
    className: 'analytics',
  },
  'rocket-designer': {
    icon: ApplicationTypeIcons.BPM,
    className: 'bpm',
  },
  defaultApplication: {
    icon: ApplicationTypeIcons.Tasks,
    className: 'default',
  },
};

export const ApplicationListTooltip: React.FC = () => {
  const { t } = useTranslation();
  const { active, getUrl } = useDomain();
  const { session } = React.useContext(SessionContext);
  const { pinned_applications = [] } = session?.data || {};
  const isLoading = !session?.data;

  return (
    <div className="application-tooltip">
      <div className="application-tooltip__list">
        {isLoading && <Loader.Spinner />}
        {!isLoading &&
          pinned_applications.map((item, key) => {
            const type =
              item.instance_type.type in iconsType
                ? iconsType[item.instance_type.type]
                : iconsType.defaultApplication;

            return (
              <a
                href={active && `${getUrl(active)}/apps/${item.instance_type.type}/${item.token}/`}
                target="_blank"
                rel="noopener noreferrer"
                key={`${key}-application`}
                className={`application-tooltip__list__item application__item--${type?.className}`}
              >
                <div className="application-tooltip__list__item__icon">
                  <Icon component={type?.icon} />
                </div>
                <span>{item.name}</span>
              </a>
            );
          })}
      </div>
      <div className="application-tooltip__footer">
        <Link to={{ target: '_blank', href: '' }}>
          {t('application.viewBase')}
          <Forward />
        </Link>
      </div>
    </div>
  );
};
