import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  useForm,
  useNotify,
} from 'ebs-design';
import { dateFormat, dateFormatAPI, dateFormatInput, format, today } from 'libs';
import { ValidationError } from 'errors';
import { useLeadsAPI, useOpportunitiesAPI } from 'api';
import { Lead, Properties } from 'types';
import { Trash, Files } from 'resources/icons';

interface Report1CProps {
  onCancel: () => void;
  opportunityId?: number;
}

enum AnnuityType {
  EQUAL_RATES = 'equal-rates',
  UNEQUAL_RATES = 'unequal-rates',
}

export const Report1C: React.FC<Report1CProps> = ({ onCancel, opportunityId }) => {
  const { t } = useTranslation();
  const { id: leadId } = useParams();
  const [form] = useForm();
  const notify = useNotify();
  const { download1C } = useOpportunitiesAPI();
  const { getLeadById } = useLeadsAPI();
  const [paymentSchemes, setPaymentSchemes] = React.useState<Properties[]>([]);
  const queryClient = useQueryClient();

  const { data: lead } = useQuery<Lead>(['lead', { id: leadId }], getLeadById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const mutation = useMutation((data: Properties) => download1C(data), {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: async (response: Response) => {
      try {
        queryClient.invalidateQueries(['opportunity']);

        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Lead ${lead?.name} 1C report (${format(today.toString(), dateFormat)}).pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
  });

  const handleSubmit = (): void => {
    const {
      sum,
      term,
      annuity_type,
      disbursment_commission_rate,
      administration_commission_rate,
      interest_rate,
      date,
      first_payment_date,
      payment_scheme,
    } = form.getFieldsValue();

    mutation.mutate({
      sum: parseFloat(sum),
      term: parseFloat(term),
      annuity_type,
      disbursment_commission_rate: parseFloat(disbursment_commission_rate),
      administration_commission_rate: parseFloat(administration_commission_rate),
      interest_rate: parseFloat(interest_rate),
      lead: parseInt(leadId),
      payment_scheme:
        payment_scheme?.map((item) => {
          item.principal_sum = parseFloat(item.principal_sum || 0);
          item.interest_sum = parseFloat(item.interest_sum || 0);
          item.commission_sum = parseFloat(item.commission_sum || 0);
          item.total_sum = parseFloat(item.total_sum || 0);
          item.start_date = format(item.start_date, dateFormatAPI, dateFormat);
          item.end_date = format(item.end_date, dateFormatAPI, dateFormat);

          return item;
        }) || [],
      date: date && format(date, dateFormatAPI, dateFormat),
      first_payment_date:
        first_payment_date && format(first_payment_date, dateFormatAPI, dateFormat),
      opportunity: opportunityId,
    });
  };

  const onValuesChange = (params): void => {
    if (params.payment_scheme) {
      const index = params.payment_scheme.findIndex((i) => i);

      setPaymentSchemes((prevState) =>
        prevState.map((item, i) => {
          if (i === index) {
            item = { ...item, ...params.payment_scheme[index] };
          }

          return item;
        }),
      );
    }
  };

  return (
    <Modal
      size="large"
      closeOnClickOutside={false}
      open
      header={<h3>{t('report1C.generateReport')}</h3>}
      onClose={onCancel}
    >
      <Modal.Content>
        <Form
          form={form}
          id="report-1C-form"
          onValuesChange={onValuesChange}
          onFinish={handleSubmit}
        >
          <Row>
            <Col size={6} className="mt-20">
              <Form.Field
                name="sum"
                label={t('report1C.disbursementsSum')}
                rules={[{ required: true }]}
              >
                <Input type="number" step="0.01" />
              </Form.Field>
            </Col>
            <Col size={6} className="mt-20">
              <Form.Field name="term" label={t('report1C.term')} rules={[{ required: true }]}>
                <Input type="number" />
              </Form.Field>
            </Col>
            <Col size={6} className="mt-20">
              <Form.Field
                name="date"
                label={t('report1C.disbursementsDate')}
                rules={[{ required: true }]}
              >
                <DatePicker dateFormat={dateFormatInput} />
              </Form.Field>
            </Col>
            <Col size={6} className="mt-20">
              <Form.Field
                name="first_payment_date"
                label={t('report1C.firstPaymentDate')}
                rules={[{ required: true }]}
              >
                <DatePicker dateFormat={dateFormatInput} />
              </Form.Field>
            </Col>
            <Col size={4} className="mt-20">
              <Form.Field
                name="interest_rate"
                label={t('report1C.interestRate')}
                initialValue={0}
                rules={[{ required: true }]}
              >
                <Input type="number" step="0.01" />
              </Form.Field>
            </Col>
            <Col size={4} className="mt-20">
              <Form.Field
                name="disbursment_commission_rate"
                label={t('report1C.disbursementCommissionRate')}
                initialValue={0}
                rules={[{ required: true }]}
              >
                <Input type="number" step="0.01" />
              </Form.Field>
            </Col>
            <Col size={4} className="mt-20">
              <Form.Field
                name="administration_commission_rate"
                label={t('report1C.administrationCommissionRate')}
                initialValue={0}
                rules={[{ required: true }]}
              >
                <Input type="number" step="0.01" />
              </Form.Field>
            </Col>
            <Col size={8} sm={4} className="mt-20">
              <Form.Field
                name="annuity_type"
                label={t('report1C.annuityType')}
                rules={[{ required: true }]}
              >
                <Radio
                  className="flex-space-between"
                  options={[
                    { value: AnnuityType.EQUAL_RATES, text: t('report1C.equalRates') },
                    { value: AnnuityType.UNEQUAL_RATES, text: t('report1C.unequalRates') },
                  ]}
                />
              </Form.Field>
            </Col>
            <Col size={4} sm={8} />
            {paymentSchemes.map(
              ({ principal_enabled, interest_enabled, commission_enabled, total_enabled }, i) => (
                <Col key={i} size={12} className="payment-scheme">
                  <Space align="end">
                    <Row>
                      <Col size={4}>
                        <Row className="flex-nowrap mt-20">
                          <Col size={6}>
                            <Form.Field
                              name={['payment_scheme', i, 'start_date']}
                              label={t('report1C.start_date')}
                              rules={[{ required: true }]}
                            >
                              <DatePicker dateFormat={dateFormatInput} />
                            </Form.Field>
                          </Col>
                          <Col size={6}>
                            <Form.Field
                              name={['payment_scheme', i, 'end_date']}
                              label={t('report1C.end_date')}
                              rules={[{ required: true }]}
                            >
                              <DatePicker dateFormat={dateFormatInput} />
                            </Form.Field>
                          </Col>
                        </Row>
                      </Col>
                      <Col size={2}>
                        <Row g={0} className="flex-nowrap mt-20">
                          <Col size="auto">
                            <Form.Field
                              name={['payment_scheme', i, 'principal_enabled']}
                              label=" "
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox />
                            </Form.Field>
                          </Col>
                          <Col>
                            <Form.Field
                              name={['payment_scheme', i, 'principal_sum']}
                              label={t('report1C.principal_sum')}
                              rules={[{ required: principal_enabled }]}
                            >
                              <Input type="number" step="0.01" disabled={!principal_enabled} />
                            </Form.Field>
                          </Col>
                        </Row>
                      </Col>
                      <Col size={2}>
                        <Row g={0} className="flex-nowrap mt-20">
                          <Col size="auto">
                            <Form.Field
                              name={['payment_scheme', i, 'interest_enabled']}
                              label=" "
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox />
                            </Form.Field>
                          </Col>
                          <Col>
                            <Form.Field
                              name={['payment_scheme', i, 'interest_sum']}
                              label={t('report1C.interest_sum')}
                              rules={[{ required: interest_enabled }]}
                            >
                              <Input type="number" step="0.01" disabled={!interest_enabled} />
                            </Form.Field>
                          </Col>
                        </Row>
                      </Col>
                      <Col size={2}>
                        <Row g={0} className="flex-nowrap mt-20">
                          <Col size="auto">
                            <Form.Field
                              name={['payment_scheme', i, 'commission_enabled']}
                              label=" "
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox />
                            </Form.Field>
                          </Col>
                          <Col>
                            <Form.Field
                              name={['payment_scheme', i, 'commission_sum']}
                              label={t('report1C.commission_sum')}
                              rules={[{ required: commission_enabled }]}
                            >
                              <Input type="number" step="0.01" disabled={!commission_enabled} />
                            </Form.Field>
                          </Col>
                        </Row>
                      </Col>
                      <Col size={2}>
                        <Row g={0} className="flex-nowrap mt-20">
                          <Col size="auto">
                            <Form.Field
                              name={['payment_scheme', i, 'total_enabled']}
                              label=" "
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Checkbox />
                            </Form.Field>
                          </Col>
                          <Col>
                            <Form.Field
                              name={['payment_scheme', i, 'total_sum']}
                              label={t('report1C.total_sum')}
                              rules={[{ required: total_enabled }]}
                            >
                              <Input type="number" step="0.01" disabled={!total_enabled} />
                            </Form.Field>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Button
                      icon={Files}
                      onClick={() => {
                        setPaymentSchemes((prevState) => [
                          ...prevState,
                          {
                            principal_enabled,
                            interest_enabled,
                            commission_enabled,
                            total_enabled,
                          },
                        ]);

                        const payment_schemes = form.getFieldValue('payment_scheme');
                        const scheme = payment_schemes.find((_, y) => i === y);

                        form.setFieldsValue({
                          payment_scheme: [
                            ...payment_schemes,
                            {
                              ...scheme,
                              start_date: dayjs(scheme?.start_date, dateFormat)
                                .add(1, 'year')
                                .format(dateFormat),
                              end_date: dayjs(scheme?.end_date, dateFormat)
                                .add(1, 'year')
                                .format(dateFormat),
                            },
                          ],
                        });
                      }}
                    />
                    <Button
                      icon={Trash}
                      onClick={() => {
                        setPaymentSchemes((prevState) => prevState.filter((_, y) => i !== y));
                        form.setFieldsValue({
                          payment_scheme: [...form.getFieldValue('payment_scheme')].filter(
                            (_, y) => y !== i,
                          ),
                        });
                      }}
                    />
                  </Space>
                </Col>
              ),
            )}
            <Col size={12} className="mt-20">
              <Button
                onClick={() =>
                  setPaymentSchemes((prevState) => [
                    ...prevState,
                    {
                      start_date: '',
                      end_date: '',
                      principal_enabled: false,
                      principal_sum: 0,
                      interest_enabled: false,
                      interest_sum: 0,
                      commission_enabled: false,
                      commission_sum: 0,
                      total_enabled: false,
                      total_sum: 0,
                    },
                  ])
                }
              >
                {t('report1C.addPaymentScheme')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
          <Button type="primary" submit form="report-1C-form">
            {t('report1C.getReport')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
