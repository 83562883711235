import { Route } from 'types';
import {
  NomenclaturesLeadSource,
  NomenclaturesProducts,
  NomenclaturesReasons,
  NomenclaturesIncomingSource,
  NomenclaturesCurrencies,
  NomenclaturesDocuments,
  NomenclaturesSystemTask,
  NomenclaturesCustomTask,
  NomenclaturesActivityDomains,
  NomenclaturesCities,
} from 'features/nomenclatures/pages';
import {
  Charts,
  Customers,
  Folder,
  Funnel,
  Phone,
  Task,
  TaskList,
  UserAlone,
  Files,
  Map,
} from 'resources/icons';

export const routesNomenclatures: Route[] = [
  {
    name: 'NomenclaturesLeadSource',
    label: 'leadSource',
    path: '/lead-source',
    iconComponent: Charts,
    showInMenu: true,
    exact: true,
    Component: NomenclaturesLeadSource,
  },
  {
    name: 'NomenclaturesProducts',
    label: 'products',
    iconComponent: Task,
    showInMenu: true,
    path: '/products',
    exact: true,
    Component: NomenclaturesProducts,
  },
  {
    name: 'NomenclaturesActivityDomains',
    label: 'activityDomains',
    path: '/activity-domains',
    iconComponent: Files,
    showInMenu: true,
    exact: true,
    Component: NomenclaturesActivityDomains,
  },
  {
    name: 'NomenclaturesReasons',
    label: 'reasons',
    iconComponent: Funnel,
    showInMenu: true,
    path: `/reasons`,
    exact: true,
    Component: NomenclaturesReasons,
  },
  {
    name: 'NomenclaturesIncomingSource',
    label: 'incomingSource',
    iconComponent: Phone,
    showInMenu: true,
    path: '/incoming-source',
    exact: true,
    Component: NomenclaturesIncomingSource,
  },
  {
    name: 'NomenclaturesCurrency',
    label: 'currencies',
    iconComponent: TaskList,
    showInMenu: true,
    path: '/currency',
    exact: true,
    Component: NomenclaturesCurrencies,
  },
  {
    name: 'NomenclaturesDocuments',
    label: 'documents',
    iconComponent: Customers,
    showInMenu: true,
    path: '/documents',
    exact: true,
    Component: NomenclaturesDocuments,
  },
  {
    name: 'NomenclaturesSystemTask',
    label: 'taskType',
    iconComponent: Folder,
    showInMenu: true,
    path: '/task-type/system',
    isActive: (path: string): boolean => path.replaceAll('\\', '').split('/').includes('task-type'),
    exact: true,
    Component: NomenclaturesSystemTask,
  },
  {
    name: 'NomenclaturesCustomTask',
    path: '/task-type/custom',
    iconComponent: UserAlone,
    showInMenu: false,
    exact: false,
    Component: NomenclaturesCustomTask,
  },
  {
    name: 'NomenclaturesCities',
    path: '/cities',
    label: 'cities',
    iconComponent: Map,
    showInMenu: true,
    exact: false,
    Component: NomenclaturesCities,
  },
];
