import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Col, Icon, ListGroup, Loader, Row, Space, useNotify } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { Funnel } from 'components/molecules';
import { getColor } from 'components/molecules/Charts/utils';
import { Funnel as FunnelIcon } from 'resources/icons';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { SalesFunnelReport } from 'types';

interface SalesFunnelCardProps {
  id?: number;
}

export const SalesFunnelCard: React.FC<SalesFunnelCardProps> = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const params = useQueryParams();
  const { getSalesFunnel } = useReportsAPI();

  const { data, isLoading } = useQuery<SalesFunnelReport>(
    [
      'sales-funnel-report',
      {
        ...params,
        date: undefined,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getSalesFunnel,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const tableData = React.useMemo(() => {
    if (!data) return;
    const objectData = Object.keys(data);
    return objectData.map((item, key) => ({
      text: t(`salesFunnelReport.${item}`),
      value: data[item].leads_count,
      color: getColor(key),
    }));
  }, [data]);

  return (
    <div className="sales-funnel-card">
      <Card>
        <Card.Header>{t('menu.sales_funnel')}</Card.Header>
        <Card.Body>
          <Loader loading={isLoading}>
            <Row>
              <Col size={6}>
                {tableData?.find((i) => i.value > 0) ? (
                  <Funnel sortData={false} dataSource={tableData} />
                ) : (
                  <div className="sales-funnel-card__no-data">
                    <Icon component={FunnelIcon} />
                    <span>{t('main.noData')}</span>
                  </div>
                )}
              </Col>
              <Col size={6}>
                <ListGroup className="bg-transparent no-border">
                  {tableData?.map(({ text, value, color }, key) => (
                    <ListGroup.Item key={`list-item-${key}`}>
                      <Space justify="space-between">
                        <Space size="small" className="p-7">
                          <div className="reports-point" style={{ background: color }} />
                          {text}
                        </Space>
                        {value}
                      </Space>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </Loader>
        </Card.Body>
      </Card>
    </div>
  );
};
