export interface Sources {
  id: number;
  name: string;
  expression: string;
  source_type: SOURCES_TYPE;
  created_at: Date;
  modified_at: Date;
}
export enum SOURCES_TYPE {
  ONLINE = 'online',
  OFFLINE = 'offline',
}
