import * as React from 'react';
import cn from 'classnames';
import { Icon } from 'ebs-design';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { MeetingForm } from './MeetingForm';
import { Message } from 'resources/icons';
import { LogType } from 'types';

export const CreateMeeting: React.FC = () => {
  const { toggleCreateMeeting } = React.useContext(LogsPanelContext);

  return (
    <div className="activity-log-item">
      <div className="activity-log-item__icon-box">
        <div className={cn('activity-log-item__icon', LogType.MEETING)}>
          <Icon component={Message} />
        </div>
      </div>
      <div className="activity-log-item__body">
        <MeetingForm onCloseForm={toggleCreateMeeting} />
      </div>
    </div>
  );
};
