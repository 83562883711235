import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Form, Row, Col, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { useLeadsImportAPI } from 'api';
import { ImportProgress, ImportStep, Lists, SelectOption } from 'types';

const step = ImportStep.CONFIRM_FIELD_MAPPING;

export const ConfirmField: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = useForm();
  const history = useHistory();
  const notify = useNotify();
  const { getImportByIdPreview, getImportHeaders, patchStep, getImportById } = useLeadsImportAPI();

  const { data: headers } = useQuery<Lists>(['leads-import-headers'], getImportHeaders);

  const { data: leadImport } = useQuery<ImportProgress>(['import', { id }], getImportById, {
    enabled: id !== 'add',
  });

  const { data: preview } = useQuery<{ data: string[][] }>(
    ['import-preview', { id }],
    getImportByIdPreview,
  );

  React.useEffect(() => {
    if (leadImport) {
      const { headers, ...rest } = leadImport;
      form.setFieldsValue({ headers: headers.map((i) => i || undefined), rest });
    }
  }, [leadImport]);

  const mutation = useMutation(patchStep, {
    onError: () => {
      notify.error({
        title: t('error.someThingIsWrong'),
      });
    },
    onSuccess: (response: ImportProgress) => {
      history.push(`/contacts/import/${response.id}/${response.step}`);
    },
  });

  const headersOptions: SelectOption[] = React.useMemo(
    () => headers?.map((item) => ({ text: item.label, value: item.key })) || [],
    [headers],
  );

  const handleSubmit = (): void => {
    const { headers } = form.getFieldsValue();
    const headersArray = Object.values(headers || {}).map((i) => i);
    mutation.mutate({
      headers: headersArray,
      id,
      step: ImportStep.CONFIRM_FIELD_MAPPING,
    } as any);
  };

  return (
    <div className="confirm-import">
      <Card.Header className="bold">{t('import.descriptionForConfirmFieldMapping')}</Card.Header>
      <Card.Body>
        <Form id={`form-${step}`} form={form} onFinish={handleSubmit} className="mapping-fields">
          <Row className="mapping-fields_head">
            <Col size={3} className="bold">
              {t('import.headerRow')}
            </Col>
            <Col size={4} className="bold">
              {t('import.moduleField')}
            </Col>
            <Col size={4}>
              <div className="confirm-import__last-col bold">{t('import.raw1')}</div>
            </Col>
          </Row>
          {preview?.data.length &&
            preview?.data[0]?.map((item, key) => (
              <Row key={key} className="mapping-fields_names">
                <Col size={3}>{item}</Col>
                <Col size={4}>
                  <Form.Field key={key} name={['headers', `${key}`]}>
                    <SmartSelect
                      size="small"
                      options={headersOptions}
                      mode="single"
                      placeholder={t('main.select')}
                    />
                  </Form.Field>
                </Col>
                <Col size={4}>
                  <div className="confirm-import__last-col">
                    {preview.data[1] && preview.data[1][key]}
                  </div>
                </Col>
              </Row>
            ))}
        </Form>
      </Card.Body>
    </div>
  );
};
