import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Form, Icon, Modal, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { useLeadsAPI, useUsersAPI } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { userTransformResults } from 'utils';
import { ValidationError } from 'errors';
import { Properties } from 'types';

interface AssignToProps {
  leads: number[];
  queryKey?: string | string[];
  onClose: () => void;
}

export const AssignTo: React.FC<AssignToProps> = ({ leads, queryKey, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [form] = useForm();
  const { getUsers } = useUsersAPI();
  const { assigneeTo } = useLeadsAPI();

  const assigneeToMutation = useMutation(assigneeTo, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: (e: Properties) => {
      queryClient.invalidateQueries(queryKey || ['contacts']);
      onClose();
      notify.success({ title: e.message });
    },
  });

  const handleSubmit = (): void => {
    assigneeToMutation.mutate({ ...form.getFieldsValue(), leads });
  };

  return (
    <Modal
      closeOnClickOutside={false}
      open
      header={<h3>{t('contacts.reAssignResponsible')}</h3>}
      onClose={onClose}
    >
      <Modal.Content>
        <Form form={form} id="assign-to-form" onFinish={handleSubmit}>
          <Form.Field name="assignee_to" label={t('contacts.firstName')}>
            <SmartSelect
              search
              api={getUsers}
              mode="single"
              placeholder={t('main.select')}
              transform={userTransformResults}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button
            prefix={<Icon type="check" model="bold" />}
            type="primary"
            submit
            form="assign-to-form"
          >
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
