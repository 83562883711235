import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useBranchesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getBranches: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/branches/list`, query), getRequest);
    },

    getBranchesMarketingReportList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/branches/marketing-report/list`, query), getRequest);
    },
  };
};
