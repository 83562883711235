import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { Space, ButtonGroup, Button, Icon, Select, DatePicker } from 'ebs-design';
import { useUsersAPI, useBranchesAPI } from 'api';
import { dateFormatInput } from 'libs';
import { useQueryUpdate } from 'hooks/useQueryParams';
import { Filters as Filter } from 'components/molecules';
import { defaultNonPagination } from 'utils';
import { Properties, Results, User, Branches } from 'types';
import { UserAlone, TaskList } from 'resources/icons';

interface Props {
  filters: Properties;
  setFilters: (value: Properties) => void;
}

export const Filters: React.FC<Props> = ({ filters, setFilters }) => {
  const { t } = useTranslation();

  const { getUsers } = useUsersAPI();
  const { getBranches } = useBranchesAPI();

  const { updateQuery } = useQueryUpdate();
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [branchesFilter, setBranchesFilter] = useSetState(defaultNonPagination);

  const { data: users } = useQuery<Results<User>>(['users', usersFilter], getUsers);

  const { data: branches } = useQuery<Branches>(['branches', branchesFilter], getBranches);

  React.useEffect(() => updateQuery(filters), [filters]);

  return (
    <Space className="mb-15" justify="space-between">
      <h3 className="title">{t('menu.dashboard')}</h3>

      <Space>
        {filters.date_from !== undefined && (
          <DatePicker.Range
            dateFormat={dateFormatInput}
            value={[filters.date_from, filters.date_to]}
            onChange={(value) =>
              value &&
              (value[0] || value[1]) &&
              setFilters({ date_from: value[0], date_to: value[1] })
            }
            isClearable
          />
        )}

        <ButtonGroup>
          <Button
            type={filters.date_from !== undefined ? 'primary' : 'ghost'}
            onClick={() =>
              setFilters({
                date_from: null,
                date_to: null,
                days: undefined,
              })
            }
          >
            {t('filter.period')}
          </Button>
          <Button
            type={filters.days === 7 ? 'primary' : 'ghost'}
            onClick={() => setFilters({ days: 7, date_from: undefined, date_to: undefined })}
          >
            7 {t('nomenclature.days')}
          </Button>
          <Button
            type={filters.days === 14 ? 'primary' : 'ghost'}
            onClick={() => setFilters({ days: 14, date_from: undefined, date_to: undefined })}
          >
            14 {t('nomenclature.days')}
          </Button>
          <Button
            type={filters.days === 30 ? 'primary' : 'ghost'}
            onClick={() => setFilters({ days: 30, date_from: undefined, date_to: undefined })}
          >
            {t('nomenclature.oneMonth')}
          </Button>
        </ButtonGroup>

        <Filter onChange={({ user }) => setFilters({ user })}>
          <Filter.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('filter.user')}
            value={filters.user}
            field="user"
          >
            <Select.Options>
              {(users?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={users?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filter.Select>
        </Filter>

        <Filter onChange={({ branch }) => setFilters({ branch })}>
          <Filter.Select
            prefix={<Icon component={TaskList} />}
            placeholder={t('filter.branch')}
            value={filters.branch}
            field="branch"
          >
            <Select.Options>
              {(branches?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-branch`} value={item.key}>
                  {item.label}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={branches?.count || 0}
              limit={branchesFilter.per_page}
              page={branchesFilter.page}
              setPage={(page) => setBranchesFilter({ page })}
              mode="scroll"
            />
          </Filter.Select>
        </Filter>
      </Space>
    </Space>
  );
};
