import * as React from 'react';
import cn from 'classnames';
import { SortBy, Space } from 'ebs-design';
import { SortProps } from '../interface';
import { FiltersContext } from '../Filters';

interface SortFilterProps extends Omit<SortProps, 'value'> {
  value?: string;
}

export const SortFilter: React.FC<SortFilterProps> = ({
  field,
  options,
  placeholder,
  sortByTitle,
  value,
  className,
}) => {
  const { onChange } = React.useContext(FiltersContext);
  const handleChange = (v: string): void => {
    onChange({ [field]: v });
  };

  return (
    <Space className={cn(className, 'filter-item')}>
      <SortBy
        value={value}
        title={placeholder}
        sortByTitle={sortByTitle}
        options={options}
        onChange={handleChange}
      />
    </Space>
  );
};
