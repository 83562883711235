import { User } from './user';
import { Cities } from './cities';

export interface Owner extends Omit<User, 'id'> {
  id: string;
  phone_number: number;
  sip: number;
}

export interface LeadVisit {
  id: number;
  owner: Owner;
  name: string;
}

export interface ContactsVisit {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  landline_number?: string;
  lead?: number;
}

export interface BranchVisit {
  id: number;
  modified_at: string;
  created_at: string;
  name: string;
}

export interface CityVisit extends Omit<Cities, 'region'> {
  region: {
    id: number;
    modified_at: string;
    created_at: string;
    name: string;
    country: number;
  };
}

export interface Visit {
  id: number;
  city: CityVisit;
  agent: Owner;
  lead: LeadVisit;
  branch: BranchVisit;
  contacts: ContactsVisit[];
  modified_at: string;
  created_at: string;
  type: VisitType;
  description?: string;
}

export enum VisitType {
  MARKETING = 'marketing',
  PLANNED = 'planned',
}

export interface ReportsVisits {
  id: string;
  marketing_visits: number;
  planned_visits: number;
  email: string;
  last_name: string;
  first_name: string;
  phone_number: number;
  total: number;
}
