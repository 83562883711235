import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, DatePicker, Icon, Modal, Card } from 'ebs-design';
import { DateType } from 'ebs-design/dist/components/molecules/DatePicker/types';
import { dateFormatInput } from 'libs';

export type PeriodType = DateType | undefined[];

interface CustomPeriodProp {
  onChange: (period_start: string, period_end: string) => void;
  onClose: () => void;
  initialValue: PeriodType;
}

export const CustomPeriod: React.FC<CustomPeriodProp> = ({ onClose, onChange, initialValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<PeriodType>(initialValue);

  const handleChange = (date: DateType): void => {
    setValue(date);
  };

  const handleSubmit = (): void => {
    if (Array.isArray(value)) {
      onChange(value[0]?.toString() || '', value[1]?.toString() || '');
    }
  };
  return (
    <Modal
      closeOnClickOutside={false}
      open
      size="small"
      onClose={onClose}
      className="custom-period"
    >
      <Card>
        <Space justify="center">
          <DatePicker.RangeInput
            monthsShown={2}
            dateFormat={dateFormatInput}
            inline
            onChange={handleChange}
            value={value}
          />
        </Space>
        <Card.Footer>
          <Space justify="space-between">
            <Space>
              <Button onClick={onClose}>{t('buttons.cancel')}</Button>
            </Space>
            <Space>
              <Button
                type="primary"
                onClick={handleSubmit}
                prefix={<Icon type="check" model="bold" />}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Space>
        </Card.Footer>
      </Card>
    </Modal>
  );
};
