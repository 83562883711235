import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Form, useForm, Input, useNotify } from 'ebs-design';
import { ValidationError } from 'errors';

interface NomenclaturesFormToProps {
  id?: number;
  formId: string;
  getItem: any;
  onFinish: any;
  onClose: () => void;
}

export const FormNomenclaturesCurrencies: React.FC<NomenclaturesFormToProps> = ({
  id,
  getItem,
  formId,
  onFinish,
  onClose,
}) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { data } = useQuery([formId, { id }], getItem, {
    enabled: Boolean(id),
  });

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const mutation = useMutation(onFinish, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({ title: t('error.someThingIsWrong') });
        onClose();
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([formId]);
      onClose();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({ ...form.getFieldsValue(), id });
  };
  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Form.Field name="name" label={t('nomenclature.name')}>
        <Input />
      </Form.Field>
      <Form.Field name="symbol" label={t('nomenclature.symbol')}>
        <Input />
      </Form.Field>
      <Form.Field name="ratio" label={t('nomenclature.ratio')}>
        <Input type="number" step="0.001" />
      </Form.Field>
    </Form>
  );
};
