import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Form, Space, Textarea, useForm, useNotify } from 'ebs-design';
import { useLogsAPI } from 'api';
import { Log, LogAction, LogType } from 'types';

interface Props {
  log?: Log;
  onCloseForm: () => void;
}

export const NoteForm: React.FC<Props> = ({ log, onCloseForm }) => {
  const { t } = useTranslation();
  const { id: lead } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { postNote, putLog } = useLogsAPI();
  const [form] = useForm();

  React.useEffect(() => {
    if (log) {
      form.setFieldsValue(log);
    }
  }, [log]);

  const mutation = useMutation(log ? putLog : postNote, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-logs']);
      onCloseForm();
    },
  });
  const handleSubmit = (): void => {
    mutation.mutate({
      ...form.getFieldsValue(),
      lead,
      id: log?.id,
      action: LogAction.UPDATED,
      type: LogType.NOTE,
    });
  };
  return (
    <Form form={form} onFinish={handleSubmit}>
      <Form.Field name="description" className="textarea-static-width">
        <Textarea placeholder={t('logs.note')} />
      </Form.Field>
      <Space justify="space-between">
        <Button type="ghost" size="small" onClick={onCloseForm}>
          {t('buttons.cancel')}
        </Button>
        <Button type="primary" size="small" submit>
          {t('buttons.save')}
        </Button>
      </Space>
    </Form>
  );
};
