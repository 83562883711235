import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  Actions,
  AvatarInline,
  Button,
  Icon,
  Label,
  Space,
  Modal,
  Textarea,
  useNotify,
  Form,
  useForm,
  DatePicker,
} from 'ebs-design';
import { dateTimeFormat, dateTimeFormatInput, format, getAPIDateFormat, today, toISO } from 'libs';
import { Arrow, Check, RotateBack } from 'resources/icons';
import { labelStatus, labelText } from 'utils';
import { useTaskAPI } from 'api';
import { Code, OutstandingType, Task } from 'types';

interface TaskItemProps {
  task: Task;
  onEdit: (id: number) => void;
  onComplete: (task: Task) => void;
  onDelete: (id: number) => void;
}

const getPriority = (task: Task, t): React.ReactNode => {
  return (
    <Label type="fill" status={labelStatus[task.priority]} text={t(labelText[task.priority])} />
  );
};

export const TaskItem: React.FC<TaskItemProps> = ({ task, onEdit, onComplete, onDelete }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = React.useState(false);
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { patchTask } = useTaskAPI();
  const [completeTaskItem, setCompleteTask] = React.useState<Task | undefined>();
  const [undo, setUndo] = React.useState(false);
  const [form] = useForm();

  const isCompleted = Boolean(task.completed_at);

  React.useEffect(() => {
    if (task) {
      const { comment, deadline } = task;
      form.setFieldsValue({
        comment: comment,
        ...(Object.values(OutstandingType).includes(
          task?.type?.code as unknown as OutstandingType,
        ) && { deadline: deadline && format(deadline, dateTimeFormat) }),
      });
    }
  }, [task]);

  const mutation = useMutation(
    (body: { id: number; completed_at?: string | null; comment?: string; deadline?: string }) =>
      patchTask(body),
    {
      onError: () =>
        notify.error({
          title: t('error.someThingIsWrong'),
        }),
      onSuccess: (response: Task) => {
        if (!response.completed_at) {
          queryClient.invalidateQueries(['lead-task']);
          queryClient.invalidateQueries(['lead-logs']);
          setUndo(false);
        } else {
          setTimeout(() => {
            queryClient.invalidateQueries(['lead-task']);
            queryClient.invalidateQueries(['lead-logs']);
            setUndo(false);
          }, 30000);
        }
      },
    },
  );

  const handleToggleCollapsed = (): void => {
    setCollapsed((s) => !s);
  };

  const handleComplete = (item): void => {
    if (!Object.values(Code).includes(item?.type?.code) || item?.type?.code === Code.GENERIC) {
      completeTask(item);
    } else {
      onComplete(item);
    }
  };

  const completeTask = (item): void => {
    setUndo(true);
    mutation.mutate({
      id: item.id,
      completed_at: toISO(today.toString()),
      comment: form.getFieldsValue().comment || task.comment,
      ...(form.getFieldsValue().deadline && {
        deadline: getAPIDateFormat(form.getFieldsValue().deadline),
      }),
    });
  };

  const handleUnCompleteTask = (): void => {
    mutation.mutate({ id: task.id, completed_at: null });
  };
  const handleDoubleClickEdit = (): void => {
    if (
      !task.completed_at &&
      ![
        Code.MEETING,
        Code.SEND_OFFER,
        Code.CALL_CLIENT,
        Code.SING_OFFER,
        Code.CREATE_REQUEST,
        Code.SEND_REQUEST_TO_APPROVE,
        Code.SIGN_CONTRACT,
      ].includes(task?.type?.code)
    ) {
      onEdit(task.id);
    }
  };
  const onCloseTask = React.useCallback(() => {
    if (completeTaskItem) {
      mutation.mutate({
        id: completeTaskItem.id,
        comment: form.getFieldsValue().comment || completeTaskItem.comment,
      });
    }

    setCompleteTask(undefined);
  }, [completeTaskItem, mutation]);
  return (
    <div className="task-item" onDoubleClick={handleDoubleClickEdit}>
      <Space justify="space-between">
        {undo ? (
          <Button
            size="small"
            type="fill"
            onClick={handleUnCompleteTask}
            className="task-item__complete-button task-item__rotate-back"
            prefix={<Icon component={RotateBack} />}
          >
            {t('task.undo')}
          </Button>
        ) : (
          <Button
            size="small"
            type="ghost"
            onClick={() =>
              !task.completed_at &&
              (!Object.values(Code).includes(task?.type?.code) || task?.type?.code === Code.GENERIC)
                ? setCompleteTask(task)
                : handleComplete(task)
            }
            className="task-item__complete-button"
            prefix={<Icon component={Check} />}
            disabled={isCompleted}
          >
            {t(isCompleted ? 'task.completedTask' : 'task.completeTask')}
          </Button>
        )}
        {getPriority(task, t)}
        {!task.completed_at ? (
          ![
            Code.MEETING,
            Code.SEND_OFFER,
            Code.CALL_CLIENT,
            Code.SING_OFFER,
            Code.CREATE_REQUEST,
            Code.SEND_REQUEST_TO_APPROVE,
            Code.SIGN_CONTRACT,
          ].includes(task?.type?.code) ? (
            <Actions title={t('task.action')} placement="right">
              <Actions.Item onClick={() => onEdit(task.id)}>{t('task.edit')}</Actions.Item>
              <Actions.Item onClick={() => onDelete(task.id)}>{t('task.delete')}</Actions.Item>
            </Actions>
          ) : (
            <Label type="fill" status="success" text={t('task.systemTaskShort')} />
          )
        ) : (
          <Icon type="dots" model="bold" className="disabled-icon action-task" />
        )}
      </Space>
      <div className="mt-10">{task.title}</div>
      <div className="task-item__deadline">{format(task.deadline, dateTimeFormat)}</div>
      {collapsed && (
        <>
          <div className="task-item__description">{task.description}</div>
          <AvatarInline
            className="mt-20"
            type="dynamic"
            shortAlt={[(task.owner?.first_name || '')[0], (task.owner?.last_name || '')[0]].join(
              '',
            )}
            alt={[task.owner?.first_name, task.owner?.last_name].join(' ') || '---'}
            circle
          />
        </>
      )}
      <Button
        className={cn('task-item__collapse-button', { collapsed })}
        type="ghost"
        onClick={handleToggleCollapsed}
        prefix={<Icon className="task-item__collapse-icon" component={Arrow} />}
      />
      {completeTaskItem && (
        <Modal closeOnClickOutside={false} open header={t('task.task')} onClose={onCloseTask}>
          <Modal.Content>
            <Form form={form}>
              <Form.Field name="comment">
                <Textarea placeholder={t('contacts.comment')} />
              </Form.Field>
              {Object.values(OutstandingType).includes(
                task?.type?.code as unknown as OutstandingType,
              ) && (
                <Form.Field name="deadline">
                  <DatePicker showTimeSelect dateFormat={dateTimeFormatInput} />
                </Form.Field>
              )}
            </Form>
          </Modal.Content>

          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={onCloseTask}>{t('buttons.cancel')}</Button>
              <Button
                type="primary"
                prefix={<Icon className="action-card__save-button" component={Check} />}
                onClick={() => {
                  handleComplete(completeTaskItem);
                  setCompleteTask(undefined);
                }}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
