import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Actions, Button, Card, Icon, Loader, Modal, Space, Table, useNotify } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { DocumentForm, Pagination } from 'components/organisms';
import { ConfirmModal } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { DocumentsFilter } from '../Filters/DocumentsFilter';
import { Check, Files, Plus } from 'resources/icons';
import { defaultFilters } from 'utils';
import { useDocumentsAPI } from 'api';
import { Column, Document, FilterType, Results } from 'types';

const formId = 'document-form';

export const Documents: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { deleteDocument } = useDocumentsAPI();
  const { getDocuments } = useDocumentsAPI();
  const { updateQuery } = useQueryUpdate();
  const { loader } = React.useContext(LoaderContext);
  const [openForm, setOpenForm] = React.useState<number | string>();
  const [deleteIdItem, setDeleteIdItem] = React.useState();

  const { data: documents, isLoading } = useQuery<Results<Document>>(
    ['documents', { ...defaultFilters, ...params }],
    getDocuments,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const mutation = useMutation(deleteDocument, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
      setDeleteIdItem(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const handleEdit = (id: number): void => {
    setOpenForm(id);
  };

  const handleAdd = (): void => {
    setOpenForm('add');
  };

  const handleClose = (): void => {
    setOpenForm(undefined);
  };

  const handleDelete = (): void => {
    if (deleteIdItem) {
      mutation.mutate(deleteIdItem);
    }
  };

  const columns: Column<Document>[] = React.useMemo(
    () => [
      {
        title: t('documents.name'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name, file }) => (
          <a href={file} download={name} target="_blank" rel="noreferrer">
            <Icon component={Files} className="mr-10" />
            {name}
          </a>
        ),
      },
      {
        title: t('documents.category'),
        onFilter: (type) => handleFilter(type, 'category'),
        render: ({ category }) => category?.name,
      },
      {
        title: t('documents.privacy'),
        onFilter: (type) => handleFilter(type, 'is_public'),
        render: ({ is_public }) => t(is_public ? 'documents.public' : 'documents.private'),
      },
      {
        title: t('documents.description'),
        dataIndex: 'description',
      },
      {
        title: t('documents.action'),
        align: 'right',
        render: ({ id, file, name }): React.ReactNode => (
          <Space justify="end">
            <Actions>
              <div className="table-actions">
                <a href={file} download={name} target="_blank" rel="noreferrer">
                  <Actions.Item>{t('documents.download')}</Actions.Item>
                </a>
                <Actions.Item onClick={() => handleEdit(id)}>{t('documents.edit')}</Actions.Item>
                <Actions.Item onClick={() => setDeleteIdItem(id)}>
                  {t('documents.delete')}
                </Actions.Item>
              </div>
            </Actions>
          </Space>
        ),
      },
    ],
    [t, params],
  );

  const buttonLoading = React.useMemo(() => {
    if (formId && loader) {
      return loader[formId];
    }
    return false;
  }, [formId, loader]);

  return (
    <>
      {deleteIdItem && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteIdItem(undefined)} />
      )}
      {openForm && (
        <Modal
          closeOnClickOutside={false}
          open
          header={t('documents.addDocument')}
          onClose={handleClose}
        >
          <Modal.Content>
            <DocumentForm
              formId={formId}
              id={openForm !== 'add' ? openForm.toString() : undefined}
              closeModal={handleClose}
            />
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
              <Button
                type="primary"
                submit
                form={formId}
                loading={buttonLoading}
                prefix={<Icon className="action-card__save-button" component={Check} />}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
      <DocumentsFilter
        count={documents?.count}
        rightComponents={
          <Button type="primary" onClick={handleAdd} prefix={<Icon component={Plus} />}>
            {t('documents.addDocument')}
          </Button>
        }
      />
      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            data={documents?.results}
            columns={columns}
            className="no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={documents?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
