import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
} from 'recharts';
import { Card, Loader } from 'ebs-design';
import { Tooltip as CustomTooltip } from 'components/molecules/Charts';
import { getColor } from 'components/molecules/Charts/utils';
import { useReportsAPI } from 'api';
import { format, dateFormat } from 'libs';
import { abbreviateNumber } from 'utils';
import { DashboardProps } from 'types';

const color = getColor(4, 'secondary');

export const Disbursements: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const { getContractsChart } = useReportsAPI();

  const { data = [], isLoading } = useQuery<any>(
    ['contracts-chart', { ...params }],
    getContractsChart,
    {
      select: (response) =>
        response.map(({ value, date }) => ({
          value,
          date: format(
            date,
            dateFormat
              .split('.')
              .slice(0, !params.currentYear ? 3 : 2)
              .join('.'),
          ),
        })),
    },
  );

  return (
    <Card>
      <Card.Header>{t('reports.evolutionDisbursements')}</Card.Header>

      <Loader loading={isLoading}>
        <Card.Body className="chart__container pb-0 pl-0">
          <ResponsiveContainer>
            <ComposedChart data={data}>
              <defs>
                <linearGradient id="colorLine2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                  <stop offset="95%" stopColor={color} stopOpacity={0} />
                </linearGradient>
              </defs>

              <CartesianGrid vertical={false} strokeDasharray="4 0" />
              <XAxis dataKey="date" scale="band" />
              <YAxis
                yAxisId="left"
                orientation="left"
                scale="auto"
                type="number"
                tickFormatter={(value) => abbreviateNumber(value)}
              />
              <Tooltip
                content={({ active, payload }) =>
                  active && payload ? (
                    <CustomTooltip
                      title={payload[0].value as string}
                      content={payload[0].payload.date}
                    />
                  ) : null
                }
              />
              <Area
                type="monotone"
                yAxisId="left"
                dataKey="value"
                stroke={color}
                fillOpacity={1}
                fill="url(#colorLine2)"
                activeDot={{ stroke: color, strokeWidth: 4 }}
                dot={{ stroke: 'white', strokeWidth: 3, r: 6, fill: color }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Card.Body>
      </Loader>
    </Card>
  );
};
