import * as React from 'react';
import { AvatarInline, Button, ButtonGroup, Icon, Space } from 'ebs-design';
import { MeetingForm } from '../ExtraComponents/MeetingForm';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { Pen, Trash } from 'resources/icons';
import { dateTimeFormat, format } from 'libs';
import { Log } from 'types';

interface ActivityLogMeetingProps {
  log: Log;
  onDelete: (id) => void;
}

export const ActivityLogMeeting: React.FC<ActivityLogMeetingProps> = ({ log, onDelete }) => {
  const [edit, setEdit] = React.useState(false);

  return (
    <>
      {!edit ? (
        <Space justify="space-between">
          <div className="activity-log-item__content">
            <span>{log.description}</span>
          </div>
          <Space className="activity-log-item__right-side">
            <span>{log.lead?.owner && log.lead.owner.name}</span>
            <span className="activity-log-item__created-at">
              {format(log.created_at, dateTimeFormat)}
            </span>
            {log.created_by && (
              <AvatarInline
                type="dynamic"
                shortAlt={[
                  (log.created_by?.first_name || '')[0],
                  (log.created_by?.last_name || '')[0],
                ].join('')}
                alt={[log.created_by?.first_name, log.created_by?.last_name].join(' ') || '---'}
                circle
              />
            )}
            {useRolePermission([UserRoleCRM.admin]) ? (
              <ButtonGroup>
                <Button
                  type="ghost"
                  size="small"
                  className="activity-log-item__delete"
                  onClick={() => setEdit(true)}
                  prefix={<Icon className="activity-log-item__delete__icon" component={Pen} />}
                />
                <Button
                  type="ghost"
                  size="small"
                  className="activity-log-item__delete"
                  onClick={() => onDelete(log.id)}
                  prefix={<Icon className="activity-log-item__delete__icon" component={Trash} />}
                />
              </ButtonGroup>
            ) : (
              <Button
                type="ghost"
                size="small"
                className="activity-log-item__delete"
                onClick={() => setEdit(true)}
                prefix={<Icon className="activity-log-item__delete__icon" component={Pen} />}
              />
            )}
          </Space>
        </Space>
      ) : (
        <MeetingForm onCloseForm={() => setEdit(false)} log={log} />
      )}
    </>
  );
};
