import { User } from './user';
import { Task } from './task';
import { Lead } from './leads';
import { Opportunity } from './opportunity';
import { Contact } from './contacts';
import { Call } from './calls';

export interface Log {
  id: number;
  description?: string;
  full_description?: string;
  type: LogType;
  action: LogAction;
  contact?: Contact;
  opportunity?: Opportunity;
  lead?: Lead;
  task?: Task;
  call?: Call;
  is_hidden?: boolean;
  meeting_on: string;
  created_at: string;
  created_by: User;
  modified_at?: string;
  direction?: string;
}

export enum LogAction {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum LogType {
  TASK = 'task',
  OPPORTUNITY_CHANGE = 'opportunity_change',
  LEAD_CHANGE = 'lead_change',
  CALL = 'call',
  EMAIL = 'email',
  MEETING = 'meeting',
  NOTE = 'note',
  CHAT = 'chat',
}

export enum LogMessageDirectionType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
