import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TabsType: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="nav-tab__box">
      <NavLink
        to={'/nomenclatures/task-type/system'}
        className="nav-tab__link mr-30 pt-20 pb-20"
        activeClassName="nav-tab__link--active"
      >
        {t('nomenclature.systemTask')}
      </NavLink>
      <NavLink
        to={'/nomenclatures/task-type/custom'}
        className="nav-tab__link mr-30 pt-20 pb-20"
        activeClassName="nav-tab__link--active"
      >
        {t('nomenclature.customTask')}
      </NavLink>
    </div>
  );
};
