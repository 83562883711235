import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Loader } from 'ebs-design';
import { SessionContext } from 'contexts/SessionContext';
import { useDomain } from 'hooks/useDomain';
import { Logout, UserAlone } from 'resources/icons';

export const UserProfileTooltip: React.FC = () => {
  const { t } = useTranslation();
  const { active, getUrl } = useDomain();
  const { session, onLogout } = React.useContext(SessionContext);
  const { available_organizations = [] } = session?.data || {};
  const isLoading = !session?.data;

  return (
    <div className="user-profile-tooltip">
      <div className="user-profile-tooltip__header">{t('userProfile.switchAccount')}</div>
      <div className="user-profile-tooltip__account-list">
        <Loader loading={isLoading}>
          {available_organizations.map(({ photo, name, domain_name }, i) => {
            const isActive = active === domain_name;

            return (
              <div
                key={i}
                onClick={() => !isActive && window.open(getUrl(domain_name), '_blank')}
                className={cn('user-profile-tooltip__account-list__item', {
                  'user-profile-tooltip__account-list__item--active': isActive,
                })}
              >
                {photo && <img src={photo} />}
                {name}
              </div>
            );
          })}
        </Loader>
      </div>
      <div className="user-profile-tooltip__main">
        <div className="user-profile-tooltip__header">{t('userProfile.profileManagement')}</div>
        <div className="user-profile-tooltip__profile-management">
          <Link
            className="user-profile-tooltip__profile-management__item"
            onClick={() => window.open(`/profile`, '_self')}
          >
            <UserAlone />
            {t('userProfile.myProfile')}
          </Link>
          <Link
            className="user-profile-tooltip__profile-management__item"
            onClick={() => onLogout()}
          >
            <Logout />
            {t('userProfile.logout')}
          </Link>
        </div>
      </div>
    </div>
  );
};
