import * as React from 'react';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Space } from 'ebs-design';
import { Filters } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Files, UserAlone } from 'resources/icons';
import { useDocumentCategoriesAPI, useLeadsAPI } from 'api';
import { defaultNonPagination } from 'utils';
import { List, Results } from 'types';

interface DocumentsFilterProps {
  count?: number;
  rightComponents?: React.ReactNode;
}

interface FiltersProps {
  search?: string;
  category?: string;
  lead?: string;
}

export const DocumentsFilter: React.FC<DocumentsFilterProps> = ({ count, rightComponents }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { getLeadsList } = useLeadsAPI();
  const { getDocumentsCategoryList } = useDocumentCategoriesAPI();
  const { updateQuery } = useQueryUpdate();
  const [leadsFilter, setLeadsFilter] = useSetState(defaultNonPagination);
  const [categoryFilter, setCategoryFilter] = useSetState(defaultNonPagination);

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  const { data: leads } = useQuery<Results<List>>(['leads', leadsFilter], getLeadsList);

  const { data: categories } = useQuery<Results<List>>(
    ['documents-category', categoryFilter],
    getDocumentsCategoryList,
  );

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const handleFilters = (value): void => {
    setFilters(value);
  };

  return (
    <>
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`documents.documents`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
          <Filters.Select
            prefix={<Icon component={Files} />}
            placeholder={t('filter.category')}
            value={params.category && parseInt(params.category)}
            field="category"
          >
            <Select.Options>
              {(categories?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-category`} value={item.key}>
                  {item.label}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={categories?.count || 0}
              limit={categoryFilter.per_page}
              page={categoryFilter.page}
              setPage={(page) => setCategoryFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('filter.leads')}
            value={params.lead && parseInt(params.lead)}
            field="lead"
          >
            <Select.Search
              value={leadsFilter.search}
              onSearch={(search) => setLeadsFilter({ search, page: 1 })}
            />
            <Select.Options>
              {(leads?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-lead`} value={item.key}>
                  {item.label}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={leads?.count || 0}
              limit={leadsFilter.per_page}
              page={leadsFilter.page}
              setPage={(page) => setLeadsFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
        </Space>
        <Space justify="end">{rightComponents}</Space>
      </Filters>
    </>
  );
};
