import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ImportProgress, ImportStep } from 'types';
import { Tooltip } from 'ebs-design';

interface ImportInfo {
  item: ImportProgress;
  key: string | number;
}

const getLink = (id: number, step: ImportStep): string => {
  return `/contacts/import/${id}/${step}`;
};

export const ImportInfo: React.FC<ImportInfo> = ({
  item: { id, lead_list, owner, step, status, progress },
}) => {
  const { t } = useTranslation();
  const content = (
    <>
      <div className="import-info__item import-info__key">{id}</div>
      <div className="import-info__item import-info__name">{lead_list?.name || '---'}</div>
      <div className="import-info__item import-info__mail">{owner?.email || '---'}</div>
      <div className="import-info__item import-info__status">
        <Tooltip
          placement="top"
          trigger="hover"
          tooltip={
            step !== ImportStep.COMPLETED ? t(`import.${step}`) : t(`import.${status}`) || '---'
          }
        >
          <div className="no-wrap">
            {step !== ImportStep.COMPLETED ? t(`import.${step}`) : t(`import.${status}`) || '---'}
          </div>
        </Tooltip>
      </div>
      <div className="import-info__item import-info__progress">
        <div className="progress-bar">
          <div className="progress-bar_progress">
            <div className="progress-bar_progress-line" style={{ width: `${progress}%` }} />
          </div>
          <div className="progress-bar_percent">{progress}%</div>
        </div>
      </div>
    </>
  );

  if (step !== ImportStep.COMPLETED) {
    return (
      <Link to={getLink(id, step)} className="import-info">
        {content}
      </Link>
    );
  }

  return <div className="import-info">{content}</div>;
};
