import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useDashboardAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLatestOpportunities: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/dashboard/opportunities`, query), getRequest);
    },
    getReportsChart: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/dashboard/reports-chart`, query), getRequest);
    },
    getSalesReport: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/dashboard/sales-report`, query), getRequest);
    },
    getTasks: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/dashboard/tasks`, query), getRequest);
    },
    getTopExperts: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/dashboard/top-experts`, query), getRequest);
    },
  };
};
