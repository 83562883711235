import * as React from 'react';
import { ConfirmModal } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNotify } from 'ebs-design';
import { useOpportunitiesAPI } from 'api';

interface OpportunityContextState {
  opportunitiesAllDecline: (id: number) => void;
}

const defaultContextValue: OpportunityContextState = {
  opportunitiesAllDecline: () => undefined,
};

export const OpportunityContext = React.createContext<OpportunityContextState>(defaultContextValue);

export const OpportunityContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { declineAllOpportunity } = useOpportunitiesAPI();
  const [openDeclineModal, setOpenDeclineModal] = React.useState(false);
  const [state, setState] = React.useState(0);

  const onLeadIdAdd = (id): void => {
    setState(id);
    setOpenDeclineModal(true);
  };

  const declineAllOpportunityMutation = useMutation((id: number) => declineAllOpportunity(id), {
    onError: () => {
      notify.error({
        title: t('error.someThingIsWrong'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      queryClient.invalidateQueries(['tasks']);
      queryClient.invalidateQueries(['lead']);
      queryClient.invalidateQueries(['opportunities']);
      setOpenDeclineModal(false);
    },
  });

  const onOpportunitiesAllDecline = (): void => {
    declineAllOpportunityMutation.mutate(state);
  };

  return (
    <OpportunityContext.Provider
      value={{
        opportunitiesAllDecline: (id) => onLeadIdAdd(id),
      }}
    >
      <>
        {children}
        {openDeclineModal && (
          <ConfirmModal
            content={t('opportunities.declineAllOpportunity')}
            onCancel={() => setOpenDeclineModal(false)}
            onSuccess={() => onOpportunitiesAllDecline()}
          ></ConfirmModal>
        )}
      </>
    </OpportunityContext.Provider>
  );
};
