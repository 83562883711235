import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Select } from 'ebs-design';
import { SizeType } from 'ebs-design/dist/types';
import type {
  OptionsMode,
  SelectMode,
} from 'ebs-design/dist/components/molecules/Select/interfaces';
import type { ScrollMode } from 'ebs-design/dist/components/molecules/Select/components/Pagination';
import { Results, Properties, SelectOption } from 'types';
import { defaultNonPagination, generateString, generalTransformResults } from 'utils';

interface SmartSelectProps {
  queryKey?: string;
  className?: string;
  placeholder?: string;
  api?: ({ queryKey }: Properties) => Promise<Results<any>>;
  filters?: Properties;
  options?: SelectOption[];
  value?: any;
  search?: boolean;
  enabled?: boolean;
  mode?: SelectMode;
  selected?: SelectOption | SelectOption[];
  paginationMode?: ScrollMode;
  size?: SizeType;
  optionsMode?: OptionsMode;
  disabled?: boolean;
  transform?: (results: Properties[]) => Properties[];
  onChange?: (value: any) => void;
  onLoaded?: (value?: Results<Properties>) => void;
}

export const SmartSelect: React.FC<SmartSelectProps> = ({
  queryKey,
  placeholder: placeholderText,
  className,
  api,
  value,
  options: optionsList,
  filters: baseFilters = {},
  selected,
  enabled = true,
  search = false,
  mode,
  paginationMode = 'regular',
  size = 'medium',
  optionsMode = 'dropdown',
  disabled,
  transform = generalTransformResults,
  onChange,
  onLoaded,
}) => {
  const key = React.useMemo(() => queryKey || generateString(), [queryKey]);

  const { t } = useTranslation();
  const [filters, setFilters] = useSetState<Properties>({ ...defaultNonPagination });

  const { data, isLoading, isFetching } = useQuery<Results<Properties>>(
    [key, { ...filters, ...baseFilters }],
    api || (() => new Promise(() => null)),
    {
      enabled: !!api && enabled,
    },
  );

  const placeholder = React.useMemo(
    () => placeholderText || t('main.select'),
    [t, placeholderText],
  );

  const options = React.useMemo(
    () => (!api ? optionsList : (((data && transform(data?.results)) || []) as SelectOption[])),
    [data, optionsList, api, transform],
  );

  React.useEffect(() => {
    if (onLoaded && !isLoading && !isFetching) {
      onLoaded(data);
    }
  }, [data, isLoading, isFetching]);

  return (
    <Select
      optionsMode={optionsMode}
      size={size}
      className={className}
      placeholder={placeholder}
      loading={api ? isLoading || isFetching : false}
      options={options}
      value={value}
      mode={mode}
      disabled={disabled}
      selected={selected}
      onChange={onChange}
      isClearable
    >
      {!!api && search && (
        <Select.Search
          value={filters.search}
          onSearch={(search) =>
            setFilters({
              ...((search.length || (!search.length && filters?.search?.length)) && { search }),
              page: 1,
            })
          }
        />
      )}

      <Select.Options />

      {!!api && (
        <Select.Pagination
          count={data?.count || 0}
          limit={defaultNonPagination.per_page}
          page={filters.page}
          setPage={(page) => setFilters({ page })}
          mode={paginationMode}
        />
      )}
    </Select>
  );
};
