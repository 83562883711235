import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Loader, useNotify, Card } from 'ebs-design';
import { useLeadsAPI } from 'api';
import { LeadSimilar } from 'types';
import { AffiliateContactsItem } from './AffiliateContactsItem';

export const AffiliateContactsCard: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const { getLeadsAffiliate } = useLeadsAPI();

  const { data, isLoading } = useQuery<LeadSimilar[]>(
    ['affiliate-leads', { id }],
    getLeadsAffiliate,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  return (
    <Card size="small" className="action-card mt-20">
      <Card.Header className="action-card__header">
        <span className="action-card__count" style={{ backgroundColor: '#ff9900' }}>
          {data?.length || 0}
        </span>
        {t('lead.affiliateContacts')}
      </Card.Header>
      <Card.Body className="action-card__body">
        <Loader loading={isLoading}>
          {data?.length
            ? data?.map((lead, key) => <AffiliateContactsItem key={key} lead={lead} />)
            : t('main.noData')}
        </Loader>
      </Card.Body>
    </Card>
  );
};
