import * as React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ImportLayout } from 'features/contacts/ImportLayout';
import { UploadFile, ConfirmImport, ConfirmField, CheckDuplicates, StartImport } from '../steps';
import { useTranslation } from 'react-i18next';
import { ImportStep, Route as RouteProps } from 'types';

export const ContactImportSteps: React.FC = () => {
  const { t } = useTranslation();
  const { step } = useParams();
  const steps = React.useMemo<RouteProps[]>(
    () => [
      {
        name: 'UploadFile',
        path: `/contacts/import/:id/first`,
        step: 'first',
        exact: true,
        Component: UploadFile,
      },
      {
        name: 'ConfirmImport',
        path: `/contacts/import/:id/${ImportStep.CONFIRM_IMPORTED_FILE}`,
        step: ImportStep.CONFIRM_IMPORTED_FILE,
        exact: true,
        Component: ConfirmImport,
      },
      {
        name: 'ConfirmField',
        path: `/contacts/import/:id/${ImportStep.CONFIRM_FIELD_MAPPING}`,
        step: ImportStep.CONFIRM_FIELD_MAPPING,
        exact: true,
        Component: ConfirmField,
      },
      {
        name: 'CheckDuplicates',
        path: `/contacts/import/:id/${ImportStep.CHECK_FOR_POSSIBLE_DUPLICATES}`,
        step: ImportStep.CHECK_FOR_POSSIBLE_DUPLICATES,
        exact: true,
        Component: CheckDuplicates,
      },
      {
        name: 'StartImport',
        path: `/contacts/import/:id/${ImportStep.START_IMPORT}`, //todo check use route match router sub routing
        step: ImportStep.START_IMPORT,
        exact: true,
        Component: StartImport,
      },
    ],
    [t],
  );

  return (
    <div className="contact-import-steps">
      <Switch>
        <ImportLayout step={step} steps={steps}>
          {steps.map((item, key) => (
            <Route key={key} name={item.name} path={item.path} exact={item.exact}>
              <item.Component />
            </Route>
          ))}
        </ImportLayout>
      </Switch>
    </div>
  );
};
