import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { PieChart, Pie, Cell, Sector, Tooltip, ResponsiveContainer } from 'recharts';
import { Row, Col, Card, ListGroup, Space, Loader } from 'ebs-design';
import { getColor } from 'components/molecules/Charts/utils';
import { Tooltip as CustomTooltip } from 'components/molecules';
import { useReportsAPI } from 'api';
import { abbreviateNumber } from 'utils';
import { DashboardProps, PieData } from 'types';

export const ShareSalesByMarketingChannel: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const { getSalesWeightDiagram } = useReportsAPI();

  const [activeIndex, setActiveIndex] = React.useState<number | undefined>();

  const { data = { data: [], total: 0 }, isLoading } = useQuery<PieData>(
    ['sales-diagram', { ...params }],
    getSalesWeightDiagram,
  );

  const renderActiveShape = React.useCallback(
    (props) => {
      const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = props;

      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={getColor(8, 'secondary')}
          />
        </g>
      );
    },
    [t],
  );

  return (
    <Card>
      <Card.Header>{t('reports.shareSalesByMarketingChannel')}</Card.Header>

      <Loader loading={isLoading}>
        <Card.Body>
          <Row>
            <Col size={6}>
              <div className="chart__container">
                <div className={cn('chart__data', 'chart__data--pie')}>
                  <h3>{abbreviateNumber(data.total)}</h3>
                  <span>{'MDL'}</span>
                </div>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Tooltip
                      content={({ active, payload }) =>
                        active && payload ? (
                          <CustomTooltip
                            title={payload[0].name as string}
                            content={`${(payload[0].value as number).toFixed(2)} %`}
                          />
                        ) : null
                      }
                    />
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={data.data}
                      cx="50%"
                      cy="50%"
                      innerRadius={110}
                      outerRadius={150}
                      strokeWidth={0}
                      dataKey="value"
                      onMouseEnter={(_, index) => setActiveIndex(index)}
                      onMouseLeave={() => setActiveIndex(undefined)}
                    >
                      {data.data.map((_, index) => (
                        <Cell key={`cell-${index}`} fill={getColor(index, 'secondary')} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
            <Col size={6}>
              <ListGroup className="bg-transparent no-border">
                <ListGroup.Item>
                  <Space justify="space-between">
                    <>{t('nomenclature.name')}</>
                    <>{t('nomenclature.weight')}</>
                  </Space>
                </ListGroup.Item>
                {data.data
                  .map(({ name, value }, key) => (
                    <ListGroup.Item key={`list-item-${key}`}>
                      <Space justify="space-between">
                        <Space size="small">
                          <div
                            className="reports-point"
                            style={{ background: getColor(key, 'secondary') }}
                          />
                          <b>{name}</b>
                        </Space>
                        <>{value.toFixed(2)} %</>
                      </Space>
                    </ListGroup.Item>
                  ))
                  .reverse()}
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Loader>
    </Card>
  );
};
