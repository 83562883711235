import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useLeadsImportAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getImports: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/leads-import`, query), getRequest);
    },
    getImportById: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads-import/${id}`, getRequest);
    },
    getImportByIdPreview: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads-import/${id}/preview`, getRequest);
    },
    getImportUniqueField: () => {
      return fetcher(`/leads-import/unique-fields`, getRequest);
    },
    getImportHeaders: () => {
      return fetcher(`/leads-import/headers`, getRequest);
    },
    postStep: (data) => {
      return fetcher(`/leads-import`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    putStep: ({ id, ...data }) => {
      return fetcher(`/leads-import/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    patchStep: ({ id, ...data }) => {
      return fetcher(`/leads-import/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
  };
};
