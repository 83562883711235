import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Space, Table } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { abbreviateNumber, getPeriodByName, stringifyUrl } from 'utils';
import { Column, Task } from 'types';
import { SessionContext } from 'contexts/SessionContext';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';

interface Props {
  title?: string | number;
  description?: string;
  type: 'expired' | 'future' | 'current';
  data?: Task[];
  isAdmin?: boolean;
  onClick: (id: number) => void;
}

export const TaskCard: React.FC<Props> = ({ title, description, type, data, isAdmin, onClick }) => {
  const { t } = useTranslation();
  const { session } = React.useContext(SessionContext);
  const history = useHistory();
  const hasPermission = useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin]);

  const [deadline_end_expired] = getPeriodByName('yesterday');
  const [deadline_start_future] = getPeriodByName('tomorrow');
  const [deadline_start_today, deadline_end_today] = getPeriodByName('today');

  const columns: Column<Task>[] = React.useMemo(
    () => [
      {
        title: null,
        render: ({ title, priority, type: typeId, owner, created_by }): React.ReactNode => (
          <Space size="small">
            <div
              className={cn('task-card-priority', {
                [`task-card-priority--${priority}`]: priority,
              })}
            />
            <Link
              to={stringifyUrl(`/tasks/`, {
                type: typeId,
                owner,
                created_by,
                deadline: type === 'current' ? 'today' : 'custom',
                ...((type === 'expired' && { deadline_end: deadline_end_expired }) ||
                  (type === 'future' && { deadline_start: deadline_start_future }) ||
                  (type === 'current' && {
                    deadline_end: deadline_end_today,
                    deadline_start: deadline_start_today,
                  })),
              })}
            >
              {title}
            </Link>
          </Space>
        ),
      },
    ],
    [onClick],
  );

  const taskListRoute = (type: string): void => {
    const queryUser = {
      deadline: type === 'current' ? 'today' : 'custom',

      ...((type === 'expired' && { deadline_end: deadline_end_expired }) ||
        (type === 'future' && { deadline_start: deadline_start_future }) ||
        (type === 'current' && {
          deadline_end: deadline_end_today,
          deadline_start: deadline_start_today,
        })),

      ...(hasPermission && !isAdmin && { owner: session?.data?.user.sso_id }),
    };

    history.push(stringifyUrl(`/tasks/`, queryUser));
  };

  return (
    <Row className="task-card">
      <Col className={`task-card--${type}`}>
        <div className="active-route-link" onClick={() => taskListRoute(type)}>
          <Space direction="vertical" size="small">
            <h1>{title ? abbreviateNumber(title as number) : 0}</h1>
            <div className="task-card__description">
              <b>{t('menu.tasks').toUpperCase()}</b>
              <small>{description}</small>
            </div>
          </Space>
        </div>
      </Col>
      <Col className="p-0">
        <Table emptyText={t('main.noData')} columns={columns} data={data} />
      </Col>
    </Row>
  );
};
