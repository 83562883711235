import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader, Space } from 'ebs-design';
import { Pagination } from 'components/organisms';
import { TabsType } from '../TabsType';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Results, Column, FilterType, TaskType } from 'types';
import { useTaskTypesAPI } from 'api';
import { defaultFilters } from 'utils';

export const NomenclaturesSystemTask: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { updateQuery } = useQueryUpdate();
  const { getSystemTaskTypes } = useTaskTypesAPI();

  const { data: systemTask, isLoading } = useQuery<Results<TaskType>>(
    ['system-task', { ...defaultFilters, ...params }],
    getSystemTaskTypes,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<TaskType>[] = React.useMemo(
    () => [
      {
        title: t('nomenclature.id'),
        onFilter: (type) => handleFilter(type, 'id'),
        render: ({ id }): React.ReactNode => id,
      },
      {
        title: t('nomenclature.name'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name }): React.ReactNode => t(`systemTaskCode.${name}`),
      },
      {
        title: t('nomenclature.codeName'),
        onFilter: (type) => handleFilter(type, 'code'),
        render: ({ code }): React.ReactNode => code,
      },
    ],
    [t, systemTask, params],
  );
  return (
    <>
      <TabsType />
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">{`${t(`nomenclature.systemTask`)}${
            systemTask?.count ? ` (${systemTask.count})` : ``
          }`}</h3>
        </Space>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table data={systemTask?.results} columns={columns} className="no-border" />
        </Loader>
        <Card.Footer>
          <Pagination size={systemTask?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
