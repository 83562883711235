import * as React from 'react';
import { NavLink, useRouteMatch, Switch, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from 'ebs-design';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { SessionContext } from 'contexts/SessionContext';
import { ForbiddenError } from 'components/atoms';
import { routesNomenclatures } from 'utils';

export const NomenclaturesLayout: React.FC = () => {
  const { session } = React.useContext(SessionContext);

  if (session?.data?.current_application?.application_role !== UserRoleCRM.admin) {
    return <ForbiddenError />;
  } else {
    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const { pathname } = useLocation();

    return (
      <div className="nomenclatures">
        <div className="nomenclatures__side-menu">
          <div className="nomenclatures__side-menu__title">Nomenclatures</div>
          <div className="nomenclatures__side-menu__list">
            {routesNomenclatures
              .filter(({ showInMenu }) => showInMenu)
              .map(({ path, label, iconComponent, isActive }, index) => (
                <NavLink
                  className="nomenclatures__side-menu__list-link"
                  activeClassName="active"
                  key={index}
                  to={`${url}${path}`}
                  isActive={isActive ? () => isActive(pathname) : isActive}
                >
                  <Icon component={iconComponent} />
                  {t(`nomenclature.${label}`)}
                </NavLink>
              ))}
          </div>
        </div>
        <div className="nomenclatures__content">
          <Switch>
            {routesNomenclatures.map((item, index) => (
              <Route
                key={index}
                path={`${path}${item.path}`}
                exact={item.exact}
                name={item.name}
                component={item.Component}
              />
            ))}
          </Switch>
        </div>
      </div>
    );
  }
};
