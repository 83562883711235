import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Icon, Loader, Select, Space } from 'ebs-design';
import { useSetState } from 'react-use';
import { Filters } from 'components/molecules';
import { Map } from 'resources/icons';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useBranchesAPI } from 'api';
import { defaultFilters, formatSelectValueToNumber } from 'utils';
import { Results, List } from 'types';

interface ReportFilterProps {
  count?: number;
}

interface FiltersProps {
  branch?: string;
  date?: string;
  date_from?: string;
  date_to?: string;
}

export const ReportVisitsFilter: React.FC<ReportFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getBranchesMarketingReportList, getBranches } = useBranchesAPI();
  const [branchesFilter, setBranchesFilter] = useSetState(defaultFilters);

  const { data: branches, isLoading } = useQuery<Results<List>>(
    ['branches', { ...defaultFilters, ...branchesFilter }],
    useRolePermission([UserRoleCRM.marketing]) ? getBranchesMarketingReportList : getBranches,
  );

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    if (value?.date) {
      const [date_from, date_to] = value?.date;

      if (date_from && date_to) {
        setFilters({ date_from, date_to });
      }
      return;
    }
    if (value?.date === null) {
      setFilters({
        date_from: undefined,
        date_to: undefined,
      });
      return;
    }
    setFilters(value);
  };

  return (
    <Filters onChange={handleFilters}>
      <h3 className="title">{`${t(`reports.visits`)}${count ? ` (${count})` : ``}`}</h3>
      <Space>
        <Filters.DatePickerFilter
          value={[filters.date_from, filters.date_to]}
          field="date"
          type="range"
          placeholder={t('reports.sortByDate')}
        />
        <Filters.Select
          prefix={<Icon component={Map} />}
          placeholder={t('filter.branch')}
          value={formatSelectValueToNumber(params.branch)}
          field="branch"
        >
          <Select.Options>
            {(branches?.results || []).map((item, key) => (
              <Select.Options.Item key={`${key}-branch`} value={item.key}>
                {item.label}
              </Select.Options.Item>
            ))}
          </Select.Options>

          {isLoading && <Loader.Inline />}

          <Select.Pagination
            count={branches?.count || 0}
            limit={branchesFilter.per_page}
            page={branchesFilter.page}
            setPage={(page) => setBranchesFilter({ page })}
            mode="scroll"
          />
        </Filters.Select>
      </Space>
    </Filters>
  );
};
