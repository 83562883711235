import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useSourcesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getSources: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/sources`, query), getRequest);
    },
    getSourceById: ({ queryKey: [, { id }] }) => fetcher(`/sources/${id}`, getRequest),
    createSource: (data) => {
      return fetcher('/sources', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateSource: ({ id, ...data }) => {
      return fetcher(`/sources/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteSource: (id: number) => {
      return fetcher(`/sources/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
