import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Space } from 'ebs-design';

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" className="error-page">
      <h1 className="error-page__title">{t('error.404')}</h1>
      <h2 className="error-page__description">{t('error.pageNotFound')}</h2>
    </Space>
  );
};
