import { SOURCES_TYPE } from './sources';
import { Task } from './task';
import { ShortLead } from './leads';
import { Currency } from './currency';

export interface OnlineReport {
  id: number;
  leads: number;
  conversion_leads: string;
  visits: number;
  opportunities: number;
  conversion_opportunities: string;
  signed_contracts: number;
  conversion_contracts: string;
  sum_signed_contracts: number;
  avg_signed_contracts: string;
  marketing_cost: number;
  roi: string;
  cost_per_visit: string;
  budget: number;
  variation: string;
  modified_at: string;
  created_at: string;
  name: string;
  expression: string;
  source_type: SOURCES_TYPE;
}

export interface OfflineReport {
  id: number;
  leads: number;
  opportunities: number;
  conversion_opportunities: string;
  contracts: number;
  conversion_contracts: string;
  sum_signed_contracts: number;
  avg_signed_contracts: string;
  marketing_cost: number;
  roi: string;
  budget: number;
  variation: string;
  modified_at: string;
  created_at: string;
  name: string;
  expression: string;
  source_type: SOURCES_TYPE;
}

export interface ReportsCalls {
  id: number;
  name?: string;
  incoming_calls: number;
  outgoing_calls: number;
  total_calls: number;
  incoming_calls_duration: number;
  outgoing_calls_duration: number;
  outgoing_calls_outstanding: number;
  outgoing_calls_sales: number;
  total_calls_duration: number;
  incoming_leads: number;
  outgoing_leads: number;
  total_leads: number;
  incoming_signed_contracts: number;
  outgoing_signed_contracts: number;
  total_signed_contracts: number;
  incoming_signed_contracts_value: number;
  outgoing_signed_contracts_value: number;
  total_signed_contracts_value: number;
  incoming_avg_signed_contracts_value: number;
  outgoing_avg_signed_contracts_value: number;
  total_avg_signed_contracts_value: number;
  incoming_pbx_cost: number;
  outgoing_pbx_cost: number;
  total_pbx_cost: number;
  email: string;
  last_name: string;
  first_name: string;
  phone_number: number;
}

export type SalesFunnelReport = {
  [key in keyof typeof SalesFunnelReportsKey]: {
    conversion_leads: number;
    leads_count: number;
  };
};

export enum SalesFunnelReportsKey {
  APPROVED = 'approved',
  COMMITTEE_APPROVAL = 'committee_approval',
  CREDIT_APPLICATION_SIGNED = 'credit_application_signed',
  OFFER_ACCEPTED = 'offer_accepted',
  OFFER_TRANSMITTED = 'offer_transmitted',
  QUALIFIED_LEADS = 'qualified_leads',
  UNQUALIFIED_LEADS = 'unqualified_leads',
}

export interface SalesReport {
  approved: number;
  committee_approval: number;
  credit_application_signed: number;
  offer_accepted: number;
  offer_transmitted: number;
  qualified_leads: number;
  unqualified_leads: number;
}

interface TaskReport {
  tasks: Task[];
  total_tasks: number;
}

export interface TasksReport {
  current_tasks: TaskReport;
  future_tasks: TaskReport;
  past_tasks: TaskReport;
}

export interface UserTasksReport {
  admin: TasksReport;
  user: TasksReport;
}

export interface LeadReport {
  currency: string;
  id: number;
  lead_value: number;
  name: string;
}

export interface CreditExpert {
  id: string;
  name: string;
  count: number;
}

export interface PieData {
  data: { id: number; name: string; value: number; i?: number }[];
  total: number;
}

export interface LastOpportunityReport {
  id: number;
  lead: ShortLead;
  value: number;
  currency: Currency;
}

export interface ReportsCallsBranches {
  id: number;
  incoming_avg_signed_contracts_value: number;
  incoming_calls: number;
  incoming_calls_duration: number;
  incoming_leads: number;
  incoming_pbx_cost: number;
  incoming_signed_contracts: number;
  incoming_signed_contracts_value: number;
  name: string;
  outgoing_avg_signed_contracts_value: number;
  outgoing_calls: number;
  outgoing_calls_duration: number;
  outgoing_calls_outstanding: number;
  outgoing_calls_sales: number;
  outgoing_leads: number;
  outgoing_pbx_cost: number;
  outgoing_signed_contracts: number;
  outgoing_signed_contracts_value: number;
  total_avg_signed_contracts_value: number;
  total_calls: number;
  total_calls_duration: number;
  total_leads: number;
  total_pbx_cost: number;
  total_signed_contracts: number;
  total_signed_contracts_value: number;
}

export interface ReportsCallsBranchesTotals {
  total_avg_signed_contracts_value: number;
  total_calls: number;
  total_calls_duration: number;
  total_calls_outstanding: number;
  total_calls_sales: number;
  total_incoming_avg_signed_contracts_value: number;
  total_incoming_calls: number;
  total_incoming_calls_duration: number;
  total_incoming_leads: number;
  total_incoming_pbx_cost: number;
  total_incoming_signed_contracts: number;
  total_incoming_signed_contracts_value: number;
  total_leads: number;
  total_outgoing_avg_signed_contracts_value: number;
  total_outgoing_calls: number;
  total_outgoing_calls_duration: number;
  total_outgoing_leads: number;
  total_outgoing_pbx_cost: number;
  total_outgoing_signed_contracts: number;
  total_outgoing_signed_contracts_value: number;
  total_pbx_cost: number;
  total_signed_contracts: number;
  total_signed_contracts_value: number;
}

export interface ReportsCallsBranchesResponse {
  branches: ReportsCallsBranches[];
  totals: ReportsCallsBranchesTotals;
}

export interface ReportCallsNormalizedToProps {
  name?: string;
  call_type?: string;
  calls: number;
  calls_duration: number;
  leads: number;
  pbx_cost: number;
  signed_contracts: number;
  signed_contracts_value: number;
  avg_signed_contracts_value: number;
  outgoing_calls_outstanding?: number;
  outgoing_calls_sales?: number;
}
