import * as React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Space } from 'ebs-design';
import { useLeadsImportAPI } from 'api';
import { Pagination } from 'components/organisms';
import { useQueryParams } from 'hooks/useQueryParams';
import { ImportInfo } from 'features/contacts/ImportInfo';
import { ImportProgress, Results } from 'types';
import { defaultFilters } from 'utils';

export const ContactImport: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { getImports } = useLeadsImportAPI();

  const { data: dataLeadsImport } = useQuery<Results<ImportProgress>>(
    ['import', { ...defaultFilters, ...params }],
    getImports,
  );

  return (
    <>
      <Space className="mb-20" justify="space-between">
        <Space justify="start">
          <Space>
            <h3>
              {`${t(`import.progressList`)}${
                dataLeadsImport?.count ? ` (${dataLeadsImport.count})` : ``
              }`}
            </h3>
          </Space>
        </Space>
        <Space justify="end">
          <Link to={'/contacts/import/add/first'}>
            <Button type="primary">{t('import.contact')}</Button>
          </Link>
        </Space>
      </Space>
      <Card>
        <Card.Body>
          {dataLeadsImport?.results.map((item, key) => (
            <ImportInfo item={item} key={key} />
          ))}
        </Card.Body>
        <Card.Footer>
          <Pagination size={dataLeadsImport?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
