import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Form, Icon, Space, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { LeadListForm } from 'features/contacts/LeadListForm';
import { Plus } from 'resources/icons';
import { useLeadsImportAPI, useLeadsListAPI, useUsersAPI } from 'api';
import { userTransformResults } from 'utils';
import { ValidationError } from 'errors';
import { ImportStep } from 'types';

const step = ImportStep.START_IMPORT;

export const StartImport: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const notify = useNotify();
  const { getUsers } = useUsersAPI();
  const { patchStep } = useLeadsImportAPI();
  const { getLeadsList } = useLeadsListAPI();
  const [form] = useForm();

  const [open, setOpen] = React.useState(false);

  const mutation = useMutation(patchStep, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      notify.success({
        title: t('main.withSuccess'),
      });
      history.push('/contacts/import');
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({ ...form.getFieldsValue(), id, step: ImportStep.START_IMPORT });
  };

  return (
    <div className="check-duplicates">
      <Card.Header className="bold">{t('import.descriptionStartImport')}</Card.Header>
      <Card.Body>
        <Form onFinish={handleSubmit} form={form} id={`form-${step}`} className="start-import">
          <Form.Field name="lead_list" className="form-item" label={t('import.selectExistingList')}>
            <SmartSelect
              search
              queryKey="leads-list-select"
              api={getLeadsList}
              placeholder={t('filter.contactList')}
              optionsMode="box"
            />
          </Form.Field>
          <Space justify="end" className="mb-20">
            <Button type="primary" onClick={() => setOpen(true)} prefix={<Icon component={Plus} />}>
              {t('import.addNewList')}
            </Button>
          </Space>

          <Form.Field name="owner" label={t('import.selectResponsiblePerson')}>
            <SmartSelect
              search
              paginationMode="regular"
              optionsMode="box"
              api={getUsers}
              placeholder={t('main.select')}
              transform={userTransformResults}
            />
          </Form.Field>
        </Form>
      </Card.Body>
      {open && (
        <LeadListForm
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};
