import { Currency } from './currency';
import { Opportunity } from './opportunity';
import { User } from './user';
import { Nomenclatures } from './nomenclatures';

export interface Contract {
  id: number;
  value: number;
  number: string;
  status: ContractStatus;
  balance_loan: number;
  balance_overdue: number;
  outstanding_days: number;
  currency: Currency;
  owner?: User;
  opportunity: Opportunity;
  product: Nomenclatures;
  pledged_goods: string;
  deadline_at: string;
  signed_at: string;
  created_at: string;
  modified_at: string;
}

export enum ContractStatus {
  SIGNED = 'signed',
  CLOSED = 'closed',
}
