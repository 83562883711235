import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Space, Textarea, useNotify } from 'ebs-design';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { Check, RotateBack } from 'resources/icons';
import { today, toISO } from 'libs';
import { useTaskAPI } from 'api';
import { Code, Log, LogAction, Task } from 'types';

interface CompleteTaskButtonProps {
  log: Log;
}

export const CompleteTaskButton: React.FC<CompleteTaskButtonProps> = ({ log }) => {
  const { t } = useTranslation();
  const [task, setTask] = React.useState<Task | undefined>();

  if ([LogAction.EXPIRED, LogAction.DELETED].includes(log.action)) {
    return (
      <Button size="small" type="ghost" className="task-item__complete-button" disabled>
        {t(`logs.${log.action}`)}
      </Button>
    );
  }

  const queryClient = useQueryClient();
  const notify = useNotify();
  const { patchTask } = useTaskAPI();
  const [undo, setUndo] = React.useState(false);
  const { openCompleteTask } = React.useContext(LogsPanelContext);

  const isCompleted = Boolean(log?.task?.completed_at);

  const mutation = useMutation(
    (body: { id: number; completed_at?: string | null; comment?: string }) => patchTask(body),
    {
      onError: () =>
        notify.error({
          title: t('error.someThingIsWrong'),
        }),
      onSuccess: (response: Task) => {
        if (!response.completed_at) {
          queryClient.invalidateQueries(['lead-task']);
          queryClient.invalidateQueries(['lead-logs']);
          setUndo(false);
        } else {
          setTimeout(() => {
            queryClient.invalidateQueries(['lead-task']);
            queryClient.invalidateQueries(['lead-logs']);
            setUndo(false);
          }, 30000);
        }
      },
    },
  );

  const completeTask = (): void => {
    setUndo(true);
    if (task?.id) {
      mutation.mutate({
        id: task.id,
        completed_at: toISO(today.toString()),
        comment: task.comment,
      });
    }
  };
  const handleComplete = (item): void => {
    if (!Object.values(Code).includes(item?.type?.code) || item?.type?.code === Code.GENERIC) {
      completeTask();
    } else if (!isCompleted && item) {
      openCompleteTask(item);
    }
  };

  const handleUnCompleteTask = (): void => {
    if (log?.task?.id) {
      mutation.mutate({ id: log.task.id, completed_at: null });
    }
  };

  const onCloseTask = React.useCallback(() => {
    if (task?.comment?.length) {
      mutation.mutate({
        id: task.id,
        comment: task.comment,
      });
    }

    setTask(undefined);
  }, [task, mutation]);

  return (
    <>
      {undo ? (
        <Button
          size="small"
          type="fill"
          onClick={handleUnCompleteTask}
          className="task-item__complete-button task-item__rotate-back"
          prefix={<Icon component={RotateBack} />}
        >
          {t('task.undo')}
        </Button>
      ) : (
        <Button
          size="small"
          type="ghost"
          onClick={() =>
            log?.task &&
            (!Object.values(Code).includes(log?.task?.type?.code) ||
              log?.task?.type?.code === Code.GENERIC)
              ? setTask(log?.task)
              : handleComplete(log?.task)
          }
          className="task-item__complete-button"
          prefix={<Icon component={Check} />}
          disabled={isCompleted}
        >
          {t(isCompleted ? 'task.completedTask' : 'task.completeTask')}
        </Button>
      )}
      {task && (
        <Modal closeOnClickOutside={false} open header={t('task.task')} onClose={onCloseTask}>
          <Modal.Content>
            <Textarea
              value={task.comment}
              onChange={(comment) => setTask((prevTask) => ({ ...(prevTask as Task), comment }))}
            />
          </Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={onCloseTask}>{t('buttons.cancel')}</Button>
              <Button
                type="primary"
                prefix={<Icon className="action-card__save-button" component={Check} />}
                onClick={() => {
                  handleComplete(task);
                  setTask(undefined);
                }}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
