export const months = {
  month1: {
    en: `January`,
    ro: `Ianuarie`,
    ru: `Январь`,
  },
  month2: {
    en: `February`,
    ro: `Februarie`,
    ru: `Февраль`,
  },
  month3: {
    en: `March`,
    ro: `Martie`,
    ru: `Март`,
  },
  month4: {
    en: `April`,
    ro: `Aprilie`,
    ru: `Апрель`,
  },
  month5: {
    en: `May`,
    ro: `Mai`,
    ru: `Май`,
  },
  month6: {
    en: `June`,
    ro: `Iunie`,
    ru: `Июнь`,
  },
  month7: {
    en: `July`,
    ro: `Iulie`,
    ru: `Июль`,
  },
  month8: {
    en: `August`,
    ro: `August`,
    ru: `Август`,
  },
  month9: {
    en: `September`,
    ro: `Septembrie`,
    ru: `Сентябрь`,
  },
  month10: {
    en: `October`,
    ro: `Octombrie`,
    ru: `Октябрь`,
  },
  month11: {
    en: `November`,
    ro: `Noiembrie`,
    ru: `Ноябрь`,
  },
  month12: {
    en: `December`,
    ro: `Decembrie`,
    ru: `Декабрь`,
  },
};
