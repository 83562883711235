import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Icon, Space } from 'ebs-design';
import { ImportStep, Route } from 'types';
import { ImportFormFooter } from '../ImportForm';

interface ImportLayoutProps {
  step: string;
  steps: Route[];
}

export const ImportLayout: React.FC<ImportLayoutProps> = ({ step, steps, children }) => {
  const { t } = useTranslation();

  const stepList = Object.values(ImportStep);
  const activeIndex = stepList.findIndex((i) => i === step);

  const isActive = (currentStep: string): 'passed' | 'active' | undefined => {
    const currentIndex = stepList.findIndex((i) => i === currentStep);
    if (currentIndex === activeIndex) {
      return 'active';
    } else if (currentIndex < activeIndex) {
      return 'passed';
    }
  };

  return (
    <div>
      <Space justify="start" className="mb-20">
        <h3 className="title">{t(`import.contact`)}</h3>
      </Space>
      <div className="contact-import-steps_navigation">
        <div>
          {steps.map((item, key) => {
            return isActive(item.step) === 'passed' ? (
              <NavLink
                key={key}
                to={item.step}
                className={`contact-import-steps_navigation-link ${isActive(item.step)}`}
              >
                <span className="contact-import-steps_step">
                  {key + 1} <Icon type="check" model="bold" />
                </span>
                {t(`import.${item.name}`)}
              </NavLink>
            ) : (
              <div
                key={key}
                className={`contact-import-steps_navigation-link ${isActive(item.step)}`}
              >
                <span className="contact-import-steps_step">
                  {key + 1} <Icon type="check" model="bold" />
                </span>
                {t(`import.${item.name}`)}
              </div>
            );
          })}
        </div>
      </div>
      <Card>
        {children}
        <Card.Footer>
          <ImportFormFooter
            formId={`form-${step}`}
            backLink={stepList[activeIndex - 1] || 'first'}
            disabledBackLink={step === 'first'}
            directionNav={step !== ImportStep.START_IMPORT}
            importButton={step === ImportStep.START_IMPORT}
          />
          {/*todo*/}
        </Card.Footer>
      </Card>
    </div>
  );
};
