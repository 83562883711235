import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Actions, Label, Space } from 'ebs-design';
import { LabelStatus } from 'ebs-design/dist/components/atoms/Label/Label';
import { Contract, ContractStatus, Lead } from 'types';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useExpertPermission } from 'hooks/useExpertPermission';

interface ContractItemProps {
  contract: Contract;
  onEdit: (id) => void;
  onDelete: (id) => void;
  lead: Lead;
}

const contractStatusLabel: { [key: string]: LabelStatus } = {
  [ContractStatus.SIGNED]: 'success',
  [ContractStatus.CLOSED]: 'danger',
};

export const ContractItem: React.FC<ContractItemProps> = ({
  contract,
  lead,
  onEdit /*, onDelete */,
}) => {
  const { t } = useTranslation();

  return (
    <div className="contract-item" onDoubleClick={() => onEdit(contract.id)}>
      <Space justify="space-between" align="start">
        <Space justify="space-between">
          <Label
            type="fill"
            status={contractStatusLabel[contract.status]}
            text={t(`contracts.${contract.status}`)}
          />
          <div className="contract-item__number">
            {t('contracts.contractNr')} {contract.number}
          </div>
        </Space>
        {(useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
          useExpertPermission({ ownerId: lead.owner?.id })) && (
          <Actions title={t('opportunities.action')} placement="right">
            <Actions.Item onClick={() => onEdit(contract.id)}>
              {t('opportunities.view')}
            </Actions.Item>
            {/* <Actions.Item onClick={() => onDelete(contract.id)}>
            {t('opportunities.delete')}
          </Actions.Item> */}
          </Actions>
        )}
      </Space>
      <Space justify="start" size={0} className="contract-item__body mt-10">
        <div className="contract-item__body__col">
          <span className="contract-value">{contract.value}</span>
          <span className="contract-currency">{contract.currency?.name}</span>
        </div>
        <div className="contract-item__body__col">
          <span className="contract-balance_overdue">{contract.balance_overdue}</span>
          <span className="contract-outstanding-text">{t('contracts.outstanding')}</span>
        </div>
        <div className="contract-item__body__col">
          <span className="contract-loan">{contract.balance_loan}</span>
          <span className="contract-loan-text">{t('contracts.loan')}</span>
        </div>
      </Space>
    </div>
  );
};
