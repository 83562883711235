import * as React from 'react';
import cn from 'classnames';
import { Icon } from 'ebs-design';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { NoteForm } from './NoteForm';
import { Pen } from 'resources/icons';
import { LogType } from 'types';

export const CreateNote: React.FC = () => {
  const { toggleCreateNote } = React.useContext(LogsPanelContext);
  return (
    <div className="activity-log-item">
      <div className="activity-log-item__icon-box">
        <div className={cn('activity-log-item__icon', LogType.NOTE)}>
          <Icon component={Pen} />
        </div>
      </div>
      <div className="activity-log-item__body">
        <NoteForm onCloseForm={toggleCreateNote} />
      </div>
    </div>
  );
};
