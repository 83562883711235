import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Row, Col, Table, Alert, Icon, useForm, useNotify, Form } from 'ebs-design';
import { ValidationError } from 'errors';
import { useLeadsImportAPI } from 'api';
import { ImportProgress, ImportStep, Lists } from 'types';

interface ImportInterface {
  name: string;
}

const step = ImportStep.CHECK_FOR_POSSIBLE_DUPLICATES;

export const CheckDuplicates: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = useForm();
  const history = useHistory();
  const notify = useNotify();
  const { patchStep, getImportById, getImportUniqueField } = useLeadsImportAPI();

  const [importCheck, setImportCheck] = React.useState<ImportInterface[]>([]);
  const [importAvailable, setImportAvailable] = React.useState<ImportInterface[]>([]);

  const [errors, setErrors] = React.useState<{
    [key: string]: { name: string | string[]; errors: string[] };
  }>();

  const { data: leadImport } = useQuery<ImportProgress>(['import', { id }], getImportById);

  const { data: uniqueField } = useQuery<Lists>(
    ['leads-import-unique-fields'],
    getImportUniqueField,
  );

  const mutation = useMutation(patchStep, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        e?.fields?.map((i) => {
          setErrors({ [i.name as string]: { ...i } });
        });
      }
      notify.error({ title: t('error.someThingIsWrong') });
    },
    onSuccess: (response: ImportProgress) => {
      history.push(`/contacts/import/${response.id}/${response.step}`);
    },
  });

  React.useEffect(() => {
    if (uniqueField) {
      setImportCheck(
        uniqueField
          .filter((i) => !leadImport?.unique_verify?.includes(i.key.toString()))
          .map((item) => ({ name: item.key.toString() })),
      );
      setImportAvailable(
        uniqueField
          .filter((i) => leadImport?.unique_verify?.includes(i.key.toString()))
          .map((item) => ({ name: item.key.toString() })),
      );
    }
  }, [uniqueField, leadImport]);

  const columnsImportCheck = React.useMemo(
    () => [
      {
        title: 'Fields to Check',
        render: ({ name }) => (
          <div onClick={() => onToAvailableField(name)}>
            {name} <Icon type="arrow-right" />
          </div>
        ),
      },
    ],
    [importCheck],
  );

  const columnsImportAvailable = React.useMemo(
    () => [
      {
        title: 'Available Fields',
        render: ({ name }) => (
          <div onClick={() => onToCheckField(name)}>
            <Icon type="arrow-left" /> {name}
          </div>
        ),
      },
    ],
    [importAvailable],
  );

  const onToAvailableField = (prevName: string): void => {
    const newImportCheck = importCheck.filter(({ name }) => prevName !== name);
    setErrors(undefined);
    setImportCheck(() => newImportCheck);
    setImportAvailable((prevImportAvailable) => [...prevImportAvailable, { name: prevName }]);
  };

  const onToCheckField = (prevName: string): void => {
    const newImportAvailable = importAvailable.filter(({ name }) => prevName !== name);
    setImportAvailable(() => newImportAvailable);
    setImportCheck((prevImportCheck) => [...prevImportCheck, { name: prevName }]);
  };

  const handleSubmit = (): void => {
    mutation.mutate({
      ...form.getFieldsValue(),
      id,
      unique_verify: importAvailable.map((item) => item.name),
      step: ImportStep.CHECK_FOR_POSSIBLE_DUPLICATES,
    });
  };

  return (
    <div className="check-duplicates">
      <Card.Header className="bold">{t('import.descriptionCheckForDuplicates')}</Card.Header>
      <Card.Body>
        <Form onFinish={handleSubmit} form={form} id={`form-${step}`} className="wrap-tables">
          <Row>
            <Col size={6}>
              <Table emptyText={t('main.noData')} data={importCheck} columns={columnsImportCheck} />
            </Col>
            <Col size={6}>
              <Table
                emptyText={t('main.noData')}
                data={importAvailable}
                columns={columnsImportAvailable}
              />
              {errors?.unique_verify && (
                <Alert
                  message={errors.unique_verify.errors.join(', ')}
                  type="error"
                  className="mt-5"
                />
              )}
            </Col>
          </Row>
          <Alert message={t('import.alertCheckForDuplicates')} type="warning" className="mt-20" />
        </Form>
      </Card.Body>
    </div>
  );
};
