import React, { useContext } from 'react';
import { DatePicker, Space } from 'ebs-design';
import { dateFormatInput } from 'libs';

import { DatePickerProps } from '../interface';
import { FiltersContext } from '../Filters';

export const DatePickerFilter: React.FC<DatePickerProps> = ({
  field,
  value,
  type,
  placeholder,
  className,
  dateFormat = dateFormatInput,
}) => {
  const { onChange } = useContext(FiltersContext);

  const handleChange = (date: any): void => {
    if (
      type === 'range' && date
        ? !!date.filter((i) => i).length &&
          date.filter((i) => (value as string[])?.includes(i)).length < 2
        : date !== value
    ) {
      onChange({ [field]: date });
    }
  };

  let Piker;
  switch (type) {
    case 'range':
      Piker = DatePicker.RangeInput;
      break;
    default:
      Piker = DatePicker;
  }

  return (
    <Space className={className}>
      <Piker
        placeholderText={placeholder}
        className="filter-item datepicker"
        dateFormat={dateFormat}
        value={value}
        onChange={handleChange}
        isClearable
      />
    </Space>
  );
};
