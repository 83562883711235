import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Column, FilterType, LeadSalesFunnel } from 'types';
import { AddressCell } from '../components/molecules';
import { dateTimeFormat, format } from 'libs';

interface ColumnLead<T> extends Column<T> {
  checked?: boolean;
  value?: string;
}

export const useLeadColumns = (): {
  columns: ColumnLead<LeadSalesFunnel>[];
  setColumns: (value: string[]) => void;
} => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [checked, setChecked] = React.useState<string[]>([]);

  React.useEffect(
    () =>
      setChecked(
        params.columns || [
          'id',
          'fullName',
          'name',
          'owner',
          'opportunity.value',
          'opportunity_status',
        ],
      ),
    [],
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const handleChooseColumn = (options): void => {
    setChecked(options);
    updateQuery({ ...params, columns: options });
  };

  const columns = React.useMemo(
    () => [
      {
        title: t('lead.id'),
        onFilter: (type) => handleFilter(type, 'id'),
        render: ({ contact }): React.ReactNode => (
          <div className="medium">{contact.id ? contact.id : '---'}</div>
        ),
        value: 'id',
        checked: checked.includes('id'),
      },
      {
        title: t('lead.fullName'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ contact }): React.ReactNode =>
          contact?.first_name || contact?.last_name
            ? [contact?.first_name, contact?.last_name].join(' ')
            : '---',
        value: 'fullName',
        checked: checked.includes('fullName'),
      },
      {
        title: t('lead.leadName'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name }) => (name ? name : '---'),
        value: 'name',
        checked: checked.includes('name'),
      },
      {
        title: t('lead.creditExpert'),
        onFilter: (type) => handleFilter(type, 'owner'),
        render: ({ owner }): React.ReactNode =>
          owner?.first_name || owner?.last_name
            ? [owner?.first_name, owner?.last_name].join(' ')
            : '---',
        value: 'owner',
        checked: checked.includes('owner'),
      },
      {
        title: t('lead.value'),
        onFilter: (type) => handleFilter(type, 'opportunity_value'),
        render: ({ opportunity }): React.ReactNode => (
          <div className="medium">
            {opportunity?.value
              ? [opportunity?.value, opportunity?.currency?.name].join(' ')
              : '---'}
          </div>
        ),
        value: 'opportunity.value',
        checked: checked.includes('opportunity.value'),
      },
      {
        title: t('lead.conditionalStatus'),
        onFilter: (type) => handleFilter(type, 'opportunity_status'),
        render: ({ opportunity, status }) => (
          <div className="medium">
            {opportunity?.status
              ? t(`opportunities.${opportunity?.status}`)
              : status
              ? t(`statuses.${status}`)
              : '---'}
          </div>
        ),
        value: 'conditional.status',
        checked: checked.includes('conditional.status'),
      },
      // { //todo pana cand e off
      //   title: t('lead.idnp'),
      //   render: ({ contact }) => <div className="medium">{contact.idnp}</div>,
      //   value: 'idnp',
      //   checked: checked.includes('idnp'),
      // },
      {
        title: t('lead.phoneNumber'),
        render: ({ contact }) => (
          <div className="medium">{contact.phone_number ? contact.phone_number : '---'}</div>
        ),
        value: 'contact.phone_number',
        checked: checked.includes('contact.phone_number'),
      },
      {
        title: t('lead.address'),
        render: ({ address_city, address_country, address_region, address_street }) => (
          <div className="medium">
            {
              <AddressCell
                address_city={address_city}
                address_country={address_country}
                address_region={address_region}
                address_street={address_street}
              />
            }
          </div>
        ),
        value: 'address',
        checked: checked.includes('address'),
      },
      {
        title: t('lead.created_at'),
        render: ({ created_at }) => (
          <div className="medium">{created_at && format(created_at, dateTimeFormat)}</div>
        ),
        value: 'created_at',
        checked: checked.includes('created_at'),
      },
      {
        title: t('lead.modified_at'),
        render: ({ modified_at }) => (
          <div className="medium">{modified_at && format(modified_at, dateTimeFormat)}</div>
        ),
        value: 'modified_at',
        checked: checked.includes('modified_at'),
      },
      {
        title: t('lead.status'),
        onFilter: (type) => handleFilter(type, 'status'),
        render: ({ status }) => (
          <div className="medium">{status ? t(`statuses.${status}`) : '---'}</div>
        ),
        value: 'status',
        checked: checked.includes('status'),
      },
      {
        title: t('lead.opportunityStatus'),
        onFilter: (type) => handleFilter(type, 'opportunity_status'),
        render: ({ opportunity }) => (
          <div className="medium">
            {opportunity?.status ? t(`opportunities.${opportunity?.status}`) : '---'}
          </div>
        ),
        value: 'opportunity_status',
        checked: checked.includes('opportunity_status'),
      },
      {
        title: t('lead.closeDate'),
        render: ({ opportunity }) => (
          <div className="medium">
            {opportunity?.close_date ? format(opportunity.close_date, dateTimeFormat) : '---'}
          </div>
        ),
        value: 'close_date',
        checked: checked.includes('close_date'),
      },
    ],
    [checked, params],
  );

  return {
    columns,
    setColumns: handleChooseColumn,
  };
};
