import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Loader, useNotify } from 'ebs-design';
import { ActionCard } from 'features/leads/ActionCard';
import { ConfirmModal } from 'components/molecules';
import { ContactItem } from './ContactItem';
import { ContactForm } from './ContactForm';
import { ValidationError } from 'errors';
import { useContactAPI } from 'api';
import { Contact, Lead } from 'types';

const formId = 'add-contact';

interface ContactCardProps {
  lead: Lead;
}

export const ContactCard: React.FC<ContactCardProps> = ({ lead }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getLeadContacts, deleteContact, postInitCall } = useContactAPI();
  const [editId, setEditId] = React.useState();
  const [deleteId, setDeleteId] = React.useState();

  const { data, isLoading } = useQuery<Contact[]>(['contacts', { id }], getLeadContacts, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const mutation = useMutation(deleteContact, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['contacts']);
      setDeleteId(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const initCall = useMutation(postInitCall, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        e?.fields?.map((i) => {
          notify.error({ title: t(i.errors) });
        });
      } else {
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      notify.success({
        title: t('success.callWasInit'),
      });
      queryClient.invalidateQueries(['logs']);
    },
  });

  const onCall = (data): void => {
    if (data) {
      initCall.mutate(data);
    }
  };

  const onMail = (mail): void => {
    window.open(`mailto:${mail}`, '_blank');
  };

  const onEdit = (id): void => {
    setEditId(id);
  };
  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutation.mutate(deleteId);
    }
  };

  return (
    <>
      <ActionCard
        header={t('lead.contacts')}
        className="mt-20"
        backgroundColor="#019AE6"
        formId={formId}
        count={data?.length}
        editId={editId}
        unsetEdit={() => setEditId(undefined)}
        FormComponent={ContactForm}
      >
        <Loader loading={isLoading}>
          {data?.length
            ? data.map((contact, key) => (
                <ContactItem
                  key={key}
                  contact={contact}
                  onCall={onCall}
                  onEdit={onEdit}
                  onMail={onMail}
                  onDelete={onDelete}
                  lead={lead}
                />
              ))
            : t('main.noData')}
        </Loader>
      </ActionCard>
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}
    </>
  );
};
