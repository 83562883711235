import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Modal } from 'ebs-design';

interface FormModalProps {
  formId: string;
  isCreate?: boolean;
  onCancel: () => void;
}

export const FormModal: React.FC<FormModalProps> = ({ formId, isCreate, onCancel, children }) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeOnClickOutside={false}
      open
      size="small"
      header={<h3>{t(isCreate ? 'nomenclature.createEntity' : 'nomenclature.updateEntity')}</h3>}
      onClose={onCancel}
    >
      <Modal.Content>{children}</Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
          <Button type="primary" submit form={formId}>
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
