import * as React from 'react';
import { SessionContext } from 'contexts/SessionContext';
import { ServiceTypes } from './useFetcher/interfaces';

export const useUploadProps = (): {
  name: string;
  action: string;
  headers: { [key: string]: string };
} => {
  const { session, apiConfig } = React.useContext(SessionContext);

  if (!apiConfig) {
    throw new Error('Services configuration was not set, please check Bootstrap component');
  }

  const service = apiConfig[ServiceTypes.FS];

  if (!service) {
    throw new Error(`Service config missing for ${ServiceTypes.FS}`);
  }

  return {
    name: 'file',
    action: `${service.baseUrl}/file/upload/`,
    headers: {
      Authorization: `Bearer ${session?.tokens.access}`,
    },
  };
};
