import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, ButtonGroup, Icon, Space } from 'ebs-design';
import { BarChart, Call, Funnel, Map, ReportsChart, TableView } from 'resources/icons';
import { isCurrentURl } from 'utils';

export const ReportsLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="reports-layout">
      <Space justify="start" size={20} wrap className="reports-layout__navigation mb-20">
        <h3 className="title">{t(`reports.reports`)}</h3>

        <Link to="/report/expenses">
          <Button
            prefix={<Icon component={TableView} />}
            className={cn('reports-layout__navigation__link', {
              active: isCurrentURl('/report/expenses'),
            })}
          >
            {t(`reports.reportExpenses`)}
          </Button>
        </Link>

        <Link to="/report/dashboard">
          <Button
            prefix={<Icon component={BarChart} />}
            className={cn('reports-layout__navigation__link', {
              active: isCurrentURl('/report/dashboard'),
            })}
          >
            {t(`reports.reportDashboard`)}
          </Button>
        </Link>

        <ButtonGroup className="reports-layout__navigation__link-group">
          <Link to="/report/calls">
            <Button
              prefix={<Icon component={Call} />}
              className={cn('reports-layout__navigation__link', {
                active: isCurrentURl('/report/calls'),
              })}
            >
              {t(`reports.reportCalls`)}
            </Button>
          </Link>

          <Link to="/report/calls-branches">
            <Button
              className={cn('reports-layout__navigation__link', {
                active: isCurrentURl('/report/calls-branches'),
              })}
            >
              {t(`reports.reportCallsBranches`)}
            </Button>
          </Link>
        </ButtonGroup>

        <ButtonGroup className="reports-layout__navigation__link-group">
          <Link to="/report/online">
            <Button
              prefix={<Icon component={ReportsChart} />}
              className={cn('reports-layout__navigation__link', {
                active: isCurrentURl('/report/online'),
              })}
            >
              {t(`reports.reportOnline`)}
            </Button>
          </Link>

          <Link to="/report/offline">
            <Button
              className={cn('reports-layout__navigation__link', {
                active: isCurrentURl('/report/offline'),
              })}
            >
              {t(`reports.reportOffline`)}
            </Button>
          </Link>
        </ButtonGroup>

        <Link to="/report/sales-funnel">
          <Button
            prefix={<Icon component={Funnel} />}
            className={cn('reports-layout__navigation__link', {
              active: isCurrentURl('/report/sales-funnel'),
            })}
          >
            {t(`reports.reportSalesFunnel`)}
          </Button>
        </Link>

        <Link to="/report/visits">
          <Button
            prefix={<Icon component={Map} />}
            className={cn('reports-layout__navigation__link', {
              active: isCurrentURl('/report/visits'),
            })}
          >
            {t(`reports.reportVisits`)}
          </Button>
        </Link>
      </Space>

      {children}
    </div>
  );
};
