import { useFileAPI } from 'api/file';
import { UploadedFile } from 'types';

interface Props {
  customRequest: (data: any) => UploadedFile;
}

export const useUpload = (): Props => {
  const { uploadFile } = useFileAPI();

  return {
    customRequest: (options) => {
      const { file } = options;
      const fmData = new FormData();

      fmData.append('file', file);
      fmData.append('public', 'false');
      return uploadFile(fmData);
    },
  };
};
