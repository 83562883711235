import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useProductsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getProducts: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/products`, query), getRequest);
    },
    getProductById: ({ queryKey: [, { id }] }) => fetcher(`/products/${id}`, getRequest),
    createProduct: (data) => {
      return fetcher('/products', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateProduct: ({ id, ...data }) => {
      return fetcher(`/products/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteProduct: (id: number) => {
      return fetcher(`/products/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
