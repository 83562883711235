import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useTaskTypesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getTaskTypes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/task-types`, query), getRequest);
    },
    getAllTaskTypes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/task-types/all`, query), getRequest);
    },
    getSystemTaskTypes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/task-types/system`, query), getRequest);
    },
    getCustomTaskTypes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/task-types/generic`, query), getRequest);
    },
    getListTaskTypes: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/task-types/list`, query), getRequest);
    },
    getTaskTypeById: ({ queryKey: [, { id }] }) => fetcher(`/task-types/${id}`, getRequest),
    createTaskType: (data) => {
      return fetcher('/task-types', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateTaskType: ({ id, ...data }) => {
      return fetcher(`/task-types/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteTaskType: (id: number) => {
      return fetcher(`/task-types/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
