import * as React from 'react';
import ChartFunnel, {
  Title,
  Margin,
  Export,
  Tooltip,
  Item,
  IFunnelOptions,
} from 'devextreme-react/funnel';
import type { Option } from 'ebs-design/dist/components/molecules/Select/interfaces';

import { getColor } from './utils';

interface FunnelOption extends Option {
  color?: string;
}

interface Props extends IFunnelOptions {
  title?: {
    text: string;
    margin?: { bottom?: number; top?: number; left?: number; right?: number };
  };
  dataSource?: FunnelOption[];
  canExport?: boolean;
  hasTooltip?: boolean;
}

export const Funnel: React.FC<Props> = ({
  title,
  dataSource = [],
  canExport,
  hasTooltip = true,
  label,
  ...props
}) => {
  return (
    <ChartFunnel
      dataSource={dataSource.map(({ text, value, color }, i) => ({
        value,
        text,
        color: color || getColor(i),
      }))}
      argumentField="text"
      valueField="value"
      colorField="color"
      palette="Ocean"
      label={{ ...label, visible: label?.visible || false }}
      {...props}
    >
      {title && (
        <Title text={title.text}>
          {title.margin && (
            <Margin
              bottom={title.margin.bottom}
              top={title.margin.top}
              left={title.margin.left}
              right={title.margin.right}
            />
          )}
        </Title>
      )}

      {canExport && <Export enabled={canExport} />}

      <Item />

      {hasTooltip && <Tooltip enabled={hasTooltip} format="fixedPoint" />}
    </ChartFunnel>
  );
};
