import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import useSetState from 'react-use/esm/useSetState';
import {
  Space,
  Card,
  useForm,
  Loader,
  Actions,
  Table,
  useNotify,
  Checkbox,
  Button,
  Modal,
} from 'ebs-design';
import { AssignTo, MoveTo } from 'features/contacts/Actions';
import { MultipleCheckBoxSelect } from 'components/molecules';
import { Pagination } from 'components/organisms';
import { useSearchAPI, useLeadsAPI } from 'api';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Results, Lead, Properties, SearchLead } from 'types';
import { defaultFilters } from 'utils';

import { useSearchColumns, ColumnType } from '../useSearchColumns';
import { Filters, customFilters } from '../Filters';

export const GlobalSearch: React.FC = () => {
  const { t } = useTranslation();
  const { search, ...params } = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { push } = useHistory();
  const [form] = useForm();

  const notify = useNotify();
  const queryClient = useQueryClient();

  const { columns: searchColumns, setColumns } = useSearchColumns();

  const { getSearch } = useSearchAPI();
  const { deleteLeads } = useLeadsAPI();

  const [filters, setFilters] = useSetState<Properties>({
    ...defaultFilters,
    ...customFilters,
    search,
    ...params,
    branch: params?.branch && parseInt(params?.branch),
    owner: params?.owner && parseInt(params?.owner),
    activity_domain: params?.activity_domain && parseInt(params?.activity_domain),
    address_city: params?.address_city && parseInt(params?.address_city),
    address_region: params?.address_region && parseInt(params?.address_region),
    address_country: params?.address_country && parseInt(params?.address_country),
    source: params?.source && parseInt(params?.source),
  });

  React.useEffect(() => {
    const { page, per_page } = params;

    if ((page && page !== filters.page) || (per_page && per_page !== filters.per_page)) {
      setFilters({
        ...(page && { page }),
        ...(per_page && { per_page }),
      });
    }
  }, [params, filters]);

  const [enabled, setEnabled] = React.useState(false);

  const [checkedLeads, setCheckedLeads] = React.useState<number[]>([]);
  const [assignTo, setAssignTo] = React.useState<number[]>();
  const [moveTo, setMoveTo] = React.useState<number[]>();
  const [deleteItems, setDeleteItems] = React.useState<number[]>([]);

  const { data, isLoading } = useQuery<Results<Lead>>(
    ['global-search', { ...filters, ...params }],
    getSearch,
    {
      enabled,
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const deleteLeadsMutation = useMutation(deleteLeads, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['global-search']);
      setDeleteItems([]);
    },
  });

  const onCheckAll = (): void => {
    if (data?.results.length !== checkedLeads.length) {
      setCheckedLeads(data?.results.map((item) => item.id) || []);
    } else {
      setCheckedLeads([]);
    }
  };

  const onCheckLead = (type: boolean, id: number): void => {
    if (type) {
      setCheckedLeads((prevCheckedLeads) => [...prevCheckedLeads, id]);
    } else {
      setCheckedLeads((prevCheckedLeads) => prevCheckedLeads.filter((item) => item !== id));
    }
  };

  const handleCloseAssignTo = (): void => {
    setAssignTo(undefined);
  };
  const handleCloseMoveTo = (): void => {
    setMoveTo(undefined);
  };

  const handleDeleteItems = (): void => {
    deleteLeadsMutation.mutate({ leads: deleteItems } as any);
  };

  const handleCancelDelete = (): void => {
    setDeleteItems([]);
  };

  const columns: ColumnType<SearchLead>[] = React.useMemo(
    () => [
      {
        title: (
          <Checkbox onChange={onCheckAll} checked={data?.results.length === checkedLeads.length} />
        ),
        render: ({ id }): React.ReactNode => (
          <Checkbox
            onChange={(type) => onCheckLead(type, id)}
            checked={checkedLeads.find((item) => item === id) ? true : false}
          />
        ),
      },
      ...searchColumns,
      {
        align: 'right',
        title: !!checkedLeads.length && (
          <Actions showTitle={false}>
            <div className="table-actions">
              <div className="actions-title">{t('contacts.action')}</div>
              <Actions.Item onClick={() => setAssignTo(checkedLeads)}>
                {t('contacts.reAssignResponsible')}
              </Actions.Item>
              <Actions.Item onClick={() => setMoveTo(checkedLeads)}>
                {t('contacts.moveTo')}
              </Actions.Item>
              <Actions.Item onClick={() => setDeleteItems(checkedLeads)}>
                {t('contacts.deleteRecord')}
              </Actions.Item>
            </div>
          </Actions>
        ),
        render: ({ id }): React.ReactNode => (
          <Actions showTitle={false}>
            <div className="table-actions">
              <div className="actions-title">{t('contacts.action')}</div>
              <Link to={`/lead/${id}`}>
                <Actions.Item onClick={() => setAssignTo(checkedLeads)}>
                  {t('contacts.viewLead')}
                </Actions.Item>
              </Link>
              <Actions.Item onClick={() => setAssignTo([id])}>
                {t('contacts.reAssignResponsible')}
              </Actions.Item>
              <Actions.Item onClick={() => setMoveTo([id])}>{t('contacts.moveTo')}</Actions.Item>
              <Actions.Item onClick={() => setDeleteItems([id])}>
                {t('contacts.deleteRecord')}
              </Actions.Item>
            </div>
          </Actions>
        ),
      },
    ],
    [t, searchColumns, data, checkedLeads],
  );

  const handleColumnSelect = (columns): void => {
    setColumns(columns);
  };

  const handleSubmit = (values): void => {
    const params = { ...values, page: 1 };

    updateQuery(params);
    setFilters(params);
    setEnabled(true);
  };

  const onRowClick = (record): void => {
    if (record.available_for_view) push(`/lead/${record.id}`);
  };

  return (
    <>
      {assignTo && (
        <AssignTo queryKey={['global-search']} leads={assignTo} onClose={handleCloseAssignTo} />
      )}

      {moveTo && <MoveTo queryKey={['global-search']} leads={moveTo} onClose={handleCloseMoveTo} />}

      {!!deleteItems?.length && (
        <Modal
          closeOnClickOutside={false}
          open
          header={<h3>{t('confirm.areYouSure')}</h3>}
          onClose={handleCancelDelete}
        >
          <Modal.Content>{t('confirm.areYouSureDescription')}</Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={handleCancelDelete}>{t('buttons.cancel')}</Button>
              <Button type="primary" onClick={handleDeleteItems}>
                {t('buttons.ok')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
      <Filters form={form} data={filters} filters={filters} onSearch={handleSubmit} />
      <Card className="mt-20">
        <Card.Header>
          <Space justify="space-between">
            <>
              {t('main.searchResults')}: {data?.total || 0}
            </>
            <MultipleCheckBoxSelect
              placeHolder={t('filter.chooseColumn')}
              callback={handleColumnSelect}
              options={columns}
            />
          </Space>
        </Card.Header>
        <Card.Body className="p-0">
          <Loader loading={isLoading} size="regular">
            <Table
              data={data?.results}
              columns={columns.filter((i) => i.checked)}
              className="contacts-table no-border"
              onRow={(record, index) => ({
                className: record.available_for_view ? 'table-cell--clickable' : 'table-cell',
                onClick: onRowClick.bind(null, record, index),
              })}
            />
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Pagination size={data?.total_results || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
