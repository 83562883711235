import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Loader, Table, useNotify } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { useReportsAPI } from 'api';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { Column, SalesFunnelReport } from 'types';

interface SalesFunnelTableProps {
  id?: number;
}

export const SalesFunnelTable: React.FC<SalesFunnelTableProps> = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const params = useQueryParams();
  const { getSalesFunnel } = useReportsAPI();

  const { data, isLoading } = useQuery<SalesFunnelReport>(
    [
      'sales-funnel-report',
      {
        ...params,
        date: undefined,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
      },
    ],
    getSalesFunnel,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const columns: Column<{
    label: string;
    conversion_leads: number;
    leads_count: number;
  }>[] = React.useMemo(
    () => [
      {
        title: t('salesFunnelReport.leadSource'),
        render: ({ label }): React.ReactNode => (
          <div className="balls-palette">{t(`salesFunnelReport.${label}`)}</div>
        ),
      },
      {
        title: t('salesFunnelReport.leadNr'),
        dataIndex: 'leads_count',
      },
      {
        title: t('salesFunnelReport.conversionRate'),
        render: ({ conversion_leads }) => `${conversion_leads} %`,
      },
    ],
    [t],
  );

  const tableData = React.useMemo(() => {
    return (
      data &&
      Object.keys(data).map((item) => ({
        label: item,
        conversion_leads: data[item].conversion_leads,
        leads_count: data[item].leads_count,
      }))
    );
  }, [data]);

  return (
    <div className="sales-funnel-table">
      <Loader loading={isLoading} size="regular">
        {data && (
          <Table
            emptyText={t('main.noData')}
            data={tableData}
            columns={columns}
            className="sales-funnel-table__table"
          />
        )}
      </Loader>
    </div>
  );
};
