import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Row,
  Space,
  Textarea,
  Alert,
  useForm,
} from 'ebs-design';
import { SessionContext } from 'contexts/SessionContext';
import { SmartSelect } from 'components/organisms';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  useLeadsAPI,
  useBranchesAPI,
  useSourcesAPI,
  useCountriesAPI,
  useCitiesAPI,
  useIncomesAPI,
  useActivityDomainsAPI,
  useUsersAPI,
  useRegionsAPI,
} from 'api';
import { ValidationError } from 'errors';
import { listTransformResults, userTransformResults } from 'utils';
import { LeadStatus } from 'types';

export const ContactCreate: React.FC = () => {
  const { t } = useTranslation();
  const { back } = useQueryParams();
  const { session } = React.useContext(SessionContext);
  const history = useHistory();

  const [form] = useForm();
  const [branchId, setBranchId] = React.useState<number | undefined>();
  const [regionId, setRegionId] = React.useState<number | undefined>();
  const [isDuplicate, setAsDuplicate] = React.useState<boolean>(false);
  const [isMarkedAsDuplicate, markAsDuplicate] = React.useState<boolean>(false);

  const { postLead } = useLeadsAPI();
  const { getBranches } = useBranchesAPI();
  const { getSources } = useSourcesAPI();
  const { getCountries } = useCountriesAPI();
  const { getCities } = useCitiesAPI();
  const { getIncomes } = useIncomesAPI();
  const { getActivityDomainList } = useActivityDomainsAPI();
  const { getUsers } = useUsersAPI();
  const { getRegionsList } = useRegionsAPI();

  const createContactMutation = useMutation(postLead, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);

        const alreadyExistsError = e.fields.filter(
          ({ name, errors }) =>
            (name.includes('phone_number') || name.includes('email')) &&
            errors.map((message) => message.includes('already exists')).includes(true),
        );
        setAsDuplicate(alreadyExistsError.length > 0);
      }
    },
    onSuccess: () => {
      history.push(back ? back : '/contacts');
    },
  });

  const onSubmitForm = async (): Promise<void> => {
    createContactMutation.mutate(form.getFieldsValue());
  };

  const onResetForm = (): void => form.resetFields();

  const onValuesChange = React.useCallback((values) => {
    if (values.branch) {
      setBranchId(values.branch);
    }
    if (values.address_region) {
      setRegionId(values.address_region);
    }
    markAsDuplicate(!!values.is_duplicate);
  }, []);

  const onLoadedResponsiblePerson = React.useCallback(
    (data) => {
      if (data?.results?.length) {
        form.setFieldsValue({ owner: data.results[0]?.id });
      }
    },
    [form],
  );

  return (
    <div className="contact-create">
      <Card>
        <Card.Body>
          <Form
            form={form}
            id="create-contact"
            onValuesChange={onValuesChange}
            onFinish={onSubmitForm}
            className="contact-create_form"
          >
            <h3>{t('contacts.contact')}</h3>

            <Row className="mt-20">
              <Col>
                <Form.Field name={['contact', 'first_name']} label={t('contacts.firstName')}>
                  <Input placeholder={t('contacts.firstName')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name={['contact', 'last_name']} label={t('contacts.lastName')}>
                  <Input placeholder={t('contacts.lastName')} />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col size={6}>
                <Form.Field name={['contact', 'email']} label="Email">
                  <Input type="email" placeholder="Email" />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field name={['contact', 'landline_number']} label={t('contacts.landline')}>
                  <Input placeholder={t('contacts.landline')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name={['contact', 'phone_number']} label={t('contacts.phoneMobile')}>
                  <Input placeholder={t('contacts.phoneMobile')} />
                </Form.Field>
              </Col>
            </Row>

            {(isDuplicate || isMarkedAsDuplicate) && (
              <Row className="mt-20">
                <Col>
                  <Alert icon message={t('contacts.duplicateInfo')} outlined type="warning">
                    <Form.Field name="is_duplicate" className="duplicate-radio">
                      <Radio
                        className="flex-space-between"
                        options={[{ value: 'true', text: t('contacts.duplicateConfirm') }]}
                      />
                    </Form.Field>
                  </Alert>
                </Col>
              </Row>
            )}

            <Row className="mt-20">
              <Col>
                <Form.Field name={['contact', 'job_title']} label={t('contacts.function')}>
                  <Input placeholder={t('contacts.function')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name={['contact', 'social_url']} label={t('contacts.linkSocialMedia')}>
                  <Input placeholder={t('contacts.linkSocialMedia')} />
                </Form.Field>
              </Col>
            </Row>

            <hr />

            <h3>{t('contacts.lead')}</h3>

            <Row className="mt-20">
              <Col>
                <Form.Field name="name" label={t('contacts.nameLead')}>
                  <Input placeholder={t('contacts.nameLead')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="activity_domain" label={t('contacts.activityDomain')}>
                  <SmartSelect
                    search
                    queryKey="activity-domain"
                    api={getActivityDomainList}
                    placeholder={t('contacts.activityDomain')}
                    transform={listTransformResults}
                  />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field name="registration_number" label={t('contacts.registrationNumber')}>
                  <Input placeholder={t('contacts.registrationNumber')} />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="website" label="Website">
                  <Input placeholder="http://" />
                </Form.Field>
              </Col>
            </Row>

            <hr />

            <h3>{t('contacts.address')}</h3>

            <Row className="mt-20">
              <Col>
                <Form.Field name="address_country" label={t('contacts.country')}>
                  <SmartSelect
                    search
                    queryKey="countries"
                    api={getCountries}
                    placeholder={t('contacts.country')}
                  />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="address_region" label={t('contacts.region')}>
                  <SmartSelect
                    search
                    queryKey="regions"
                    api={getRegionsList}
                    placeholder={t('contacts.region')}
                    transform={listTransformResults}
                  />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field name="address_city" label={t('contacts.city')}>
                  <SmartSelect
                    search
                    queryKey="cities"
                    api={getCities}
                    placeholder={t('contacts.city')}
                    filters={regionId ? { region: regionId } : undefined}
                  />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="address_street" label={t('contacts.address')}>
                  <Input placeholder={t('main.text')} />
                </Form.Field>
              </Col>
            </Row>

            <hr />

            <h3>{t('contacts.contactDetails')}</h3>

            <Row className="mt-20">
              <Col size={6}>
                <Form.Field
                  name="type"
                  label={t('contacts.leadType')}
                  className="contact-type-radio"
                >
                  <Radio
                    options={[
                      { text: t('contacts.individual'), value: 'individual' },
                      { text: t('contacts.company'), value: 'company' },
                    ]}
                  />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field name="source" label={t('contacts.source')}>
                  <SmartSelect
                    search
                    queryKey="source"
                    api={getSources}
                    placeholder={t('contacts.source')}
                  />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="income_source" label={t('contacts.income')}>
                  <SmartSelect
                    search
                    queryKey="income"
                    api={getIncomes}
                    placeholder={t('contacts.income')}
                  />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field
                  name="branch"
                  initialValue={session?.data?.user?.teams[0]?.id}
                  label={t('contacts.branch')}
                >
                  <SmartSelect
                    search
                    queryKey="branch"
                    api={getBranches}
                    placeholder={t('contacts.branch')}
                    transform={listTransformResults}
                  />
                </Form.Field>
              </Col>

              <Col>
                <Form.Field name="owner" label={t('contacts.responsiblePerson')}>
                  <SmartSelect
                    search
                    queryKey="branch-users"
                    filters={branchId ? { branch: branchId } : undefined}
                    api={getUsers}
                    placeholder={t('contacts.responsiblePerson')}
                    transform={userTransformResults}
                    onLoaded={onLoadedResponsiblePerson}
                  />
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col size={6}>
                <Form.Field name="status" label={t('contacts.status')}>
                  <Select>
                    <Select.Options>
                      {Object.values(LeadStatus).map((item, key) => (
                        <Select.Options.Item value={item} key={`status-${key}`}>
                          {t(`statuses.${item}`)}
                        </Select.Options.Item>
                      ))}
                    </Select.Options>
                  </Select>
                </Form.Field>
              </Col>
            </Row>

            <Row className="mt-20">
              <Col>
                <Form.Field
                  name="comment"
                  label={t('contacts.comment')}
                  className="textarea-static-width"
                >
                  <Textarea placeholder={t('contacts.description')} />
                </Form.Field>
              </Col>
            </Row>
          </Form>
        </Card.Body>

        <Card.Footer>
          <Space align="end" justify="end">
            <Button onClick={onResetForm}>{t('buttons.cancel')}</Button>
            <Button type="primary" submit form="create-contact">
              {t('buttons.save')}
            </Button>
          </Space>
        </Card.Footer>
      </Card>
    </div>
  );
};
