import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Loader, Table, useNotify } from 'ebs-design';
import { Pagination } from 'components/organisms';
import { useQueryParams } from 'hooks/useQueryParams';
import { useLeadColumns } from 'hooks/useLeadColumns';
import { SalesFunnelFilter } from '../Filters/SalesFunnelFilter';
import { defaultFilters } from 'utils';
import { useLeadsAPI } from 'api';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { Results, LeadSalesFunnel, Properties } from 'types';

export const SalesFunnel: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const history = useHistory();
  const { columns, setColumns } = useLeadColumns();

  const { getLeadsSalesFunnel } = useLeadsAPI();
  const { data: leads, isLoading } = useQuery<Results<LeadSalesFunnel>>(
    [
      'sales-funnel',
      {
        ...defaultFilters,
        ...params,
        created_at_from:
          params.created_at_from && format(params.created_at_from, dateFormatAPI, dateFormat),
        created_at_to:
          params.created_at_to && format(params.created_at_to, dateFormatAPI, dateFormat),
        created_at: undefined,
        opportunity__close_date_from:
          params.opportunity__close_date_from &&
          format(params.opportunity__close_date_from, dateFormatAPI, dateFormat),
        opportunity__close_date_to:
          params.opportunity__close_date_to &&
          format(params.opportunity__close_date_to, dateFormatAPI, dateFormat),
        opportunity__close_date: undefined,
        columns: undefined,
      },
    ],
    getLeadsSalesFunnel,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const onRow = (lead): Properties => {
    return {
      onClick: () => {
        history.push(`/lead/${lead.id}`);
      },
    };
  };

  return (
    <div>
      <SalesFunnelFilter count={leads?.count} columns={columns} setColumns={setColumns} />
      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            onRow={onRow}
            data={leads?.results}
            columns={columns.filter((i) => i.checked)}
            className="table-cell--clickable no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={leads?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </div>
  );
};
