import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useDocumentsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadDocuments: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads/${id}/documents`, getRequest);
    },
    getDocuments: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/documents`, query), getRequest);
    },
    getDocumentById: ({ queryKey: [, { id }] }) => {
      return fetcher(`/documents/${id}`, getRequest);
    },
    postDocumentBulk: (data) => {
      return fetcher('/documents/bulk-create', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    postDocument: (data) => {
      return fetcher('/documents', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    patchDocument: ({ id, ...data }) => {
      return fetcher(`/documents/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    putDocument: ({ id, ...data }) => {
      return fetcher(`/documents/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteDocument: (id: number) => {
      return fetcher(`/documents/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
