import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Upload, Button, Radio, Form, useForm } from 'ebs-design';
import { useLeadsImportAPI } from 'api';
import { Plus } from 'resources/icons';
import { useUpload } from 'hooks/useUpload';
import { ValidationError } from 'errors';
import { CreateTypeLeadImport, ImportProgress, ImportStep, UploadedFile } from 'types';

const step = 'first';

const acceptTypes = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const UploadFile: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const { customRequest } = useUpload();
  const [file, setFile] = React.useState<UploadedFile>();
  const [form] = useForm();
  const { postStep, patchStep } = useLeadsImportAPI();
  const { getImportById } = useLeadsImportAPI();

  React.useEffect(() => {
    form.setFieldsValue({ create_type: CreateTypeLeadImport.CREATE_RECORDS });
  }, []);

  const { data: leadImport } = useQuery<ImportProgress>(['import', { id }], getImportById, {
    enabled: id !== 'add',
  });

  React.useEffect(() => {
    if (leadImport) {
      const { file, ...rest } = leadImport;
      form.setFieldsValue({ file: [{ name: file, url: file, rest }] });
    }
  }, [leadImport]);

  const mutation = useMutation(id === 'add' ? postStep : patchStep, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: (response: ImportProgress) => {
      history.push(`/contacts/import/${response.id}/${ImportStep.CONFIRM_IMPORTED_FILE}`);
    },
  });

  const handleCustomRequest = React.useCallback(async (options) => {
    const data = await customRequest(options);
    setFile(data);
  }, []);

  const handleSubmit = (): void => {
    mutation.mutate({
      ...form.getFieldsValue(),
      file: file?.url || leadImport?.file,
      id: id === 'add' ? undefined : id,
    });
  };

  const toAcceptBeforeUpload = (file): boolean => {
    if (acceptTypes.includes(file.type)) {
      return true;
    }
    form.setFields([{ name: 'file', errors: [t('import.fileType')] }]);
    return false;
  };

  return (
    <div className="import-file-upload">
      <Card.Header className="bold">{t('import.uploadFirstStepDescription')}</Card.Header>
      <Card.Body>
        <Form id={`form-${step}`} form={form} onFinish={handleSubmit}>
          <div className="label-form">{t('import.fileType')}</div>
          <div className="file-uploader">
            <Form.Field name="file">
              <Upload
                beforeUpload={toAcceptBeforeUpload}
                customRequest={handleCustomRequest}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                <Button type="primary" prefix={<Plus />} className="select-file">
                  {t('import.selectFile')}
                </Button>
              </Upload>
            </Form.Field>
          </div>
          <Form.Field name="create_type" label={t('import.dataProcessType')}>
            <Radio
              options={[
                { value: CreateTypeLeadImport.CREATE_RECORDS, text: t('import.createNew') },
                {
                  value: CreateTypeLeadImport.CREATE_UPDATE_RECORDS,
                  text: t('import.createNewAndUpdate'),
                },
              ]}
            />
          </Form.Field>
        </Form>
      </Card.Body>
    </div>
  );
};
