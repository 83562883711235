import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, Space, Loader, Button } from 'ebs-design';
import { useDashboardAPI } from 'api';
import { Column, DashboardProps, Properties, LastOpportunityReport } from 'types';

export const RecentLeads: React.FC<DashboardProps> = ({ ...params }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { getLatestOpportunities } = useDashboardAPI();

  const { data = [], isLoading } = useQuery<LastOpportunityReport[]>(
    ['recent-leads', params],
    getLatestOpportunities,
  );

  const columns: Column<LastOpportunityReport>[] = React.useMemo(
    () => [
      {
        title: t('nomenclature.name'),
        dataIndex: ['lead', 'name'],
      },
      {
        title: t('lead.status'),
        render: ({ status }): React.ReactNode => t(`opportunities.${status}`),
      },
      {
        title: t('lead.value'),
        render: ({ value, currency }): React.ReactNode => `${value || 0} ${currency?.name || ''}`,
      },
    ],
    [t],
  );

  const onRow = (data): Properties => {
    return {
      onClick: () => {
        history.push(`/lead/${data.lead.id}`);
      },
    };
  };

  return (
    <Card>
      <Card.Header>{t('reports.recentLeads')}</Card.Header>
      <Card.Body className="p-0">
        <Loader loading={isLoading}>
          <Table
            emptyText={t('main.noData')}
            onRow={onRow}
            columns={columns}
            data={data}
            className="sales-funnel-table__table table-cell--clickable no-border"
          />
        </Loader>
      </Card.Body>
      <Card.Footer>
        <Space justify="space-between">
          <Space size="small">
            <>{t('reports.total')}:</>

            {!isLoading ? data.length : <Loader.Inline />}
          </Space>

          <Link to="/sales-funnel">
            <Button type="primary">{t('reports.viewAll')}</Button>
          </Link>
        </Space>
      </Card.Footer>
    </Card>
  );
};
