import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Icon, Loader, Select, Space } from 'ebs-design';
import { Filters } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { TaskList, UserAlone } from 'resources/icons';
import { useBranchesAPI, useSourcesAPI, useUsersAPI } from 'api';
import { defaultFilters, defaultNonPagination } from 'utils';
import { List, Results, Sources, User } from 'types';

interface FiltersProps {
  owner?: string;
  branch?: string;
  lead_source?: string;
  date?: string[];
  date_from?: string;
  date_to?: string;
}

export const ReportSalesFunnelFilter: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getUsersMarketingReportList, getUsers } = useUsersAPI();
  const { getBranchesMarketingReportList, getBranches } = useBranchesAPI();
  const { getSources } = useSourcesAPI();
  const [branchFilter, setBranchFilter] = useSetState(defaultNonPagination);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [sourcesFilter, setSourcesFilter] = useSetState(defaultNonPagination);
  const [filters, setFilters] = useSetState<FiltersProps>(params);

  const isMarketingRole = useRolePermission([UserRoleCRM.marketing]);

  const { data: branches, isLoading: isLoadingBranches } = useQuery<Results<List>>(
    ['branches', { ...defaultFilters, ...branchFilter }],
    isMarketingRole ? getBranchesMarketingReportList : getBranches,
  );

  const { data: users, isLoading: isLoadingUsers } = useQuery<Results<User>>(
    ['users', { ...defaultFilters, ...usersFilter }],
    isMarketingRole ? getUsersMarketingReportList : getUsers,
  );

  const { data: leadSource } = useQuery<Results<Sources>>(
    ['lead_source', { ...sourcesFilter }],
    getSources,
  );

  React.useEffect(() => {
    const { date, ...rest } = filters;
    const [date_from, date_to] = date || [];
    updateQuery({
      ...rest,
      date,
      date_from,
      date_to,
    });
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    setFilters(value);
  };

  return (
    <Filters className="mb-0 sales-funnel-filter" onChange={handleFilters}>
      <Space justify="start">
        <h3 className="title">{t('salesFunnelReport.salesFunnel')}</h3>
      </Space>

      <Space justify="end">
        <Filters.Select
          prefix={<Icon component={UserAlone} />}
          placeholder={t('filter.agent')}
          value={params.owner}
          field="owner"
        >
          <Select.Options>
            {(users?.results || []).map((item, key) => (
              <Select.Options.Item key={`${key}-user`} value={item.id}>
                {item?.first_name || item?.last_name
                  ? `${item?.first_name} ${item?.last_name}`
                  : item.email}
              </Select.Options.Item>
            ))}
          </Select.Options>

          {isLoadingUsers && <Loader.Inline />}

          <Select.Pagination
            count={users?.count || 0}
            limit={usersFilter.per_page}
            page={usersFilter.page}
            setPage={(page) => setUsersFilter({ page })}
            mode="scroll"
          />
        </Filters.Select>

        <Filters.Select
          prefix={<Icon component={TaskList} />}
          placeholder={t('filter.branch')}
          value={params.branch && parseInt(params.branch)}
          field="branch"
        >
          <Select.Options>
            {(branches?.results || []).map((item, key) => (
              <Select.Options.Item key={`${key}-branches`} value={item.key}>
                {item.label}
              </Select.Options.Item>
            ))}
          </Select.Options>

          {isLoadingBranches && <Loader.Inline />}

          <Select.Pagination
            count={branches?.count || 0}
            limit={branchFilter.per_page}
            page={branchFilter.page}
            setPage={(page) => setBranchFilter({ page })}
            mode="scroll"
          />
        </Filters.Select>

        <Filters.Select
          prefix={<Icon component={TaskList} />}
          placeholder={t('filter.source')}
          value={params.lead_source && parseInt(params.lead_source)}
          field="lead_source"
        >
          <Select.Options>
            {(leadSource?.results || []).map((item, key) => (
              <Select.Options.Item key={`${key}-lead-source`} value={item.id}>
                {item.name}
              </Select.Options.Item>
            ))}
          </Select.Options>

          <Select.Pagination
            count={leadSource?.count || 0}
            limit={sourcesFilter.per_page}
            page={sourcesFilter.page}
            setPage={(page) => setSourcesFilter({ page })}
            mode="scroll"
          />
        </Filters.Select>

        <Filters.DatePickerFilter
          type="range"
          value={params.date}
          placeholder={t('filter.date')}
          field="date"
        />
      </Space>
    </Filters>
  );
};
