import { User } from './user';
import { Contact } from './contacts';
import { Nomenclatures } from './nomenclatures';
import { Currency } from './currency';
import { Document } from './documents';

export interface ShortOpportunity {
  id: number;
  currency: Currency;
  value: number;
}

export interface Opportunity extends ShortOpportunity {
  notes: string;
  close_date: string;
  created_at: string;
  status: OpportunityStatus;
  term: number;
  contact: Contact;
  product: Nomenclatures;
  lead: number;
  refusal_reason: number;
  owner: User;
  documents: Document[];
}

export enum OpportunityStatus {
  PROSPECTING = 'prospecting',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  SIGNED = 'signed',
  REQUEST_ON_APPROVE = 'request_on_approve',
  REQUEST_APPROVED = 'request_approved',
  REQUEST_DECLINED = 'request_declined',
  REQUEST_ANNULLED = 'request_annulled',
}

export const opportunityLabel = {
  prespecting: 'warning',
  sent: 'success',
  accepted: 'success',
  declined: 'danger',
  signed: 'success',
  request_on_approve: 'info',
  request_approved: 'success',
  request_declined: 'danger',
  request_annulled: 'danger',
};
