import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Actions, Card, Table, useNotify, Loader, Space, Icon, Button } from 'ebs-design';
import { Pagination } from 'components/organisms';
import { ConfirmModal } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Plus } from 'resources/icons';
import { FormModal } from '../FormModal';
import { FormNomenclatures } from '../FormNomenclatures';
import { Results, Column, FilterType, Nomenclatures } from 'types';
import { useIncomesAPI } from 'api';
import { defaultFilters } from 'utils';

const formId = 'incomes';

export const NomenclaturesIncomingSource: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { updateQuery } = useQueryUpdate();
  const { getIncomes, createIncome, getIncomeById, updateIncome, deleteIncome } = useIncomesAPI();

  const [isCreate, setIsCreate] = React.useState<boolean>(false);
  const [editItem, setEditItem] = React.useState();
  const [deleteItem, setDeleteItem] = React.useState();

  const { data: incomes, isLoading } = useQuery<Results<Nomenclatures>>(
    ['incomes', { ...defaultFilters, ...params }],
    getIncomes,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const deleteIncomesMutation = useMutation(deleteIncome, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['incomes']);
      setDeleteItem(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const handleDelete = (): void => {
    if (deleteItem) {
      deleteIncomesMutation.mutate(deleteItem);
    }
  };

  const handleCancelDelete = (): void => {
    setDeleteItem(undefined);
  };

  const handleCloseModal = (): void => {
    setEditItem(undefined);
    setIsCreate(false);
  };

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<Nomenclatures>[] = React.useMemo(
    () => [
      {
        title: t('nomenclature.incomes'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name }): React.ReactNode => name,
      },
      {
        align: 'right',
        render: ({ id }) => (
          <Space justify="end">
            <Actions placement="bottom">
              <Actions.Item onClick={() => setEditItem(id)}>{t('nomenclature.edit')}</Actions.Item>
              <Actions.Item onClick={() => setDeleteItem(id)}>
                {t('nomenclature.delete')}
              </Actions.Item>
            </Actions>
          </Space>
        ),
      },
    ],
    [t, incomes, params],
  );

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">{`${t(`nomenclature.incomes`)}${
            incomes?.count ? ` (${incomes.count})` : ``
          }`}</h3>
        </Space>
        <Space>
          <Button
            type="primary"
            prefix={<Icon component={Plus} />}
            onClick={() => setIsCreate(true)}
          >
            {t('buttons.addNew')}
          </Button>
        </Space>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            data={incomes?.results}
            columns={columns}
            className="no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={incomes?.total_pages || 0} />
        </Card.Footer>
      </Card>

      {!!deleteItem && <ConfirmModal onSuccess={handleDelete} onCancel={handleCancelDelete} />}

      {(editItem || isCreate) && (
        <FormModal formId={formId} onCancel={handleCloseModal} isCreate={isCreate}>
          <FormNomenclatures
            formId={formId}
            getItem={getIncomeById}
            onFinish={isCreate ? createIncome : updateIncome}
            onClose={handleCloseModal}
            id={editItem}
          />
        </FormModal>
      )}
    </>
  );
};
