import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useVisitsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getVisits: ({ queryKey: [, query] }) => fetcher(stringifyUrl(`/visits`, query), getRequest),
    getVisitById: ({ queryKey: [, { id }] }) => fetcher(`/visits/${id}`, getRequest),

    postVisit: (data) =>
      fetcher('/visits', {
        data,
        request: {
          method: 'POST',
        },
      }),
    patchVisit: ({ id, ...data }) =>
      fetcher(`/visits/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      }),

    putVisit: ({ id, ...data }) =>
      fetcher(`/visits/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      }),

    deleteVisit: (id: number) =>
      fetcher(`/visits/${id}`, {
        request: {
          method: 'DELETE',
        },
      }),
  };
};
