import * as React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Card, Icon, Label, Loader, Table, useNotify } from 'ebs-design';
import { Pagination, AudioPlayer } from 'components/organisms';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { CallsFilter } from 'features/calls/Filters/CallsFilter';
import { Play } from 'resources/icons';
import { defaultFilters } from 'utils';
import { useCallsAPI } from 'api';
import { dateFormat, dateFormatAPI, dateTimeFormat, format } from 'libs';
import { Call, Column, FilterType, FullCall, Results, CallStatus, Properties } from 'types';

export const Calls: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const [callItem, setCallItem] = React.useState<Call>();
  const { getCalls } = useCallsAPI();
  const { updateQuery } = useQueryUpdate();
  const history = useHistory();

  const { data: calls, isLoading } = useQuery<Results<FullCall>>(
    [
      'calls',
      {
        ...defaultFilters,
        ...params,
        created_at_start:
          params.created_at_start && format(params.created_at_start, dateFormatAPI, dateFormat),
        created_at_end:
          params.created_at_end && format(params.created_at_end, dateFormatAPI, dateFormat),
        created_at: undefined,
      },
    ],
    getCalls,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const handleOpenPlayer = (call: Call): void => {
    if (call.file && call.id !== callItem?.id) {
      setCallItem(call);
    }
  };

  const onRow = ({ contact }): Properties => {
    return {
      onClick: () => {
        history.push(`/lead/${contact.lead}`);
      },
    };
  };

  const columns: Column<FullCall>[] = React.useMemo(
    () => [
      {
        title: t('calls.id'),
        onFilter: (type) => handleFilter(type, 'id'),
        dataIndex: 'id',
      },
      {
        title: t('calls.dateTime'),
        onFilter: (type) => handleFilter(type, 'created_at'),
        render: ({ created_at }) => format(created_at, dateTimeFormat),
      },
      {
        title: t('calls.callType'),
        onFilter: (type) => handleFilter(type, 'type'),
        render: ({ type }) => (
          <Label
            className="calls__type-label"
            status={CallStatus[type]}
            text={t(`calls.${type}`)}
          />
        ),
      },
      {
        title: t('calls.toNumber'),
        onFilter: (type) => handleFilter(type, 'to_phone_number'),
        dataIndex: 'to_phone_number',
      },
      {
        title: t('calls.duration'),
        onFilter: (type) => handleFilter(type, 'call_duration'),
        dataIndex: 'call_duration',
      },
      {
        title: t('calls.fullName'),
        onFilter: (type) => handleFilter(type, 'lead__name'),
        render: ({ contact }) =>
          contact ? [contact?.first_name, contact?.last_name].join(' ') : '---',
      },
      {
        title: t('calls.answered'),
        onFilter: (type) => handleFilter(type, 'answered'),
        render: ({ answered }) =>
          answered ? (
            <p className="calls__answered-true">{t('calls.answeredTrue')}</p>
          ) : (
            <p className="calls__answered-false">{t('calls.answeredFalse')}</p>
          ),
      },
      {
        title: t('calls.agent'),
        render: ({ agent }) => (agent ? [agent?.first_name, agent?.last_name].join(' ') : '---'),
      },
      {
        title: t('calls.action'),
        align: 'right',
        render: (item) => (
          <Button
            type="ghost"
            size="small"
            className={cn('calls__play-button', { active: callItem?.id === item.id })}
            disabled={!item.file}
            onClick={() => handleOpenPlayer(item)}
            prefix={<Icon className="activity-log-item__delete__icon" component={Play} />}
          />
        ),
      },
    ],
    [callItem, params, t],
  );

  return (
    <div className="calls">
      <CallsFilter count={calls?.count} />
      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            onRow={onRow}
            emptyText={t('main.noData')}
            data={calls?.results}
            columns={columns}
            className="no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={calls?.total_pages || 0} />
        </Card.Footer>
      </Card>
      <div className="player-wrapper">
        {callItem && <AudioPlayer record={callItem} onClose={() => setCallItem(undefined)} />}
      </div>
    </div>
  );
};
