import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import dayjs from 'dayjs';
import { Row, Col, Space, ButtonGroup, Button, DatePicker } from 'ebs-design';
import { format, dateFormatAPI, dateFormat, dateFormatInput } from 'libs';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Properties } from 'types';

import {
  WebsiteVisits,
  CreditApplications,
  TelephoneCalls,
  Disbursements,
  ShareSalesByMarketingChannel,
  ShareCallsByMarketingChannel,
  ShareLeadsByMarketingChannel,
  ShareRequestsByMarketingChannel,
} from './charts';

export const ReportDashboard: React.FC = () => {
  const params = useQueryParams();

  const { t } = useTranslation();
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState<Properties>({
    ...params,
    ...(!params.date_from && { days: params.days ? parseInt(params.days) : 7 }),
  });

  React.useEffect(() => updateQuery(filters), [filters]);

  const props = React.useMemo(
    () => ({
      ...((filters.days || filters.date_from) && {
        date_from: filters.days
          ? dayjs().subtract(filters.days, 'day').format(dateFormatAPI)
          : format(filters.date_from, dateFormatAPI, dateFormat),
      }),
      ...((filters.days || filters.date_to) && {
        date_to: filters.days
          ? format(new Date(), dateFormatAPI)
          : format(filters.date_to, dateFormatAPI, dateFormat),
      }),
      currentYear: !!filters.days,
    }),
    [filters],
  );

  return (
    <Row g={4}>
      <Col size={12}>
        <Space>
          {params.date_from !== undefined && (
            <DatePicker.Range
              dateFormat={dateFormatInput}
              value={[params.date_from, params.date_to]}
              onChange={(value) =>
                value &&
                (value[0] || value[1]) &&
                setFilters({ date_from: value[0], date_to: value[1] })
              }
              isClearable
            />
          )}

          <ButtonGroup>
            <Button
              type={params.date_from !== undefined ? 'primary' : 'ghost'}
              onClick={() =>
                setFilters({
                  date_from: null,
                  date_to: null,
                  days: undefined,
                })
              }
            >
              {t('filter.period')}
            </Button>
            <Button
              type={params.days === '7' ? 'primary' : 'ghost'}
              onClick={() => setFilters({ days: 7, date_from: undefined, date_to: undefined })}
            >
              7 {t('nomenclature.days')}
            </Button>
            <Button
              type={params.days === '14' ? 'primary' : 'ghost'}
              onClick={() => setFilters({ days: 14, date_from: undefined, date_to: undefined })}
            >
              14 {t('nomenclature.days')}
            </Button>
            <Button
              type={params.days === '30' ? 'primary' : 'ghost'}
              onClick={() => setFilters({ days: 30, date_from: undefined, date_to: undefined })}
            >
              {t('nomenclature.oneMonth')}
            </Button>
          </ButtonGroup>
        </Space>
      </Col>
      <Col size={6}>
        <WebsiteVisits {...props} />
      </Col>
      <Col size={6}>
        <CreditApplications {...props} />
      </Col>
      <Col size={6}>
        <TelephoneCalls {...props} />
      </Col>
      <Col size={6}>
        <Disbursements {...props} />
      </Col>
      <Col size={6}>
        <ShareSalesByMarketingChannel {...props} />
      </Col>
      <Col size={6}>
        <ShareCallsByMarketingChannel {...props} />
      </Col>
      <Col size={6}>
        <ShareRequestsByMarketingChannel {...props} />
      </Col>
      <Col size={6}>
        <ShareLeadsByMarketingChannel {...props} />
      </Col>
    </Row>
  );
};
