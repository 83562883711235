import { User } from './user';
import { ShortOpportunity } from './opportunity';
import { ShortLead } from './leads';

export interface Task {
  id: number;
  title: string;
  description: string;
  comment: string;
  deadline: string;
  repeated: number;
  completed_at: string;
  priority: string; //1 --> highest priority;  2 --> medium;  3 --> lowest;
  owner: User;
  opportunity: ShortOpportunity;
  lead: ShortLead;
  type: TaskType;
  created_by: User;
  created_at: string;
  modified_at: string;
}

export interface TaskType {
  id: number;
  name: string;
  code: Code;
  created_at: string;
  modified_at: string;
}

export enum Code {
  GENERIC = 'generic',
  MEETING = 'meeting',
  SEND_OFFER = 'send_offer',
  CALL_CLIENT = 'call_client',
  SING_OFFER = 'sign_offer',
  CREATE_REQUEST = 'create_request',
  SEND_REQUEST_TO_APPROVE = 'send_request_to_approve',
  SIGN_CONTRACT = 'sign_contract',
}

export enum TaskCallClient {
  WAITING = 'waiting',
  DECLINED = 'declined',
  ACCEPTED = 'accepted',
}

export enum OutstandingType {
  OUTSTANDING_DAYS_15 = 'outstanding_days_15',
  OUTSTANDING_DAYS_30 = 'outstanding_days_30',
  OUTSTANDING_DAYS_40 = 'outstanding_days_40',
  OUTSTANDING_DAYS_60 = 'outstanding_days_60',
}
