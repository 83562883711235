import * as React from 'react';
import { Log } from 'types';
import { useHistory } from 'react-router-dom';
import { AvatarInline, Button, Icon, Space } from 'ebs-design';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { Email, Trash } from 'resources/icons';
import { dateTimeFormat, format } from 'libs';

interface ActivityLogEmailProps {
  log: Log;
  onDelete: (id) => void;
}

export const ActivityLogEmail: React.FC<ActivityLogEmailProps> = ({ log, onDelete }) => {
  const history = useHistory();

  const [showMore, setShowMore] = React.useState<boolean>(false);

  const onShowDescription = (): void => {
    setShowMore((prevState) => !prevState);
  };

  const onRedirectMessage = (): void => {
    if (log && log.lead && log.lead.id) {
      history.push(`/lead/${log.lead.id}`);
    }
  };

  return (
    <Space justify="space-between">
      <div className="activity-log-item__content">
        {showMore ? (
          log.full_description ? (
            <span dangerouslySetInnerHTML={{ __html: log.full_description }} />
          ) : (
            <span>{log.description}</span>
          )
        ) : (
          <span>{log.description}</span>
        )}
      </div>
      <Space className="activity-log-item__right-side">
        <div className="redirect-mail-message" onClick={() => onRedirectMessage()}>
          <Icon component={Email} />
        </div>

        <div className="show-more-button" onClick={() => onShowDescription()}>
          <b>. . .</b>
        </div>
        <span className="activity-log-item__created-at">
          {format(log.created_at, dateTimeFormat)}
        </span>

        {log.created_by && (
          <AvatarInline
            type="dynamic"
            shortAlt={[
              (log.created_by?.first_name || '')[0],
              (log.created_by?.last_name || '')[0],
            ].join('')}
            alt={[log.created_by?.first_name, log.created_by?.last_name].join(' ') || '---'}
            circle
          />
        )}

        {useRolePermission([UserRoleCRM.admin]) && (
          <Button
            type="ghost"
            size="small"
            className="activity-log-item__delete"
            onClick={() => onDelete(log.id)}
            prefix={<Icon className="activity-log-item__delete__icon" component={Trash} />}
          />
        )}
      </Space>
    </Space>
  );
};
