import * as React from 'react';
import { Space } from 'ebs-design';

interface Props {
  title: string;
  content: React.ReactNode;
}

export const Tooltip: React.FC<Props> = ({ title, content }) => (
  <div className="ebs-tooltip__wrapper ebs-tooltip--bottom">
    <div className="ebs-tooltip__body chart-tooltip">
      <Space direction="vertical" justify="center" size="small">
        <div className="ebs-tooltip__body-title">{title}</div>
        {content}
      </Space>
    </div>
  </div>
);
