export type Properties = Record<string, any>;

export interface Tokens {
  access_token: string;
  refresh_token: string;
}

export enum OrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
