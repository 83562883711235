import * as React from 'react';
import cn from 'classnames';
import { Button, ButtonGroup, Space } from 'ebs-design';
import { Pen, Download, Trash, Viber } from 'resources/icons';
import { Document, Lead } from 'types';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useExpertPermission } from 'hooks/useExpertPermission';

interface DocumentItemProps {
  document: Document;
  className?: string;
  lead?: Lead;
  onDelete: (id) => void;
  onEdit: (id) => void;
  onSend?: (id) => void;
}

export const DocumentItem: React.FC<DocumentItemProps> = ({
  document,
  className,
  lead,
  onEdit,
  onDelete,
  onSend,
}) => {
  return (
    <div className={cn('document-item', className)} onDoubleClick={() => onEdit(document.id)}>
      <Space justify="space-between">
        <div className="document-item__description">
          <span className="document-item__name">{document.name || '---'}</span>
        </div>
        {(useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
          useExpertPermission({ ownerId: lead?.owner?.id })) && (
          <ButtonGroup className="document-item__button-group">
            <Button
              className="document-item__button-group__button"
              onClick={() => onEdit(document.id)}
              prefix={<Pen />}
            />
            <a href={document.file} download={document.name} target="_blank" rel="noreferrer">
              <Button className="document-item__button-group__button" prefix={<Download />} />
            </a>
            {onSend && (
              <Button
                className="document-item__button-group__button"
                onClick={() => onSend(document.id)}
                prefix={<Viber />}
              />
            )}
            <Button
              className="document-item__button-group__button"
              onClick={() => onDelete(document.id)}
              prefix={<Trash />}
            />
          </ButtonGroup>
        )}
      </Space>
    </div>
  );
};
