import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Loader, useNotify } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { KanbanFilter } from '../KanbanFilter';
import { KanbanColumn } from '../KanbanColumn';
import { useLeadsAPI } from 'api';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { LeadSalesKanbanResponse } from 'types';

export const SalesFunnelKanban: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getLeadsPipeline } = useLeadsAPI();
  const { data: kanbanView, isLoading } = useQuery<LeadSalesKanbanResponse[]>(
    [
      'pipeline',
      {
        ...params,
        created_at_from:
          params.created_at_from && format(params.created_at_from, dateFormatAPI, dateFormat),
        created_at_to:
          params.created_at_to && format(params.created_at_to, dateFormatAPI, dateFormat),
        created_at: undefined,
      },
    ],
    getLeadsPipeline,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  return (
    <div>
      <KanbanFilter />
      <Card className="kanban">
        <Loader loading={isLoading} size="regular">
          <div className="kanban-columns">
            {kanbanView?.map((column, key) => (
              <KanbanColumn column={column} key={key} />
            ))}
          </div>
        </Loader>
      </Card>
    </div>
  );
};
