import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Space } from 'ebs-design';
import { ArrowLeft, ArrowRight } from 'resources/icons';
import { useTranslation } from 'react-i18next';

interface ImportFormFooterProps {
  formId: string;
  backLink: string;
  disabledBackLink?: boolean;
  directionNav: boolean;
  importButton: boolean;
}

export const ImportFormFooter: React.FC<ImportFormFooterProps> = ({
  formId,
  backLink,
  disabledBackLink = false,
  directionNav,
  importButton,
}) => {
  const { t } = useTranslation();

  return (
    <Space justify="space-between">
      <Space>
        <Link to={'/contacts/import'}>
          <Button>{t('buttons.cancel')}</Button>
        </Link>
      </Space>
      <Space className="text-right">
        <Link to={backLink}>
          <Button type="primary" disabled={disabledBackLink}>
            <ArrowLeft className="arrow-position arrow-left" />
            {t('buttons.previous')}
          </Button>
        </Link>
        {directionNav && (
          <Button type="primary" submit form={formId}>
            {t('buttons.next')}
            <ArrowRight className="arrow-position arrow-right" />
          </Button>
        )}
        {importButton && (
          <Button type="primary" submit form={formId}>
            {t('buttons.import')}
          </Button>
        )}
      </Space>
    </Space>
  );
};
