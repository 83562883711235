import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';
import { Properties } from 'types';

export const useContractAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadContracts: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads/${id}/contracts`, getRequest);
    },
    getContractById: ({ queryKey: [, { id }] }) => fetcher(`/contracts/${id}`, getRequest),
    postContract: (data) => {
      return fetcher('/contracts', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    putContract: ({ id, ...data }) => {
      return fetcher(`/contracts/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    patchContract: ({ id, ...data }) => {
      return fetcher(`/contracts/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    deleteContract: (id: number) => {
      return fetcher(`/contracts/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
