import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Form, useForm, useNotify } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { useTaskAPI } from 'api';
import { today, toISO } from 'libs';

interface SingOfferProps {
  id?: number;
  leadId: number;
  formId: string;
  onSuccess: () => void;
}

export const SingOffer: React.FC<SingOfferProps> = ({ id, formId, onSuccess }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { patchTask } = useTaskAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);

  const mutation = useMutation(
    (body: { id: number; completed_at: string | null }) => patchTask(body),
    {
      onError: () =>
        notify.error({
          title: t('error.someThingIsWrong'),
        }),
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(['lead-task']);
        queryClient.invalidateQueries(['lead-logs']);
        queryClient.invalidateQueries(['tasks']);
      },
    },
  );

  const handleSubmit = (): void => {
    if (id) {
      mutation.mutate({ id, completed_at: toISO(today.toString()) });
    }
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      {t('task.singOfferDescription')}
    </Form>
  );
};
