import { Contact } from './contacts';
import { User } from './user';
import { LeadsList } from './leadsList';
import { ActivityDomains } from './activityDomains';
import { Nomenclatures } from './nomenclatures';
import { Opportunity, ShortOpportunity } from './opportunity';
import { Currency } from './currency';

export interface ShortLead {
  id: number;
  owner?: User;
  name: string;
}

export interface Lead extends ShortLead {
  address_country: Nomenclatures;
  address_region: Nomenclatures;
  address_city: Nomenclatures;
  address_street: string;
  activity_domain: ActivityDomains;
  created_by?: any; //todo add interface
  contact: Contact;
  modified_at: string;
  created_at: string;
  website: string;
  comment: string;
  registration_number: string;
  type: LeadType;
  status: LeadStatus;
  branch: number;
  source: number;
  lead_list: number;
  income_source: number;
  primary_contact: {
    email: string;
    phone_number: string;
  };
}
export interface SearchLead extends Lead {
  available_for_view?: boolean;
}

export enum LeadType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export enum LeadStatus {
  PROSPECTING = 'prospecting',
  NOT_INTERESTED = 'not_interested',
  UNQUALIFIED = 'unqualified',
  NOT_ELIGIBLE = 'not_eligible',
  QUALIFIED = 'qualified',
}

export interface ImportProgress {
  id: number;
  type: 'csv' | 'excel';
  notes: string;
  headers: string[];
  file: string;
  owner: {
    id: string;
    email: string;
    name: string;
  };
  create_type: CreateTypeLeadImport;
  progress: number;
  unique_verify: string[];
  lead_list: LeadsList;
  status: ImportStatus;
  step: ImportStep;
}

export enum CreateTypeLeadImport {
  CREATE_RECORDS = 'create_records',
  CREATE_UPDATE_RECORDS = 'create_update_records',
}

export enum ImportStatus {
  NOT_STARTED = 'not_started',
  PROGRESS = 'progress',
  FAILED = 'failed',
  WRONG_FILE = 'wrong_file',
  COMPLETED = 'completed',
}

export enum ImportStep {
  CONFIRM_IMPORTED_FILE = 'confirm_imported_file',
  CONFIRM_FIELD_MAPPING = 'confirm_field_mapping',
  CHECK_FOR_POSSIBLE_DUPLICATES = 'check_for_possible_duplicates',
  START_IMPORT = 'start_import',
  COMPLETED = 'completed',
}

export interface Customer {
  id: number;
  number: string;
  signed_at: string;
  balance_loan: number;
  balance_overdue: number;
  outstanding_days: number;
  lead: Lead;
  currency: Currency;
}

export interface LeadSalesFunnel {
  id: number;
  contact: Contact;
  name: string;
  owner: User;
  status: LeadStatus;
  opportunity: ShortOpportunity;
}

export interface LeadSalesKanban {
  id: number;
  name: string;
  opportunity: Opportunity;
}

export interface LeadSalesKanbanResponse {
  title: string;
  currency: string;
  sum: number;
  data: LeadSalesKanban[];
}

export interface LeadSimilar {
  id: number;
  name: string;
  status: LeadStatus;
}
