import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Col, Form, Input, Radio, Row, Upload, useForm, useNotify, Loader } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { SmartSelect } from 'components/organisms';
import { DropZone } from 'components/molecules';
import { useUploadProps } from 'hooks/useUploadProps';
import { useDocumentCategoriesAPI, useDocumentsAPI, useLeadsAPI } from 'api';
import { listTransformResults } from 'utils';
import { Document } from 'types';

interface DocumentFormProps {
  id?: string;
  formId: string;
  closeModal: () => void;
}

interface Files extends Document {
  uid?: string;
}

export const DocumentForm: React.FC<DocumentFormProps> = ({ id, formId, closeModal }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { id: leadId } = useParams();
  const queryClient = useQueryClient();
  const uploadProps = useUploadProps();
  const { getLeadsList } = useLeadsAPI();
  const { postDocument, postDocumentBulk, patchDocument, getDocumentById } = useDocumentsAPI();
  const { getDocumentsCategoryList } = useDocumentCategoriesAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);
  const [files, setFiles] = React.useState<Files[]>([]);

  const { data, isLoading } = useQuery<Document>(['documents', { id }], getDocumentById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
    enabled: Boolean(id),
  });

  React.useEffect(() => {
    if (data) {
      const { is_public, owner, lead, category, name, ...rest } = data;

      form.setFieldsValue({
        ...rest,
        name,
        is_public: Number(is_public),
        owner: owner?.id,
        lead: lead?.id,
        category: category?.id,
      });
    }
  }, [data]);

  React.useEffect(() => {
    const fieldValue = form.getFieldsValue();

    if (files[0] && !fieldValue.name) {
      form.setFieldsValue({
        name: files[0].name,
      });
    }
  }, [files[0]]);

  const mutation = useMutation(
    id ? patchDocument : files.length <= 1 ? postDocument : postDocumentBulk,
    {
      onError: () =>
        notify.error({
          title: t('error.someThingIsWrong'),
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(['documents']);
        queryClient.invalidateQueries(['opportunities']);
        closeModal();
        notify.success({ title: t('success.successDataSave') });
      },
    },
  );

  const handleSubmit = (): void => {
    if (!id) {
      const { file, ...rest } = form.getFieldsValue();
      const data =
        file.length === 1
          ? { ...rest, file: file[0]?.url }
          : {
              documents: file.map((item) => ({
                file: item.url,
                name: item.name,
              })),
            };
      mutation.mutate({ lead: leadId, ...data });
    } else {
      mutation.mutate({ ...form.getFieldsValue(), id });
    }
  };

  const handleOnchange = (props): void => {
    if (props[0]?.name && props[0].name.includes('file')) {
      setFiles(props[0]?.value || []);
    }
  };

  const handleOnSuccessUpload = (changedData): void => {
    setFiles(changedData.map((item) => ({ file: item.url, name: item.name })));
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Loader loading={isLoading}>
      <Form
        id={formId}
        form={form}
        onFieldsChange={handleOnchange}
        className="create-request"
        onFinish={handleSubmit}
      >
        <Row>
          {!id && (
            <Col size={12}>
              <Form.Field name="file">
                <Upload
                  {...uploadProps}
                  onSuccess={handleOnSuccessUpload}
                  data={{ public: false }}
                  multiple
                >
                  <DropZone />
                </Upload>
              </Form.Field>
            </Col>
          )}
          {files?.length <= 1 && (
            <>
              <Col size={6} className="mt-10">
                <Form.Field name="name" label={t('document.name')}>
                  <Input />
                </Form.Field>
              </Col>
              <Col size={6} className="mt-10">
                <Form.Field name="category" label={t('document.category')}>
                  <SmartSelect
                    search
                    queryKey="document-category"
                    api={getDocumentsCategoryList}
                    mode="single"
                    transform={listTransformResults}
                    selected={
                      data?.category && { value: data.category.id, text: data.category.name }
                    }
                    placeholder={t('main.select')}
                  />
                </Form.Field>
              </Col>
              {!leadId && (
                <Col size={6} className="mt-10">
                  <Form.Field name="lead" label={t('document.lead')}>
                    <SmartSelect
                      search
                      queryKey="lead-list"
                      api={getLeadsList}
                      selected={data?.lead && { value: data.lead.id, text: data.lead.name }}
                      mode="single"
                      placeholder={t('main.select')}
                    />
                  </Form.Field>
                </Col>
              )}
              <Col size={6} className="mt-10">
                <Form.Field name="is_public" label={t('document.privacy')}>
                  <Radio
                    className="document-form__radio-button"
                    options={[
                      { text: t('document.public'), value: 1 },
                      {
                        text: t('document.private'),
                        value: 0,
                      },
                    ]}
                  />
                </Form.Field>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Loader>
  );
};
