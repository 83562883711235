import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Form, Table, useForm, useNotify } from 'ebs-design';
import { useLeadsImportAPI } from 'api';
import { ImportProgress, ImportStep } from 'types';

const step = ImportStep.CONFIRM_IMPORTED_FILE;

export const ConfirmImport: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [form] = useForm();
  const notify = useNotify();
  const [data, setData] = React.useState<string[][]>([]);
  const { getImportByIdPreview } = useLeadsImportAPI();
  const { patchStep } = useLeadsImportAPI();

  const { data: importPreview } = useQuery<{ data: string[][] }>(
    ['import-preview', { id }],
    getImportByIdPreview,
  );

  const columns = React.useMemo(() => {
    const firstRow = importPreview?.data[0] || [];
    return firstRow.map((item, key) => ({
      title: item.toString(),
      render: (row) => `${row[key]}`,
    }));
  }, [importPreview]);

  React.useEffect(() => {
    if (importPreview?.data && importPreview.data.length) {
      const [, ...rest] = importPreview.data;
      setData(rest);
    }
  }, [importPreview]);

  const mutation = useMutation(patchStep, {
    onError: () => {
      notify.error({
        title: t('error.someThingIsWrong'),
      });
    },
    onSuccess: (response: ImportProgress) => {
      history.push(`/contacts/import/${response.id}/${response.step}`);
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({ ...form.getFieldsValue(), id, step: ImportStep.CONFIRM_IMPORTED_FILE });
  };

  return (
    <div className="confirm-import">
      <Form onFinish={handleSubmit} form={form} id={`form-${step}`}>
        <Card.Header className="bold">{t('import.descriptionForConfirmImportStep')}</Card.Header>
        <Card.Body>
          <Table emptyText={t('main.noData')} data={data} columns={columns} />
        </Card.Body>
      </Form>
    </div>
  );
};
