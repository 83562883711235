import React, { useMemo, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  Layout as EBSLayout,
  Sidebar,
  Icon,
  Tooltip,
  NotifyContainer,
  AvatarInline,
  Select,
  Badge,
} from 'ebs-design';
import { LoaderContextProvider } from 'contexts/LoaderContext';
// import { useUsersAPI } from 'api';
import {
  GlobalSearch,
  UserProfileTooltip,
  ApplicationListTooltip,
  Chat,
} from 'components/organisms';
import { SessionContext } from 'contexts/SessionContext';
import { useModules } from 'hooks/useModules';
import { Application, UserCircle, Message } from 'resources/icons';
import { Logo } from 'resources';

const { Topbar, Content, Footer } = EBSLayout;
const { TopMenu, BottomMenu, Item } = Sidebar;

const getChildrenItems = (children): undefined | JSX.Element => {
  if (!children) return;
  return (
    <>
      {Object.keys(children || {}).map((m) => (
        <Link key={`${module}-${m}`} to={children[m].route}>
          <Item text={children[m].label} active={children[m].active} />
        </Link>
      ))}
    </>
  );
};

export const Layout: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { modules } = useModules();
  const { session } = useContext(SessionContext);
  // const { editLanguage } = useUsersAPI();
  const [chatOpen, setChatOpen] = useState(false);
  const [count, setCount] = useState(0);

  const onChangeLanguage = useCallback(
    (value) => {
      // editLanguage({ language: value });
      localStorage.setItem('language', value);
      i18n.changeLanguage(value as string);
    },
    [i18n],
  );

  const chat = useMemo(
    () => <Chat open={chatOpen} onChangeCount={setCount} onClose={() => setChatOpen(false)} />,
    [chatOpen],
  );

  return (
    <>
      <EBSLayout className="ebs-crm">
        <Topbar>
          <Topbar.Toggler />
          <Topbar.Title>
            <Icon component={Logo} />
          </Topbar.Title>
          <Topbar.LeftSide>
            <GlobalSearch />
          </Topbar.LeftSide>
          <Topbar.RightSide>
            <Select
              className="ebs-crm__language-select"
              value={i18n.language}
              options={i18n.languages.map((value) => ({ value, text: value.toUpperCase() }))}
              onChange={onChangeLanguage}
            />

            <AvatarInline
              img={session?.data?.user?.photo}
              alt={[session?.data?.user?.first_name, session?.data?.user?.last_name].join(' ')}
              shortAlt={[
                session?.data?.user?.first_name.charAt(0),
                session?.data?.user?.last_name.charAt(0),
              ].join('')}
              reversed
            />
          </Topbar.RightSide>
        </Topbar>

        <Sidebar>
          <TopMenu>
            {Object.keys(modules).map((module, i) => (
              <Item
                key={i}
                prefix={
                  <Icon
                    {...(modules[module].iconType
                      ? { type: modules[module].iconType }
                      : { component: modules[module]?.iconComponent })}
                  />
                }
                text={<Link to={modules[module].route}>{modules[module].label}</Link>}
                active={modules[module].active}
                className={cn({ 'active-background': modules[module].active })}
              >
                {getChildrenItems(modules[module].children)}
              </Item>
            ))}
          </TopMenu>
          <BottomMenu>
            <Item
              prefix={
                <Badge count={count}>
                  <Icon component={Message} />
                </Badge>
              }
              text={t('menu.chat')}
              onClick={() => setChatOpen(true)}
            />
            <Tooltip
              bodyClass="p-0"
              placement="right"
              trigger="click"
              tooltip={<UserProfileTooltip />}
            >
              <Item
                prefix={<Icon component={UserCircle} />}
                text={t('menu.userProfile')}
                className="user-profile"
              />
            </Tooltip>

            <Tooltip placement="right" trigger="click" tooltip={<ApplicationListTooltip />}>
              <Item
                prefix={<Icon component={Application} />}
                text={<Link to={{ target: '_blank', href: '' }}>{t('menu.applications')}</Link>}
              />
            </Tooltip>
          </BottomMenu>
        </Sidebar>

        <Content>
          <NotifyContainer />
          <LoaderContextProvider>{children}</LoaderContextProvider>
        </Content>
        <Footer />
      </EBSLayout>
      {chat}
    </>
  );
};
