import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Card, Loader, Table, useNotify, InputSearch, Space, AvatarInline } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { useUsersAPI } from 'api';
import { Pagination } from 'components/organisms';
import { InlineEdit } from 'components/molecules';
import { useStateHandlers } from 'hooks/useStateHandlers';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Column, Results, FilterType, Agent } from 'types';
import { defaultFilters } from 'utils';

const queryKey = 'agents';

export const Agents: React.FC = () => {
  const { t } = useTranslation();
  const { updateQuery } = useQueryUpdate();
  const { getUsers } = useUsersAPI();
  const { editUsers } = useUsersAPI();

  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [filters, setFilters] = useStateHandlers({ ...defaultFilters });

  const { data, isLoading } = useQuery<Results<Agent>>(
    [queryKey, { ...filters, ...params }],
    getUsers,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { mutate } = useMutation((data: Agent) => editUsers(data), {
    onMutate: async ({ id, phone_number, sip }: Agent) => {
      const query = [queryKey, { ...filters, ...params }];
      const prevData = queryClient.getQueryData(query) as Results<Agent>;

      queryClient.setQueryData(query, {
        ...prevData,
        results: prevData.results.map((item) => {
          if (item.id === id) {
            item[phone_number ? 'phone_number' : 'sip'] = sip || phone_number;
          }

          return item;
        }),
      });

      return () => queryClient.setQueryData(query, prevData);
    },
    onError: (err, _, rollback: any) => {
      rollback();
      notify.error({
        title: t('error.someThingIsWrong'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });

    return type;
  };

  const onSearchUser = (userValue: string): void => setFilters({ search: userValue, page: 1 });

  const onChangeValue = ({ id, sip, phone_number }: Agent): void => {
    mutate({
      id,
      sip: typeof sip === 'string' && !sip ? null : sip,
      phone_number: typeof phone_number === 'string' && !phone_number ? null : phone_number,
    });
  };

  const columns: Column<Agent>[] = React.useMemo(
    () => [
      {
        title: t('agents.name'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name, email }) => (
          <AvatarInline alt={name} description={email} className="no-avatar" />
        ),
      },
      {
        title: t('agents.phone'),
        width: 200,
        onFilter: (type) => handleFilter(type, 'phone_number'),
        render: ({ id, phone_number }) => (
          <InlineEdit
            defaultValue={phone_number || '---'}
            onChange={(value) => onChangeValue({ id, phone_number: value })}
          />
        ),
      },

      {
        title: t('agents.sip'),
        render: ({ sip, id }) => (
          <InlineEdit
            defaultValue={sip || '---'}
            onChange={(value) => onChangeValue({ id, sip: value })}
          />
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Space className="mb-15">
        <h3 className="title">{`${t(`menu.agents`)} (${data?.count || 0})`}</h3>
        <InputSearch placeholder={t('agents.search')} onSearch={onSearchUser} />
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            emptyText={t('main.noData')}
            data={data?.results || []}
            columns={columns}
            className="no-border"
          />
        </Loader>
        <Card.Footer>
          <Pagination size={data?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
