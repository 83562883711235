import React, { useState, useEffect } from 'react';
import { useCookie } from 'react-use';
import { getTokenFromUrl } from 'utils';
import { ServiceTypes, Session, SessionData, Tokens } from 'hooks/useFetcher/interfaces';

import SessionContext from './SessionContext';
import { fetchUser, getUrlDomain, logout } from './utils';
import { BootstrapConfig, BootstrapOptions, SessionContextState } from './interface';

interface SessionProviderProps {
  initialSession: Session | null;
  apiConfig: BootstrapConfig;
  options: BootstrapOptions;
  status: number;
  onRedirect: () => void;
}

type SPT = React.FC<SessionProviderProps>;

export const accessTokenKey = '__access_token__';
export const refreshTokenKey = '__refresh_token__';
export const saasTokenKey = '__saas_token__';

const clearStorage = (): void => {
  window.localStorage.removeItem(accessTokenKey);
  window.localStorage.removeItem(saasTokenKey);
};

/**
 * This component provides session context down the tree.
 *
 * Use it only once in the application and in the most top
 * level possible.
 */
const SessionProvider: SPT = ({
  children,
  initialSession,
  apiConfig,
  options,
  status,
  onRedirect,
}) => {
  const [, updateCookie] = useCookie('Authorization');
  const [, updateaAppCookie] = useCookie('app_token');
  const [session, setSession] = useState<Session | null>(initialSession);

  useEffect(() => {
    setSession(initialSession);
  }, [initialSession]);

  useEffect(() => {
    if (session !== null) {
      window.localStorage.setItem(accessTokenKey, session.tokens.access);
      window.localStorage.setItem(refreshTokenKey, session.tokens.refresh);
      window.localStorage.setItem(saasTokenKey, getTokenFromUrl());

      updateCookie(session.tokens.access, { domain: `.${getUrlDomain()}` });
      updateaAppCookie(getTokenFromUrl(), { domain: `.${getUrlDomain()}` });
    } else {
      clearStorage();
      updateCookie('', { domain: `.${getUrlDomain()}` });
      updateaAppCookie('', { domain: `.${getUrlDomain()}` });
    }
  }, [session]);

  const setTokens = async (tokens: Tokens, data?: SessionData): Promise<Error | null> => {
    if (data) {
      setSession({ data, tokens });
    } else if (session) {
      setSession({ ...session, tokens });
    } else if (options.hasOrganization) {
      const [error, data] = await fetchUser(apiConfig[ServiceTypes.ORGANIZATION], tokens);

      if (error) {
        return error;
      }

      if (data) {
        setSession({
          data,
          tokens,
        });
      }
    }
    return null;
  };

  const removeSession = (): void => setSession(null);
  const onLogout = (): void => {
    removeSession();
    clearStorage();

    logout(apiConfig[ServiceTypes.SSO]);

    onRedirect();
  };

  useEffect(() => {
    if (status === 2) {
      onLogout();
    }
  }, [status, onLogout]);

  const contextValue: SessionContextState = {
    session,
    apiConfig,
    setTokens,
    removeSession,
    onLogout,
  };

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
