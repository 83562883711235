import * as React from 'react';

import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader } from 'ebs-design';
import { Column, ReportsVisits } from 'types';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useReportsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { ReportVisitsFilter } from 'features/reports/Filters';

export const ReportVisits: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportVisits } = useReportsAPI();

  const { data: visits, isLoading } = useQuery<ReportsVisits[]>(
    [
      'reports-visits',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
        date: undefined,
      },
    ],
    getReportVisits,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const columns: Column<ReportsVisits>[] = React.useMemo(
    () => [
      {
        title: t('reports.creditExpert'),
        render: ({ first_name, last_name }) => [first_name, last_name].join(' '),
      },

      {
        title: t('visits.typeMarketing'),
        dataIndex: 'marketing_visits',
      },
      {
        title: t('visits.typePlanned'),
        dataIndex: 'planned_visits',
      },
      {
        title: t('visits.total'),
        dataIndex: 'total',
      },
    ],
    [t, visits, params],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <ReportVisitsFilter count={visits?.length} />
        </Card.Header>
        <Table
          data={visits}
          columns={columns}
          className="no-border contacts-table"
          emptyText={t('main.noData')}
        />
      </Loader>
    </Card>
  );
};
