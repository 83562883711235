import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNotify, Space, Card, Loader, Select, Button, Form, useForm, Input } from 'ebs-design';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { SessionContext } from 'contexts/SessionContext';
import { ForbiddenError } from 'components/atoms';
import { ValidationError } from 'errors';
import { AdminSettings, LogType } from 'types';
import { useSettingsAPI } from 'api';

export const Settings: React.FC<AdminSettings> = () => {
  const { session } = React.useContext(SessionContext);

  if (session?.data?.current_application?.application_role !== UserRoleCRM.admin) {
    return <ForbiddenError />;
  } else {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const [form] = useForm();
    const { getSettings, createSettings } = useSettingsAPI();

    const { data: settings, isLoading } = useQuery<AdminSettings>('settings', getSettings, {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    });

    const mutation = useMutation(createSettings, {
      onError: (e) => {
        if (e instanceof ValidationError) {
          form.setFields(e.fields);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('settings');
        notify.success({ title: t('success.successDataSave') });
      },
    });

    const handleSubmit = (): void => {
      const { pbx_value, ...rest } = form.getFieldsValue();
      mutation.mutate({ ...rest, pbx_value: parseFloat(pbx_value) });
      form.resetFields();
    };

    return (
      <>
        <Space className="mb-20">
          <Space>
            <h3 className="title">{t('adminSettings.settings')}</h3>
          </Space>
        </Space>
        <div className="settings__box">
          <Form form={form} type="vertical" onFinish={handleSubmit}>
            <Card>
              <Loader loading={isLoading} size="regular">
                <Card.Body className="p-30">
                  <p className="bold">{t('adminSettings.communicationFeed')}</p>
                  <Form.Field name="name" className="bolt" label="">
                    <Select value={settings?.log_types} mode="multiple" placeholder="Select">
                      <Select.Options>
                        {Object.values(LogType).map((item, key) => (
                          <Select.Options.Item value={item} key={`type-${key}`}>
                            {t(`logs.${item}`)}
                          </Select.Options.Item>
                        ))}
                      </Select.Options>
                    </Select>
                  </Form.Field>
                  <p className="bold settings__box-text">
                    {t('adminSettings.textOnSettings')}
                    <span className="settings__box-span">
                      &quot;{t('adminSettings.spanOnSettings')}&quot;
                    </span>
                  </p>
                  <Form.Field name="follow_up_limit">
                    <Input
                      placeholder={settings?.follow_up_limit ? `${settings?.follow_up_limit}` : '0'}
                      type="number"
                    />
                  </Form.Field>
                  <Form.Field name="pbx_value" label={'PBX Cost'}>
                    <Input
                      placeholder={settings?.pbx_value ? `${settings?.pbx_value}` : '0'}
                      type="number"
                    />
                  </Form.Field>
                  <Space justify="end">
                    <Button type="primary" onClick={() => form.submit()}>
                      {t('buttons.save')}
                    </Button>
                  </Space>
                </Card.Body>
              </Loader>
            </Card>
          </Form>
        </div>
      </>
    );
  }
};
