import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useClickAway from 'react-use/esm/useClickAway';
import { Loader, Button, InputSearch, useNotify, ListGroup, AvatarInline } from 'ebs-design';
import { useSearchAPI } from 'api';
import { Results, SearchLead } from 'types';
import { isCurrentURl } from 'utils';

export const GlobalSearch: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const { getSearch } = useSearchAPI();
  const { location } = useHistory();
  const notify = useNotify();
  const ref = React.useRef(null);

  const { data, isLoading } = useQuery<Results<SearchLead>>(
    ['global-search-list', { search, per_page: 5 }],
    getSearch,
    {
      enabled: !!search.length,
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    setVisible(false);
  }, [location]);

  useClickAway(ref, () => {
    setVisible(false);
  });

  const onSearch = (value: string): void => {
    setSearch(value);

    if (value.length) {
      setVisible(true);
    }
  };
  function getResponsibleAlt(lead: SearchLead): string {
    return (
      `${lead['name']} ${lead['primary_contact']?.phone_number || '---'}` +
      ((lead['owner']?.name && ` (${t('lead.responsible')}: ${lead['owner']?.name})`) || '')
    );
  }

  const isGlobalSearch = isCurrentURl('/global-search');

  function getSearchResultItem(lead: SearchLead, index: number): JSX.Element {
    return (
      <ListGroup.Item key={index}>
        <AvatarInline
          className={`${
            lead.available_for_view ? 'no-avatar clickable' : 'no-avatar not-clickable'
          }`}
          alt={getResponsibleAlt(lead)}
          description={lead.primary_contact.email}
          status="active"
        ></AvatarInline>
      </ListGroup.Item>
    );
  }

  return (
    <div ref={ref} className="global-search">
      <div className="global-search__input" onClick={() => setVisible(true)}>
        <InputSearch
          placeholder={t('filter.searchByKeyword')}
          size="large"
          iconAlign="prefix"
          value={search}
          onSearch={onSearch}
        />
      </div>
      <AnimateHeight
        className="global-search__results"
        duration={400}
        height={visible ? 'auto' : 0}
      >
        <div className="global-search__results-container">
          {!isGlobalSearch && (
            <div className="global-search__results--advanced">
              <Link to={`/global-search?search=${search}`}>
                <Button type="primary">{t('advanced')}</Button>
              </Link>
            </div>
          )}

          <Loader loading={isLoading}>
            <ListGroup>
              {data?.results.length ? (
                data?.results.map((lead, i) =>
                  lead.available_for_view ? (
                    <Link to={`/lead/${lead.id}`} key={i}>
                      {getSearchResultItem(lead, i)}
                    </Link>
                  ) : (
                    getSearchResultItem(lead, i)
                  ),
                )
              ) : (
                <ListGroup.Item>{t('main.noData')}</ListGroup.Item>
              )}
            </ListGroup>
          </Loader>
        </div>
      </AnimateHeight>
    </div>
  );
};
