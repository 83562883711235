import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Card, Icon, Label, Loader, Modal, Space, Tooltip, useNotify } from 'ebs-design';
import { useLeadsAPI } from 'api';
import { ContactForm } from 'features/contacts/ContactForm';
import { LoaderContext } from 'contexts/LoaderContext';
import { SmartSelect } from 'components/organisms';
import { Earth, Email, Map, Pen, Phone, UserAlone } from 'resources/icons';
import { ValidationError } from 'errors';
import { statusLabel } from 'utils';
import { Lead, LeadStatus } from 'types';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useExpertPermission } from 'hooks/useExpertPermission';

interface LeadCardProps {
  lead: Lead;
}

export const LeadCard: React.FC<LeadCardProps> = ({ lead }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { loader } = React.useContext(LoaderContext);

  const { patchLead } = useLeadsAPI();
  const [openModal, setOpenModal] = React.useState(false);

  const changeStatusMutation = useMutation((status: string) => patchLead({ id: lead.id, status }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['lead']);
      queryClient.invalidateQueries(['lead-logs']);
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['opportunities']);
    },
    onError: (error) => {
      if (error instanceof ValidationError) {
        error.fields?.forEach(({ name, errors }) =>
          notify.error({
            title: Array.isArray(name) ? name[name.length - 1].toUpperCase() : name,
            description: Array.isArray(errors) ? errors.join(',') : errors,
          }),
        );
      }
    },
  });

  const onClose = (): void => {
    setOpenModal(false);
  };

  const handleSuccessUpdate = (): void => {
    onClose();
    queryClient.invalidateQueries(['lead']);
  };

  const buttonLoading = React.useMemo(() => {
    if (lead.id && loader) {
      return loader[lead.id];
    }
    return false;
  }, [lead.id, loader]);

  return (
    <>
      <Space align="center" justify="space-between" className="lead-details__head">
        <h3 className="lead-details__name">{lead.name}</h3>
        {useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
        useExpertPermission({ ownerId: lead.owner?.id }) ? (
          <Tooltip
            placement="bottom"
            trigger="click"
            bodyClass="p-0 lead-details__tooltip"
            tooltip={
              <SmartSelect
                value={lead.status}
                options={Object.values(LeadStatus).map((status) => ({
                  value: status,
                  text: t(`statuses.${status}`),
                }))}
                optionsMode="box"
                onChange={(value) => changeStatusMutation.mutate(value)}
              />
            }
          >
            {changeStatusMutation.isLoading ? (
              <Loader.Inline />
            ) : (
              <Label
                type="fill"
                className="lead-details__tooltip-label"
                text={
                  <>
                    {t(`statuses.${lead.status}`)}
                    {<Icon component={Pen} />}
                  </>
                }
                {...statusLabel[lead.status]}
              />
            )}
          </Tooltip>
        ) : null}
      </Space>
      <Card>
        <Card.Body className="lead-details__general-data">
          {useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
          useExpertPermission({ ownerId: lead.owner?.id }) ? (
            <Button
              size="small"
              type="ghost"
              className="lead-details__edit-lead"
              onClick={() => setOpenModal(true)}
            >
              <Icon component={Pen} />
            </Button>
          ) : null}
          <div className="lead-details__general-data__item">
            <Icon component={Map} />
            {[
              lead.address_country?.name,
              lead.address_region?.name,
              lead.address_city?.name,
              lead.address_street,
            ]
              .filter((i) => i)
              .join(', ') || '---'}
          </div>
          {lead.website && (
            <div className="lead-details__general-data__item">
              <a href={lead.website} target="_blank" rel="noreferrer">
                <Icon component={Earth} />
                {lead.website}
              </a>
            </div>
          )}
          {lead.contact?.phone_number && (
            <div className="lead-details__general-data__item">
              <a href={`tel:${lead.contact?.phone_number}`} target="_blank" rel="noreferrer">
                <Icon component={Phone} />
                {lead.contact?.phone_number}
              </a>
            </div>
          )}
          {lead.contact?.landline_number && (
            <div className="lead-details__general-data__item">
              <a href={`tel:${lead.contact.landline_number}`} target="_blank" rel="noreferrer">
                <Icon component={Phone} />
                {lead.contact.landline_number}
              </a>
            </div>
          )}
          {lead.contact?.email && (
            <div className="lead-details__general-data__item">
              <a href={`mailto:${lead.contact.email}`} target="_blank" rel="noreferrer">
                <Icon component={Email} />
                {lead.contact.email}
              </a>
            </div>
          )}
          <div className="lead-details__responsible_person lead-details__general-data__item">
            <Icon component={UserAlone} />
            {lead['owner']?.name || '---'}
          </div>
        </Card.Body>
      </Card>
      {openModal && (
        <Modal
          closeOnClickOutside={false}
          open
          size="regular"
          onClose={onClose}
          className="custom-period"
        >
          <Card>
            <Card.Body>
              <ContactForm id={lead.id} onSuccess={handleSuccessUpdate} />
            </Card.Body>
            <Card.Footer>
              <Space justify="space-between">
                <Space>
                  <Button onClick={onClose}>{t('buttons.cancel')}</Button>
                </Space>
                <Space>
                  <Button
                    type="primary"
                    submit
                    form="lead-edit"
                    loading={buttonLoading}
                    prefix={<Icon type="check" model="bold" />}
                  >
                    {t('buttons.save')}
                  </Button>
                </Space>
              </Space>
            </Card.Footer>
          </Card>
        </Modal>
      )}
    </>
  );
};
