import * as React from 'react';
import { Card, Col, Row } from 'ebs-design';
import { SalesFunnelCard, SalesFunnelTable } from 'features/reports/SalesFunnelCards';
import { ReportSalesFunnelFilter } from 'features/reports/Filters';

export const ReportSalesFunnel: React.FC = () => {
  return (
    <Card>
      <Card.Header>
        <ReportSalesFunnelFilter />
      </Card.Header>
      <Card.Body className="no-background">
        <Row className="equal-height-columns">
          <Col size={6}>
            <SalesFunnelCard />
          </Col>
          <Col size={6}>
            <SalesFunnelTable />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
