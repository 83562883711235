import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal, Space, Loader } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { Check } from 'resources/icons';
import {
  SendOffer,
  CallClient,
  SingOffer,
  ApproveRequest,
  SingContract,
  CreateRequest,
} from './CompleteTaskForm';
import { useTaskAPI } from 'api';
import { Code, Task } from 'types';

interface CompleteTaskProps {
  task: Task;
  leadId: number;
  onCancel: () => void;
}

const formId = 'complete-task-form';

const FormComponents = {
  [Code.SEND_OFFER]: SendOffer,
  [Code.CALL_CLIENT]: CallClient,
  [Code.SING_OFFER]: SingOffer,
  [Code.SEND_REQUEST_TO_APPROVE]: ApproveRequest,
  [Code.SIGN_CONTRACT]: SingContract,
  [Code.CREATE_REQUEST]: CreateRequest,
};

export const CompleteTask: React.FC<CompleteTaskProps> = ({ task, leadId, onCancel }) => {
  const { t } = useTranslation();
  const { getTaskById } = useTaskAPI();
  const { loader } = React.useContext(LoaderContext);

  const { data: dataTask, isLoading: isLoadingTask } = useQuery<Task>(
    ['task', { id: task.id }],
    getTaskById,
    {
      enabled: Boolean(task.id),
    },
  );

  const Form = React.useMemo(() => dataTask && FormComponents[dataTask?.type.code], [dataTask]);

  const buttonLoading = React.useMemo(() => {
    if (formId && loader) {
      return loader[formId];
    }
    return false;
  }, [formId, loader]);

  return (
    <Modal
      closeOnClickOutside={false}
      open
      header={`${t('task.completeForm')}: ${task.title}`}
      onClose={onCancel}
    >
      <Modal.Content>
        <Loader loading={isLoadingTask}>
          {Form && <Form id={task?.id} leadId={leadId} formId={formId} onSuccess={onCancel} />}
        </Loader>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
          <Button
            type="primary"
            submit
            form={formId}
            loading={buttonLoading}
            prefix={<Icon className="action-card__save-button" component={Check} />}
          >
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
