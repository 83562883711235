import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Properties } from 'types';

export const useCallsNormalizedData = (data?: Properties): Properties => {
  const { t } = useTranslation();

  const reportCallsNormalized = useMemo(() => {
    if (Array.isArray(data) && !!data?.length) {
      const newData =
        data?.flatMap((el) => [
          {
            name: [el?.first_name, el?.last_name].join(' ') || el?.name,
            call_type: '',
            calls: el?.total_calls,
            calls_duration: el?.total_calls_duration,
            leads: el?.total_leads,
            pbx_cost: el?.total_pbx_cost,
            signed_contracts: el?.total_signed_contracts,
            signed_contracts_value: el?.total_signed_contracts_value,
            avg_signed_contracts_value: el?.total_avg_signed_contracts_value,
          },
          {
            call_type: t('reports.inbound'),
            calls: el?.incoming_calls,
            calls_duration: el?.incoming_calls_duration,
            leads: el?.incoming_leads,
            pbx_cost: el?.incoming_pbx_cost,
            signed_contracts: el?.incoming_signed_contracts,
            signed_contracts_value: el?.incoming_signed_contracts_value,
            avg_signed_contracts_value: el?.incoming_avg_signed_contracts_value,
          },
          {
            call_type: t('reports.outbound'),
            calls: el?.outgoing_calls,
            calls_duration: el?.outgoing_calls_duration,
            leads: el?.outgoing_leads,
            pbx_cost: el?.outgoing_pbx_cost,
            signed_contracts: el?.outgoing_signed_contracts,
            signed_contracts_value: el?.outgoing_signed_contracts_value,
            avg_signed_contracts_value: el?.outgoing_avg_signed_contracts_value,
            outgoing_calls_outstanding: el?.outgoing_calls_outstanding,
            outgoing_calls_sales: el?.outgoing_calls_sales,
          },
        ]) || [];

      return newData;
    } else if (data && data?.branches) {
      const newData =
        data?.branches?.flatMap((el) => [
          {
            name: el?.name,
            call_type: '',
            calls: el?.total_calls,
            calls_duration: el?.total_calls_duration,
            leads: el?.total_leads,
            pbx_cost: el?.total_pbx_cost,
            signed_contracts: el?.total_signed_contracts,
            signed_contracts_value: el?.total_signed_contracts_value,
            avg_signed_contracts_value: el?.total_avg_signed_contracts_value,
          },
          {
            call_type: t('reports.inbound'),
            calls: el?.incoming_calls,
            calls_duration: el?.incoming_calls_duration,
            leads: el?.incoming_leads,
            pbx_cost: el?.incoming_pbx_cost,
            signed_contracts: el?.incoming_signed_contracts,
            signed_contracts_value: el?.incoming_signed_contracts_value,
            avg_signed_contracts_value: el?.incoming_avg_signed_contracts_value,
          },
          {
            call_type: t('reports.outbound'),
            calls: el?.outgoing_calls,
            calls_duration: el?.outgoing_calls_duration,
            leads: el?.outgoing_leads,
            pbx_cost: el?.outgoing_pbx_cost,
            signed_contracts: el?.outgoing_signed_contracts,
            signed_contracts_value: el?.outgoing_signed_contracts_value,
            avg_signed_contracts_value: el?.outgoing_avg_signed_contracts_value,
            outgoing_calls_outstanding: el?.outgoing_calls_outstanding,
            outgoing_calls_sales: el?.outgoing_calls_sales,
          },
        ]) || [];

      const totals = [
        {
          name: t('reports.total'),
          call_type: '',
          calls: data?.totals?.total_calls,
          calls_duration: data?.totals?.total_calls_duration,
          leads: data?.totals?.total_leads,
          pbx_cost: data?.totals?.total_pbx_cost,
          signed_contracts: data?.totals?.total_signed_contracts,
          signed_contracts_value: data?.totals?.total_signed_contracts_value,
          avg_signed_contracts_value: data?.totals?.total_avg_signed_contracts_value,
        },
        {
          call_type: t('reports.inbound'),
          calls: data?.totals?.total_incoming_calls,
          calls_duration: data?.totals?.total_incoming_calls_duration,
          leads: data?.totals?.total_incoming_leads,
          pbx_cost: data?.totals?.total_incoming_pbx_cost,
          signed_contracts: data?.totals?.total_incoming_signed_contracts,
          signed_contracts_value: data?.totals?.total_incoming_signed_contracts_value,
          avg_signed_contracts_value: data?.totals?.total_incoming_avg_signed_contracts_value,
        },
        {
          call_type: t('reports.outbound'),
          calls: data?.totals?.total_outgoing_calls,
          calls_duration: data?.totals?.total_outgoing_calls_duration,
          leads: data?.totals?.total_outgoing_leads,
          pbx_cost: data?.totals?.total_outgoing_pbx_cost,
          signed_contracts: data?.totals?.total_outgoing_signed_contracts,
          signed_contracts_value: data?.totals?.total_outgoing_signed_contracts_value,
          avg_signed_contracts_value: data?.totals?.total_outgoing_avg_signed_contracts_value,
          outgoing_calls_outstanding: data?.totals?.total_calls_outstanding,
          outgoing_calls_sales: data?.totals?.total_calls_sales,
        },
      ];

      return [...newData, ...totals];
    }

    return [];
  }, [data, t]);

  return { reportCallsNormalized };
};
