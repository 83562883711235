import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Label,
  Radio,
  Row,
  Textarea,
  useForm,
  useNotify,
} from 'ebs-design';
import { SessionContext } from 'contexts/SessionContext';
import { LoaderContext } from 'contexts/LoaderContext';
import { SmartSelect } from 'components/organisms';
import { useLeadsAPI, useTaskAPI, useTaskTypesAPI, useUsersAPI } from 'api';
import { dateTimeFormat, dateTimeFormatAPI, dateTimeFormatInput, format, toISO } from 'libs';
import { userTransformResults, listTransformResults } from 'utils';
import { ValidationError } from 'errors';
import { Task } from 'types';
interface TaskFormProps {
  id?: string | number;
  formId?: string;
  closeModal: () => void;
  readOnly?: boolean;
}

export const TaskForm: React.FC<TaskFormProps> = ({ formId, id, closeModal, readOnly = false }) => {
  const { t } = useTranslation();
  const { id: lead } = useParams();
  const notify = useNotify();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const { session } = React.useContext(SessionContext);
  const { setLoader } = React.useContext(LoaderContext);

  const { getTaskById, postTask, putTask } = useTaskAPI();
  const { getListTaskTypes } = useTaskTypesAPI();
  const { getLeads } = useLeadsAPI();
  const { getUsers } = useUsersAPI();

  const { data } = useQuery<Task>(['task', { id }], getTaskById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  React.useEffect(() => {
    if (data) {
      const { owner, type, deadline, lead: l, ...formData } = data;
      form.setFieldsValue({
        ...formData,
        owner: owner?.id,
        type: type?.id,
        lead: l?.id,
        deadline: deadline && format(deadline, dateTimeFormat),
      });
    } else {
      form.setFieldsValue({
        owner: session?.data?.user.sso_id,
      });
    }
  }, [data]);

  const mutation = useMutation(id ? putTask : postTask, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['tasks']);
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      closeModal();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    const { deadline, completed, ...rest } = form.getFieldsValue();
    mutation.mutate({
      lead,
      ...rest,
      ...(completed && { completed_at: toISO(new Date()) }),
      deadline: deadline && toISO(format(deadline, dateTimeFormatAPI, dateTimeFormat)),
      id,
    });
  };

  React.useEffect(() => {
    if (formId) {
      setLoader({ [formId]: mutation.isLoading });
    }
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Row>
        <Col size={12} className="mt-10">
          <Form.Field name="title" label={t('task.title')}>
            <Input placeholder={t('task.title')} disabled={readOnly} />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="owner" label={t('task.assignTo')}>
            <SmartSelect
              search
              api={getUsers}
              mode="single"
              placeholder={t('main.select')}
              transform={userTransformResults}
              selected={
                data
                  ? data?.owner && {
                      value: data.owner.id,
                      text: `${data.owner?.first_name} ${data.owner?.last_name}`,
                    }
                  : {
                      value: session?.data?.user.sso_id,
                      text:
                        session?.data?.user?.first_name || session?.data?.user?.last_name
                          ? `${session?.data?.user?.first_name} ${session?.data?.user?.last_name}`
                          : session?.data?.user.email,
                    }
              }
              disabled={readOnly}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="deadline" label={t('task.dateTime')}>
            <DatePicker showTimeSelect dateFormat={dateTimeFormatInput} disabled={readOnly} />
          </Form.Field>
        </Col>
        <Col size={12} className="mt-10">
          <Form.Field
            name="description"
            label={t('task.description')}
            className="textarea-static-width"
          >
            <Textarea placeholder={t('task.description')} disabled={readOnly} />
          </Form.Field>
        </Col>
        {!lead && (
          <Col size={6} className="mt-10">
            <Form.Field name="lead" label={t('task.lead')}>
              <SmartSelect
                search
                api={getLeads}
                mode="single"
                placeholder={t('task.lead')}
                selected={data?.lead && { value: data.lead.id, text: data.lead.name }}
                disabled={readOnly}
              />
            </Form.Field>
          </Col>
        )}
        <Col size={6} className="mt-10">
          <Form.Field name="type" label={t('task.taskType')}>
            <SmartSelect
              search
              api={getListTaskTypes}
              mode="single"
              placeholder={t('task.taskType')}
              selected={data?.type && { value: data.type.id, text: data.type.name }}
              transform={listTransformResults}
              disabled={readOnly}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="priority" label={t('task.priority')}>
            <Radio
              className="task-form__radio"
              options={[
                { text: <Label type="fill" status="danger" text={t('task.high')} />, value: 1 },
                { text: <Label type="fill" status="info" text={t('task.medium')} />, value: 2 },
                { text: <Label type="fill" status="success" text={t('task.low')} />, value: 3 },
              ]}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field
            name="completed"
            label={t('filter.status')}
            initialValue={data?.completed_at ? 1 : 0}
          >
            <Radio
              className="task-form__radio"
              options={[
                {
                  text: <Label type="fill" status="warning" text={t('filter.uncompleted')} />,
                  value: 0,
                },
                {
                  text: <Label type="fill" status="success" text={t('filter.completed')} />,
                  value: 1,
                },
              ]}
            />
          </Form.Field>
        </Col>
      </Row>
    </Form>
  );
};
