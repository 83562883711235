import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useDocumentCategoriesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getDocumentsCategory: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/document-categories`, query), getRequest);
    },
    getDocumentsCategoryList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/document-categories/list`, query), getRequest);
    },
    getDocumentCategoryById: ({ queryKey: [, { id }] }) =>
      fetcher(`/document-categories/${id}`, getRequest),
    createDocumentCategory: (data) => {
      return fetcher('/document-categories', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateDocumentCategory: ({ id, ...data }) => {
      return fetcher(`/document-categories/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteDocumentCategory: (id: number) => {
      return fetcher(`/document-categories/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
