import * as React from 'react';
import { useSetState } from 'react-use';
import dayjs from 'dayjs';
import { Row, Col } from 'ebs-design';
import { format, dateFormatAPI, dateFormat } from 'libs';
import { useQueryParams } from 'hooks/useQueryParams';
import { Properties } from 'types';

import { Filters } from '../Filters';
import { SalesFunnel } from '../SalesFunnel';
import { TasksCards } from '../TasksCards';
import { RecentLeads } from '../RecentLeads';
import { TopCreditExperts } from '../TopCreditExperts';
import { ChartReports } from '../ChartReports';
import { SessionContext } from 'contexts/SessionContext';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';

export const Dashboard: React.FC = () => {
  const params = useQueryParams();
  const { session } = React.useContext(SessionContext);

  const [filters, setFilters] = useSetState<Properties>({
    ...params,
    ...(!params.date_from && { days: params.days ? parseInt(params.days) : 7 }),
    ...(!params.user &&
      useRolePermission([UserRoleCRM.expert]) && {
        user: session?.data?.user.sso_id,
      }),
  });

  const props = React.useMemo(
    () => ({
      ...((filters.days || filters.date_from) && {
        date_from: filters.days
          ? dayjs().subtract(filters.days, 'day').format(dateFormatAPI)
          : format(filters.date_from, dateFormatAPI, dateFormat),
      }),
      ...((filters.days || filters.date_to) && {
        date_to: filters.days
          ? format(new Date(), dateFormatAPI)
          : format(filters.date_to, dateFormatAPI, dateFormat),
      }),
      user: filters.user,
      branch: filters.branch,
      currentYear: !!filters.days,
    }),
    [filters],
  );

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />
      <TasksCards {...props} />
      <Row className="mb-15 equal-height-columns">
        <Col size={6}>
          <RecentLeads {...props} />
        </Col>
        <Col size={6}>
          <SalesFunnel {...props} />
        </Col>
      </Row>
      <Row className="equal-height-columns">
        <Col size={6}>
          <TopCreditExperts {...props} />
        </Col>
        <Col size={6}>
          <ChartReports {...props} />
        </Col>
      </Row>
    </>
  );
};
