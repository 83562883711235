import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { Icon, Select, Space } from 'ebs-design';
import { useQuery } from 'react-query';

import { Filters } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Results, User } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { defaultNonPagination } from 'utils';
import { useUsersAPI } from 'api';
import { UserAlone } from 'resources/icons';

interface FiltersProps {
  search?: string;
  signed?: string[];
  signed_from?: string;
  signed_to?: string;
  deadline?: string[];
  deadline_from?: string;
  deadline_to?: string;
  page?: number;
}

interface LeadFilterProps {
  count?: number;
}

export const LeadFilter: React.FC<LeadFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getUsers } = useUsersAPI();

  const [filters, setFilters] = useSetState<FiltersProps>({
    ...params,
    page: params.page ? Number(params.page) : 1,
  });
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);

  const { data: users } = useQuery<Results<User>>(
    [
      'users',
      {
        ...usersFilter,
        include_archived: useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin]),
      },
    ],
    getUsers,
  );

  React.useEffect(() => {
    const { signed, deadline, ...rest } = filters;
    const [signed_from, signed_to] = signed || [];
    const [deadline_from, deadline_to] = deadline || [];

    updateQuery({
      ...rest,
      ...(signed_from && { signed_from }),
      ...(signed_to && { signed_to }),
      ...(deadline_from && { deadline_from }),
      ...(deadline_to && { deadline_to }),
      ...(signed && { signed }),
      ...(deadline && { deadline }),
      per_page: params.per_page,
    });
  }, [filters]);

  const handleFilters = (value): void => {
    setFilters({ ...value, page: 1 });
  };

  return (
    <>
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`lead.leads`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
        </Space>
        <Space justify="end">
          {useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin, UserRoleCRM.expert]) && (
            <Filters.Select
              prefix={<Icon component={UserAlone} />}
              placeholder={t('filter.creditExpert')}
              value={params.owner}
              field="owner"
            >
              <Select.Options>
                {(users?.results || []).map((item, key) => (
                  <Select.Options.Item key={`${key}-user`} value={item.id}>
                    {item?.first_name || item?.last_name
                      ? `${item?.first_name} ${item?.last_name}`
                      : item.email}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Select.Pagination
                count={users?.count || 0}
                limit={usersFilter.per_page}
                page={usersFilter.page}
                setPage={(page) => setUsersFilter({ page })}
                mode="scroll"
              />
            </Filters.Select>
          )}
          <Filters.DatePickerFilter
            field="deadline"
            type="range"
            value={params.deadline}
            placeholder={t('filter.contractSigning')}
          />
          <Filters.DatePickerFilter
            field="signed"
            type="range"
            value={params.signed}
            placeholder={t('filter.contractExpiry')}
          />
        </Space>
      </Filters>
    </>
  );
};
