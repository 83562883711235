import * as React from 'react';
import { Call, Task } from 'types';

interface LogsPanelContextState {
  isOpenCreateNote: boolean;
  toggleCreateNote: () => void;

  isOpenCreateMeeting: boolean;
  toggleCreateMeeting: () => void;

  callItem?: Call;
  openPlayer: (call: Call) => void;
  closePlayer: () => void;

  openCompleteTask: (task: Task) => void;
}

const defaultContextValue: LogsPanelContextState = {
  isOpenCreateNote: false,
  toggleCreateNote: () => null,
  isOpenCreateMeeting: false,
  toggleCreateMeeting: () => null,
  callItem: undefined,
  openPlayer: () => null,
  closePlayer: () => null,
  openCompleteTask: () => null,
};

export default React.createContext<LogsPanelContextState>(defaultContextValue);
