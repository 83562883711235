import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Actions,
  AvatarInline,
  Button,
  ButtonGroup,
  Icon,
  Label,
  Space,
  Tooltip,
} from 'ebs-design';
import { Arrow, Email, Phone } from 'resources/icons';
import { dateFormat, format } from 'libs';
import { SessionContext } from 'contexts/SessionContext';
import { useDomain } from 'hooks/useDomain';
import { CallCategoryType, Lead, Opportunity, opportunityLabel } from 'types';
import { ServiceTypes, UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useExpertPermission } from 'hooks/useExpertPermission';
import { SmartSelect } from 'components/organisms';

interface OpportunityItemProps {
  opportunity: Opportunity;
  lead: Lead;
  onCall: (data) => void;
  onEdit: (id) => void;
  onDelete: (id) => void;
}

export const OpportunityItem: React.FC<OpportunityItemProps> = ({
  opportunity,
  lead,
  onCall,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { active, getUrl } = useDomain();
  const { session } = React.useContext(SessionContext);
  const [collapsed, setCollapsed] = React.useState(false);

  const handleToggleCollapsed = (): void => {
    setCollapsed((s) => !s);
  };

  const mailToken = React.useMemo(
    () =>
      session?.data?.pinned_applications?.find(
        ({ instance_type: { type } }) => type === ServiceTypes.MAIL.toLowerCase(),
      )?.token || null,
    [session],
  );

  const mailUrl = active && getUrl(active);

  return (
    <div className="opportunity-item" onDoubleClick={() => onEdit(opportunity.id)}>
      <Space justify="space-between">
        <Label
          type="fill"
          status={opportunityLabel[opportunity.status]}
          text={t(`opportunities.${opportunity.status}`)}
        />
        <Space>
          <div className="opportunity-item__value">
            {opportunity.value}
            <span className="opportunity-item__value-currency">{opportunity.currency?.name}</span>
          </div>
          {useRolePermission([UserRoleCRM.marketing, UserRoleCRM.manager, UserRoleCRM.admin]) ||
          useExpertPermission({ ownerId: lead?.owner?.id }) ? (
            <Actions title={t('opportunities.action')} placement="right">
              <Actions.Item onClick={() => onEdit(opportunity.id)}>
                {t('opportunities.edit')}
              </Actions.Item>
              <Actions.Item onClick={() => onDelete(opportunity.id)}>
                {t('opportunities.delete')}
              </Actions.Item>
            </Actions>
          ) : null}
        </Space>
      </Space>
      <Space className="mt-8" align="end" size="1" direction="vertical">
        <div>
          {t('lead.closeDate')}: {opportunity.close_date ? format(opportunity.close_date) : '---'}
        </div>
        <div>
          {t('lead.created_at')}: {opportunity.created_at ? format(opportunity.created_at) : '---'}
        </div>
      </Space>
      {collapsed && (
        <>
          <div className="opportunity-item__description mt-10">
            {t('opportunities.responsible')}
          </div>
          <AvatarInline
            className="mt-5"
            type="dynamic"
            shortAlt={[
              (opportunity.owner?.first_name || '')[0],
              (opportunity.owner?.last_name || '')[0],
            ].join('')}
            alt={[opportunity.owner?.first_name, opportunity.owner?.last_name].join(' ') || '---'}
            circle
          />
          <div className="opportunity-item__lead-name mt-15">{opportunity?.product?.name}</div>
          <div className="opportunity-item__close-date mt-5">
            {format(opportunity.close_date, dateFormat)}
          </div>
          <div className="opportunity-item__description mb-15">{opportunity?.notes}</div>
        </>
      )}
      <div className="opportunity-item__footer">
        <Space className="opportunity-item__footer-box" justify="space-between">
          <div>
            <div className="opportunity-item__contact-label">{t('opportunities.contact')}</div>
            <div className="opportunity-item__contact">
              {[opportunity.contact?.first_name, opportunity.contact?.last_name].join(' ') || '---'}
            </div>
          </div>
          <Space size={10}>
            <ButtonGroup className="opportunity-item__button-group">
              {opportunity.contact && (
                <a
                  href={
                    mailToken
                      ? `${mailUrl}/apps/mail/${mailToken}/${ServiceTypes.MAIL.toLowerCase()}/compose/?to=${
                          opportunity.contact.email
                        }`
                      : `mailto:${opportunity.contact.email}`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="contact-item__button-group__button" prefix={<Email />} />
                </a>
              )}
              <Tooltip
                placement="bottom"
                bodyClass="p-0 lead-details__tooltip"
                trigger="click"
                tooltip={
                  <SmartSelect
                    options={Object.keys(CallCategoryType).map((type) => ({
                      value: type,
                      text: t(`calls.${type}`),
                    }))}
                    optionsMode="box"
                    onChange={(value) => onCall({ id: opportunity.contact.id, category: value })}
                  />
                }
              >
                <Button className="opportunity-item__button-group__button" prefix={<Phone />} />
              </Tooltip>
            </ButtonGroup>
            <Button
              className={cn('opportunity-item__collapse-button', { collapsed })}
              type="ghost"
              onClick={handleToggleCollapsed}
              prefix={<Icon className="opportunity-item__collapse-icon" component={Arrow} />}
            />
          </Space>
        </Space>
      </div>
    </div>
  );
};
