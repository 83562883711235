import { default as queryString } from 'query-string';
import { SelectOption, Properties } from 'types';

export const getRequest = {
  request: {
    method: 'GET',
  },
};

export const stringifyUrl = (url: string, query: Properties = {}): string =>
  queryString.stringifyUrl(
    {
      url,
      query,
    },
    { skipEmptyString: true, skipNull: true },
  );

export const generateString = (length = 8): string => Math.random().toString(36).slice(-length);

export const generalTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ id, key, title, name, code_name, label }) => ({
    value: id || key,
    text: title || name || code_name || label || '---',
  }));
};

export const userEmailTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ email, title, name, code_name }) => ({
    value: email,
    text: title || name || code_name || '---',
  }));
};

export const listTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ key, label }) => ({
    value: key,
    text: label || '---',
  }));
};

export const userTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ id, first_name, last_name, email }) => ({
    value: id,
    text: [first_name, last_name].filter((i) => i.length).join(' ') || email || '---',
  }));
};
