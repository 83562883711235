import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Col, DatePicker, Form, Input, Row, Space, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { userTransformResults } from 'utils';
import { dateTimeFormat, dateTimeFormatAPI, dateTimeFormatInput, format, toISO } from 'libs';
import { useLogsAPI, useUsersAPI } from 'api';
import { Log, LogAction, LogType } from 'types';

interface Props {
  log?: Log;
  onCloseForm: () => void;
}

export const MeetingForm: React.FC<Props> = ({ log, onCloseForm }) => {
  const { t } = useTranslation();
  const { id: lead } = useParams();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { postMeeting, putLog } = useLogsAPI();
  const { getUsers } = useUsersAPI();

  React.useEffect(() => {
    if (log) {
      form.setFieldsValue({
        ...log,
        meeting_on: log.meeting_on && format(log.meeting_on, dateTimeFormat),
      });
    }
  }, [log]);

  const mutation = useMutation(log ? putLog : postMeeting, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-logs']);
      onCloseForm();
    },
  });

  const handleSubmit = (): void => {
    const { meeting_on, ...data } = form.getFieldsValue();
    mutation.mutate({
      ...data,
      meeting_on: toISO(format(meeting_on, dateTimeFormatAPI, dateTimeFormat)),
      lead,
      id: log?.id,
      action: LogAction.UPDATED,
      type: LogType.MEETING,
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row className="mb-10">
        <Col size={4}>
          <Form.Field name="description" label={t('logs.createEvent')}>
            <Input placeholder={t('logs.createEvent')} />
          </Form.Field>
        </Col>
        <Col size={4}>
          <Form.Field name="meeting_with" label={t('logs.who')}>
            <SmartSelect
              search
              className="small-multi-select"
              mode="multiple"
              api={getUsers}
              transform={userTransformResults}
              placeholder={t('main.select')}
            />
          </Form.Field>
        </Col>
        <Col size={4}>
          <Form.Field name="meeting_on" label={t('logs.dateTime')}>
            <DatePicker showTimeSelect dateFormat={dateTimeFormatInput} />
          </Form.Field>
        </Col>
      </Row>

      <Space justify="space-between">
        <Button type="ghost" size="small" onClick={onCloseForm}>
          {t('buttons.cancel')}
        </Button>
        <Button type="primary" size="small" submit>
          {t('buttons.save')}
        </Button>
      </Space>
    </Form>
  );
};
