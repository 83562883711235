import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useCountriesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getCountries: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/countries`, query), getRequest);
    },
    getCountry: ({ queryKey: [, { id }] }) => {
      return fetcher(`/countries/${id}`, getRequest);
    },
  };
};
