import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Radio, Row, Select, Textarea, useForm, Alert } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { LoaderContext } from 'contexts/LoaderContext';
import {
  useActivityDomainsAPI,
  useBranchesAPI,
  useCitiesAPI,
  useCountriesAPI,
  useIncomesAPI,
  useLeadsAPI,
  useRegionsAPI,
  useSourcesAPI,
  useUsersAPI,
} from 'api';
import { listTransformResults, userTransformResults } from 'utils';
import { ValidationError } from 'errors';
import { ContactsVisit, Lead, LeadStatus } from 'types';

interface ContactFormProps {
  id?: number;
  onSuccess: () => void;
  contact?: ContactsVisit;
}

export const ContactForm: React.FC<ContactFormProps> = ({ id, onSuccess, contact }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);
  const { getLeadById, putLead, postLead } = useLeadsAPI();
  const { getBranches } = useBranchesAPI();
  const { getSources } = useSourcesAPI();
  const { getCountries } = useCountriesAPI();
  const { getRegionsList } = useRegionsAPI();
  const { getCities } = useCitiesAPI();
  const { getIncomes } = useIncomesAPI();
  const { getActivityDomainList } = useActivityDomainsAPI();
  const { getUsers } = useUsersAPI();

  const [regionId, setRegionId] = React.useState<number | undefined>();
  const [isDuplicate, setAsDuplicate] = React.useState<boolean>(false);
  const [isMarkedAsDuplicate, markAsDuplicate] = React.useState<boolean>(false);

  const { data: lead } = useQuery<Lead>(['lead', { id }], getLeadById, {
    enabled: Boolean(id),
    onSuccess: (res) =>
      form.setFieldsValue({
        ...res,
        owner: res?.owner?.id,
        activity_domain: res?.activity_domain?.id,
        address_city: res?.address_city?.id,
        address_country: res?.address_country?.id,
        address_region: res?.address_region?.id,
      }),
  });

  React.useEffect(() => {
    if (contact) {
      form.setFieldsValue({
        contact: {
          first_name: contact?.first_name,
          last_name: contact?.last_name,
          phone_number: contact?.phone_number,
          landline_number: contact?.landline_number,
        },
      });
    }
  }, [contact]);

  const putMutation = useMutation(id ? putLead : postLead, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);

        const alreadyExistsError = e.fields.filter(
          ({ name, errors }) =>
            (name.includes('phone_number') || name.includes('email')) &&
            errors.map((message) => message.includes('already exists')).includes(true),
        );
        setAsDuplicate(alreadyExistsError.length > 0);
      }
    },
    onSuccess,
  });

  const handleSubmit = (): void => {
    putMutation.mutate({ ...form.getFieldsValue(), id });
  };

  React.useEffect(() => {
    if (id) {
      setLoader({ [id]: putMutation.isLoading });
    }
  }, [id, putMutation.isLoading]);

  const onValuesChange = React.useCallback((values) => {
    if (values.address_region) {
      setRegionId(values.address_region);
    }
    markAsDuplicate(!!values.is_duplicate);
  }, []);

  return (
    <Form
      form={form}
      id="lead-edit"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      className="contact-create_form"
    >
      <h3>{t('contacts.contact')}</h3>
      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name={['contact', 'first_name']} label={t('contacts.firstName')}>
            <Input placeholder={t('contacts.firstName')} disabled={!!contact} />
          </Form.Field>
        </Col>
        <Col size={6}>
          <Form.Field name={['contact', 'last_name']} label={t('contacts.lastName')}>
            <Input placeholder={t('contacts.lastName')} disabled={!!contact} />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name={['contact', 'email']} label="Email">
            <Input type="email" placeholder="Email" />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name={['contact', 'landline_number']} label={t('contacts.landline')}>
            <Input placeholder={t('contacts.landline')} />
          </Form.Field>
        </Col>

        <Col size={6}>
          <Form.Field name={['contact', 'phone_number']} label={t('contacts.phoneMobile')}>
            <Input placeholder={t('contacts.phoneMobile')} disabled={!!contact} />
          </Form.Field>
        </Col>
      </Row>

      {(isDuplicate || isMarkedAsDuplicate) && (
        <Row className="mt-20">
          <Col>
            <Alert icon message={t('contacts.duplicateInfo')} outlined type="warning">
              <Form.Field name="is_duplicate" className="duplicate-radio">
                <Radio
                  className="flex-space-between"
                  options={[{ value: 'true', text: t('contacts.duplicateConfirm') }]}
                />
              </Form.Field>
            </Alert>
          </Col>
        </Row>
      )}

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name={['contact', 'job_title']} label={t('contacts.function')}>
            <Input placeholder={t('contacts.designer')} />
          </Form.Field>
        </Col>

        <Col size={6}>
          <Form.Field name={['contact', 'social_url']} label={t('contacts.linkSocialMedia')}>
            <Input placeholder={t('contacts.linkSocialMedia')} />
          </Form.Field>
        </Col>
      </Row>

      <hr />

      <h3>{t('contacts.lead')}</h3>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name="name" label={t('contacts.nameLead')}>
            <Input placeholder={t('contacts.nameLead')} />
          </Form.Field>
        </Col>

        <Col size={6}>
          <Form.Field name="activity_domain" label={t('contacts.activityDomain')}>
            <SmartSelect
              search
              queryKey="activity-domain"
              api={getActivityDomainList}
              placeholder={t('contacts.activityDomain')}
              transform={listTransformResults}
              selected={
                lead?.activity_domain && {
                  value: lead.activity_domain.id,
                  text: lead.activity_domain.name,
                }
              }
            />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name="registration_number" label={t('contacts.registrationNumber')}>
            <Input placeholder={t('contacts.registrationNumber')} />
          </Form.Field>
        </Col>

        <Col size={6}>
          <Form.Field name="website" label="Website">
            <Input placeholder="http://" />
          </Form.Field>
        </Col>
      </Row>

      <hr />

      <h3>{t('contacts.address')}</h3>

      <Row className="mt-20">
        <Col>
          <Form.Field name="address_country" label={t('contacts.country')}>
            <SmartSelect
              search
              queryKey="countries"
              api={getCountries}
              placeholder={t('contacts.country')}
              selected={
                lead?.address_country && {
                  value: lead.address_country.id,
                  text: lead.address_country.name,
                }
              }
            />
          </Form.Field>
        </Col>

        <Col>
          <Form.Field name="address_region" label={t('contacts.region')}>
            <SmartSelect
              search
              queryKey="regions"
              api={getRegionsList}
              placeholder={t('contacts.region')}
              transform={listTransformResults}
              selected={
                lead?.address_region && {
                  value: lead.address_region.id,
                  text: lead.address_region.name,
                }
              }
            />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col>
          <Form.Field name="address_city" label={t('contacts.city')}>
            <SmartSelect
              search
              queryKey="cities"
              api={getCities}
              placeholder={t('contacts.city')}
              filters={regionId ? { region: regionId } : undefined}
              selected={
                lead?.address_city && { value: lead.address_city.id, text: lead.address_city.name }
              }
            />
          </Form.Field>
        </Col>

        <Col>
          <Form.Field name="address_street" label={t('contacts.address')}>
            <Input placeholder={t('main.text')} />
          </Form.Field>
        </Col>
      </Row>

      <hr />

      <h3>{t('contacts.contactDetails')}</h3>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name="type" label={t('contacts.leadType')} className="contact-type-radio">
            <Radio
              options={[
                { text: t('contacts.individual'), value: 'individual' },
                { text: t('contacts.company'), value: 'company' },
              ]}
            />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col>
          <Form.Field name="source" label={t('contacts.source')}>
            <SmartSelect
              search
              queryKey="source"
              api={getSources}
              placeholder={t('contacts.source')}
            />
          </Form.Field>
        </Col>

        <Col>
          <Form.Field name="income_source" label={t('contacts.income')}>
            <SmartSelect
              search
              queryKey="income"
              api={getIncomes}
              placeholder={t('contacts.income')}
            />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col>
          <Form.Field name="branch" label={t('contacts.branch')}>
            <SmartSelect
              search
              queryKey="branch"
              api={getBranches}
              placeholder={t('contacts.branch')}
              transform={listTransformResults}
            />
          </Form.Field>
        </Col>

        <Col>
          <Form.Field name="owner" label={t('contacts.responsiblePerson')}>
            <SmartSelect
              search
              queryKey="users"
              api={getUsers}
              placeholder={t('contacts.responsiblePerson')}
              transform={userTransformResults}
              selected={
                lead?.owner && {
                  value: lead.owner.id,
                  text: `${lead.owner?.first_name} ${lead.owner?.last_name}`,
                }
              }
            />
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col size={6}>
          <Form.Field name="status" label={t('contacts.status')}>
            <Select>
              <Select.Options>
                {Object.values(LeadStatus).map((item, key) => (
                  <Select.Options.Item value={item} key={`status-${key}`}>
                    {t(`statuses.${item}`)}
                  </Select.Options.Item>
                ))}
              </Select.Options>
            </Select>
          </Form.Field>
        </Col>
      </Row>

      <Row className="mt-20">
        <Col>
          <Form.Field
            name="comment"
            label={t('contacts.comment')}
            className="textarea-static-width"
          >
            <Textarea placeholder={t('contacts.description')} />
          </Form.Field>
        </Col>
      </Row>
    </Form>
  );
};
