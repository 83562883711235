import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { Form, Label, Radio, useForm, useNotify, Col, Row } from 'ebs-design';
import { useTaskAPI, useReasonsAPI, useOpportunitiesAPI } from 'api';
import { ValidationError } from 'errors';
import { OpportunityContext } from 'contexts/OpportunityContext';
import { LoaderContext } from 'contexts/LoaderContext';
import { SmartSelect } from 'components/organisms';
import { TaskCallClient, Opportunity } from 'types';
import { generalTransformResults } from 'utils';
interface CallClientProps {
  id?: number;
  leadId: number;
  formId: string;
  onSuccess: () => void;
}

export const CallClient: React.FC<CallClientProps> = ({ id, leadId, formId, onSuccess }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { opportunitiesAllDecline } = React.useContext(OpportunityContext);
  const { setLoader } = React.useContext(LoaderContext);
  const { patchCallClient } = useTaskAPI();
  const { getReasons } = useReasonsAPI();
  const { getLeadOpportunities } = useOpportunitiesAPI();
  const [form] = useForm();
  const [openTextarea, setOpenTextarea] = React.useState(false);

  const { data: opportunities } = useQuery<Opportunity[]>(
    ['opportunities', { id: leadId }],
    getLeadOpportunities,
    {
      enabled: Boolean(leadId),
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const mutation = useMutation(patchCallClient, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({
          title: t('error.someThingIsWrong'),
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      queryClient.invalidateQueries(['tasks']);
      queryClient.invalidateQueries(['lead']);
      queryClient.invalidateQueries(['opportunities']);
      onSuccess();
      if (
        form.getFieldValue('client_response') === TaskCallClient.ACCEPTED &&
        (opportunities?.filter((el) => el.status === 'sent').length || 0) > 1
      ) {
        opportunitiesAllDecline(leadId);
      }
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({
      ...form.getFieldsValue(),
      id,
    });
  };

  const handleValuesChange = (field): void => {
    if (field.client_response) {
      setOpenTextarea(field.client_response === TaskCallClient.DECLINED);
    }
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onValuesChange={handleValuesChange} onFinish={handleSubmit}>
      <Row>
        <Col>
          <Form.Field name="client_response" label={t('task.client_answer')}>
            <Radio
              className="task-form__radio"
              options={[
                {
                  text: (
                    <Label
                      type="fill"
                      status="success"
                      text={t(`task.${TaskCallClient.ACCEPTED}`)}
                    />
                  ),
                  value: TaskCallClient.ACCEPTED,
                },
                {
                  text: (
                    <Label
                      type="fill"
                      status="warning"
                      text={t(`task.${TaskCallClient.WAITING}`)}
                    />
                  ),
                  value: TaskCallClient.WAITING,
                },
                {
                  text: (
                    <Label
                      type="fill"
                      status="danger"
                      text={t(`task.${TaskCallClient.DECLINED}`)}
                    />
                  ),
                  value: TaskCallClient.DECLINED,
                },
              ]}
            />
          </Form.Field>
        </Col>
        {openTextarea && (
          <Col size={6}>
            <Form.Field
              name="reason"
              label={t('task.reason')}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <SmartSelect
                search
                api={getReasons}
                mode="single"
                placeholder={t('task.reason')}
                transform={generalTransformResults}
              />
            </Form.Field>
          </Col>
        )}
      </Row>
    </Form>
  );
};
