import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useCurrenciesAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getCurrencies: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/currencies`, query), getRequest);
    },
    getCurrenciesList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/currencies/list`, query), getRequest);
    },
    getCurrencyById: ({ queryKey: [, { id }] }) => fetcher(`/currencies/${id}`, getRequest),
    createCurrency: (data) => {
      return fetcher('/currencies', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateCurrency: ({ id, ...data }) => {
      return fetcher(`/currencies/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteCurrency: (id: number) => {
      return fetcher(`/currencies/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
