import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useTaskAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getTasks: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/tasks`, query), getRequest);
    },
    getLeadTasks: ({ queryKey: [, { id, ...query }] }) => {
      return fetcher(stringifyUrl(`/leads/${id}/tasks`, query), getRequest);
    },
    getTaskById: ({ queryKey: [, { id }] }) => {
      return fetcher(`/tasks/${id}`, getRequest);
    },
    postTask: (data) => {
      return fetcher('/tasks', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    patchTask: ({ id, ...data }) => {
      return fetcher(`/tasks/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    putTask: ({ id, ...data }) => {
      return fetcher(`/tasks/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteTask: (id: number) => {
      return fetcher(`/tasks/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
    patchSendOffer: ({ id, ...data }) => {
      return fetcher(`/tasks/${id}/send-offer`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    patchCallClient: ({ id, ...data }) => {
      return fetcher(`/tasks/${id}/call-client`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    createRequest: ({ id, ...data }) => {
      return fetcher(`/tasks/${id}/create-request`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
  };
};
