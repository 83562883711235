import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useUsersAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getUsers: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/users`, query), getRequest);
    },

    searchUsers: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/users`, query), getRequest);
    },

    editUsers: (data) => {
      return fetcher(`/users/${data.id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },

    getUsersMarketingReportList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/users/marketing-report`, query), getRequest);
    },
    // editLanguage: (data) => {
    //   return fetcher(`/users/edit/`, {
    //     data,
    //     request: {
    //       method: 'PATCH',
    //     },
    //   });
    // },
  };
};
