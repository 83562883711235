import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useSearchAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getSearch: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/search`, query), getRequest);
    },
  };
};
