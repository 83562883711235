import React from 'react';
import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotifyProvider } from 'ebs-design';
import Layout from 'components/template/Layout';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { Bootstrap } from 'contexts/SessionContext';
import { getBaseUrl, getTokenFromUrl } from 'utils';
import { routes } from './routes';
import { NotFound } from 'components/atoms';

//import * as en from 'locales/en';
import * as ro from 'locales/ro';
import * as ru from 'locales/ru';

const basename = getBaseUrl();

const sassAppToken = process.env.REACT_APP_SAAS_APP_TOKEN || getTokenFromUrl();

const ssoConfig = {
  baseUrl: process.env.REACT_APP_BASE_SSO || '',
};

const fsConfig = {
  token: sassAppToken,
  baseUrl: process.env.REACT_APP_FS_API || '',
};

const crmConfig = {
  token: sassAppToken,
  baseUrl: process.env.REACT_APP_BASE_API || '',
};

const organizationConfig = {
  token: sassAppToken,
  baseUrl: process.env.REACT_APP_ORGANIZATION_API || '',
};

const config = {
  [ServiceTypes.SSO]: ssoConfig,
  [ServiceTypes.CRM]: crmConfig,
  [ServiceTypes.ORGANIZATION]: organizationConfig,
  [ServiceTypes.FS]: fsConfig,
};

//set multi-language
i18n.use(initReactI18next).init({
  resources: {
    //en,
    ro,
    ru,
  },
  lng: localStorage.getItem('language') || 'ro',
  fallbackLng: ['ro', 'ru' /*, 'en' */],

  interpolation: {
    escapeValue: false,
  },
});

export const App: React.FC = () => (
  <BrowserRouter basename={basename}>
    <Bootstrap config={config}>
      <NotifyProvider>
        <Layout>
          <Switch>
            {routes.map(({ Component, ...item }) => (
              <Route key={item.name} name={item.name} path={item.path} exact={item.exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </NotifyProvider>
    </Bootstrap>
  </BrowserRouter>
);
