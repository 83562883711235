import React, { useRef } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { Speed } from './Controls';
import { Call } from 'types';
import { Icon } from 'ebs-design';

interface AudioPlayerProps {
  record: Call;
  onClose: () => void;
  onPlay?: () => void;
  onPause?: () => void;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ record, onClose, onPlay, onPause }) => {
  const player = useRef<H5AudioPlayer>(null);

  return (
    <div id="audio-player-h5" className="audio-player-container">
      <H5AudioPlayer
        autoPlay
        onPlay={onPlay}
        onPause={onPause}
        ref={player}
        src={record.file}
        customAdditionalControls={[<Speed key="speed" player={player} />]}
      />

      <div onClick={onClose} className="audio-player-close">
        <Icon type="close" />
      </div>
    </div>
  );
};
