import { useContext } from 'react';
import { SessionContext } from 'contexts/SessionContext';

export const useRolePermission = (roles: string[]): boolean => {
  const { session } = useContext(SessionContext);

  if (session?.data?.current_application?.application_role) {
    return roles?.includes(session?.data?.current_application?.application_role);
  } else return false;
};
