import * as React from 'react';
import cn from 'classnames';
import { useClickAway } from 'react-use';
import { Button, Checkbox, Form, Icon, useForm } from 'ebs-design';
import { List } from 'resources/icons';

interface MultipleCheckBoxSelectProps {
  callback: (selected: string[]) => void;
  placeHolder: string;
  options: Option[];
  label?: string;
}

interface Option {
  [key: string]: any;

  value?: string;
  checked?: boolean;
}

export const MultipleCheckBoxSelect: React.FC<MultipleCheckBoxSelectProps> = ({
  placeHolder,
  options,
  callback,
  label = 'title',
}) => {
  const ref = React.useRef(null);
  const [form] = useForm();
  const [open, setOpen] = React.useState(false);

  React.useEffect(
    () =>
      form.setFieldsValue(
        (
          options
            .map((item) => (item.checked ? item.value : false))
            .filter((item) => item) as string[]
        ).reduce((acc, curr) => ((acc[curr] = true), acc), {}),
      ),
    [options],
  );

  useClickAway(ref, () => {
    setOpen(false);
  });

  const handleOpen = (): void => {
    setOpen((s) => !s);
  };

  const handleFormChange = (): void => {
    const items = form.getFieldsValue();
    callback(Object.keys(items).filter((i) => items[i]));
  };

  return (
    <div ref={ref} className={cn(`multiple-check-box-select`, { active: open })}>
      <Button
        prefix={<Icon className="multiple-check-box-select__select-icon" component={List} />}
        className="multiple-check-box-select__select"
        onClick={handleOpen}
      >
        {placeHolder}
      </Button>
      <div className="multiple-check-box-select__select-options">
        <div className="multiple-check-box-select__select-options__body">
          <Form form={form} onChange={handleFormChange}>
            {options
              .map((item, key) => ({ ...item, key: key.toString() }))
              .filter((item) => item.checked !== undefined)
              .map((item, key) => (
                <Form.Field
                  key={key}
                  name={item.value}
                  valuePropName="checked"
                  className="multiple-check-box-select__select-options__label"
                >
                  <Checkbox text={item[label]} value={item.key} />
                </Form.Field>
              ))}
          </Form>
        </div>
      </div>
    </div>
  );
};
