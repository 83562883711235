import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';
import { Properties } from 'types';

export const useContactAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadContacts: ({ queryKey: [, { id }] }) => {
      return fetcher(`/leads/${id}/contacts`, getRequest);
    },
    getContactById: ({ queryKey: [, { id }] }) => fetcher(`/contacts/${id}`, getRequest),
    postContact: (data) => {
      return fetcher('/contacts', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    putContact: ({ id, ...data }) => {
      return fetcher(`/contacts/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    patchContact: ({ id, ...data }) => {
      return fetcher(`/contacts/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    deleteContact: (id: number) => {
      return fetcher(`/contacts/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
    postInitCall: ({ id, ...data }) => {
      return fetcher(`/contacts/${id}/init-call`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    sendViber: ({ id, ...data }) => {
      return fetcher(`/contacts/${id}/send-viber-message`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
  };
};
