import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader, Space } from 'ebs-design';
import { useQueryParams } from 'hooks/useQueryParams';
import { dateFormatAPI, format, dateFormat } from 'libs';
import { ReportsCalls, Column, ReportCallsNormalizedToProps } from 'types';
import { useReportsAPI } from 'api';
import { ReportCallsFilter } from 'features/reports/Filters';
import { CallsTableCellTooltip } from './components/CallsTableCellTooltip';
import { useCallsNormalizedData } from './hooks/useCallsNormalizedData';

export const ReportCalls: React.FC = () => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const notify = useNotify();
  const { getReportCalls } = useReportsAPI();

  const { data: reportCalls, isLoading } = useQuery<ReportsCalls[]>(
    [
      'reports-calls',
      {
        ...params,
        date_from: params.date_from && format(params.date_from, dateFormatAPI, dateFormat),
        date_to: params.date_to && format(params.date_to, dateFormatAPI, dateFormat),
        date: undefined,
      },
    ],
    getReportCalls,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { reportCallsNormalized } = useCallsNormalizedData(reportCalls);

  const columns: Column<ReportCallsNormalizedToProps>[] = React.useMemo(
    () => [
      {
        title: t('reports.creditExpert'),
        render: (children) => {
          return {
            children: children.name,
            props: {
              rowSpan: children.name ? 3 : 0,
            },
          };
        },
      },
      {
        title: t('reports.callType'),
        render: ({ call_type, outgoing_calls_outstanding, outgoing_calls_sales }) => (
          <CallsTableCellTooltip
            data={call_type}
            activeTooltip={call_type === t('reports.outbound')}
            inData={outgoing_calls_outstanding}
            outData={outgoing_calls_sales}
          />
        ),
      },
      {
        title: t('reports.totalCalls'),
        dataIndex: 'calls',
      },
      {
        title: t('reports.durationCall'),
        dataIndex: 'calls_duration',
      },
      {
        title: t('reports.leads'),
        dataIndex: 'leads',
      },
      {
        title: t('reports.signed_contracts'),
        dataIndex: 'signed_contracts',
      },
      {
        title: t('reports.contractAmount'),
        dataIndex: 'signed_contracts_value',
      },
      {
        title: t('reports.mediaValContracts'),
        dataIndex: 'avg_signed_contracts_value',
      },
      {
        title: t('reports.pbx_cost'),
        dataIndex: 'pbx_cost',
      },
    ],
    [t, reportCalls],
  );

  return (
    <Card className="overflow-visible">
      <Loader loading={isLoading} size="regular">
        <Card.Header>
          <Space justify="space-between">
            <h3 className="title">{t(`reports.latestLeads`)}</h3>

            <ReportCallsFilter />
          </Space>
        </Card.Header>

        <Table
          emptyText={t('main.noData')}
          data={reportCallsNormalized}
          columns={columns}
          className="no-border reports-table reports-table-calls"
        />
      </Loader>
    </Card>
  );
};
