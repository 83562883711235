import React, { useRef, useMemo, useContext } from 'react';
import cn from 'classnames';
import { CreateConnect } from '@ebs-platform/connect';
import { SessionContext } from 'contexts/SessionContext';

interface Props {
  open: boolean;
  onChangeCount?: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
}

export const Chat: React.FC<Props> = ({ open, onChangeCount, onClose }) => {
  const ref = useRef(null);
  const { session } = useContext(SessionContext);

  const chat = useMemo(
    () =>
      session?.tokens.access &&
      session.data && (
        <CreateConnect
          config={{
            token: session.tokens.access,
            saasAppToken: session.data.available_applications.chat.token,
            secretKey: process.env.REACT_APP_THUMBOR_KEY,
            API_URL: process.env.REACT_APP_CHAT_API,
            API_FILE_SERVICE: process.env.REACT_APP_THUMBOR_SERVER,
            isFullScreen: false,
          }}
          onChangeCount={onChangeCount}
        />
      ),
    [session, onChangeCount],
  );

  return (
    <>
      <div ref={ref} className={cn('connect-wrapper', { 'is-open': open })}>
        {chat}
      </div>
      {open && <div className="ebs-sidebar__mask" onClick={onClose}></div>}
    </>
  );
};
