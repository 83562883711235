import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Loader,
  AvatarInline,
  Form,
  Label,
  Space,
  useForm,
  useNotify,
  Button,
  Checkbox,
  ListGroup,
} from 'ebs-design';
import { dateFormat, format } from 'libs';
import { useDocumentsAPI, useOpportunitiesAPI, useTaskAPI } from 'api';
import { LoaderContext } from 'contexts/LoaderContext';
import { Download } from 'resources/icons';
import { Document, Opportunity, Task } from 'types';
import { ValidationError } from 'errors';

interface CreateRequestProps {
  id?: number;
  leadId: number;
  formId: string;
  onSuccess: () => void;
}

export const CreateRequest: React.FC<CreateRequestProps> = ({ id, leadId, formId, onSuccess }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getTaskById, createRequest } = useTaskAPI();
  const { getOpportunityById } = useOpportunitiesAPI();
  const { getLeadDocuments } = useDocumentsAPI();
  const { setLoader } = React.useContext(LoaderContext);

  const [form] = useForm();
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>([]);

  const { data: task, isLoading: isLoadingTask } = useQuery<Task>(['task', { id }], getTaskById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: opportunity, isLoading: isLoadingOpportunity } = useQuery<Opportunity>(
    ['opportunity', { id: task?.opportunity.id }],
    getOpportunityById,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
      enabled: Boolean(task?.opportunity.id),
    },
  );

  const { data: documents, isLoading: isLoadingDocuments } = useQuery<Document[]>(
    ['documents', { id: leadId }],
    getLeadDocuments,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { mutate: mutateRequest, isLoading } = useMutation(
    (body: { id: number; documents: number[] }) => createRequest(body),
    {
      onError: (e) => {
        if (e instanceof ValidationError) {
          e.fields.map(({ errors }) => {
            notify.error({ title: errors.toString() });
          });
        }
      },
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(['lead-task']);
        queryClient.invalidateQueries(['lead-logs']);
        queryClient.invalidateQueries(['tasks']);
        queryClient.invalidateQueries(['opportunities']);
        queryClient.invalidateQueries(['documents']);
      },
    },
  );

  const handleSubmit = (): void => {
    if (id) {
      mutateRequest({ id, documents: selectedDocuments });
    }
  };

  const onCheckDocument = (id: number, checked: boolean): void => {
    setSelectedDocuments((prevState) =>
      checked ? prevState.filter((value) => value !== id) : [...prevState, id],
    );
  };

  React.useEffect(() => {
    setLoader({ [formId]: isLoading });
  }, [isLoading]);

  return (
    <Form id={formId} form={form} className="create-request" onFinish={handleSubmit}>
      <Loader loading={isLoadingOpportunity || isLoadingTask || isLoadingDocuments}>
        <div className="opportunity-item no-border">
          <Space justify="space-between">
            <Label type="fill" status="success" text={t(`opportunities.${opportunity?.status}`)} />
            <div className="opportunity-item__value">
              {opportunity?.value}
              <span className="opportunity-item__value-currency">
                {opportunity?.currency?.name}
              </span>
            </div>
          </Space>
          <Space justify="space-between">
            <>
              <div className="opportunity-item__description mt-10">
                {t('opportunities.responsible')}
              </div>
              <AvatarInline
                className="mt-5"
                type="dynamic"
                shortAlt={[
                  (opportunity?.owner?.first_name || '')[0],
                  (opportunity?.owner?.last_name || '')[0],
                ].join('')}
                alt={
                  [opportunity?.owner?.first_name, opportunity?.owner?.last_name].join(' ') || '---'
                }
                circle
              />
            </>
            <>
              <div className="opportunity-item__lead-name mt-15">{opportunity?.product?.name}</div>
              <div className="opportunity-item__close-date mt-5">
                {format(opportunity?.close_date, dateFormat)}
              </div>
            </>
          </Space>

          <div className="opportunity-item__description mt-10">{t('task.description')}</div>
          <div className="opportunity-item__description">{opportunity?.notes}</div>
        </div>
        <ListGroup>
          {documents?.length
            ? documents.map((document, key) => {
                const checked = document.id ? selectedDocuments.includes(document.id) : false;

                return (
                  <div
                    key={`${key}-document`}
                    className={document.id ? 'pointer' : undefined}
                    onClick={
                      document.id
                        ? () => onCheckDocument(document.id as number, checked)
                        : undefined
                    }
                  >
                    <ListGroup.Item className={checked ? 'bg-selected' : undefined}>
                      <Space justify="space-between">
                        <Space>
                          {document.id && <Checkbox checked={checked} />}
                          <div className="document-item__description">
                            <span className="document-item__name">{document.name || '---'}</span>
                            <span className="document-item__file">{document.file || '---'}</span>
                          </div>
                        </Space>
                        <a
                          href={document.file}
                          download={document.name}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            className="document-item__button-group__button"
                            prefix={<Download />}
                          />
                        </a>
                      </Space>
                    </ListGroup.Item>
                  </div>
                );
              })
            : t('main.noData')}
        </ListGroup>
      </Loader>
    </Form>
  );
};
