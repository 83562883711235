import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Card, Form, Icon, Input, Modal, Space, useForm } from 'ebs-design';
import { ValidationError } from 'errors';
import { useLeadsListAPI } from 'api';

interface LeadListFormProps {
  onClose: () => void;
}

export const LeadListForm: React.FC<LeadListFormProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { postLeadList } = useLeadsListAPI();
  const [form] = useForm();

  const mutation = useMutation(postLeadList, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['leads-list-select']);
      onClose();
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate(form.getFieldsValue());
  };

  return (
    <Modal
      closeOnClickOutside={false}
      open
      header={<h3>{t('import.addLeadList')}</h3>}
      onClose={onClose}
    >
      <Card>
        <Card.Body>
          <Form id="add-list" form={form} onFinish={handleSubmit}>
            <Form.Field name="name" label={t('import.name')}>
              <Input />
            </Form.Field>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Space justify="space-between">
            <Space>
              <Button onClick={onClose}>{t('buttons.cancel')}</Button>
            </Space>
            <Space>
              <Button
                type="primary"
                submit
                form="add-list"
                prefix={<Icon type="check" model="bold" />}
              >
                {t('buttons.save')}
              </Button>
            </Space>
          </Space>
        </Card.Footer>
      </Card>
    </Modal>
  );
};
