import { useContext, useCallback } from 'react';
import { ServiceTypes } from './interfaces';
import { SessionContext } from 'contexts/SessionContext';
import { buildRequestInit, generateResponseErrorFrom, removeEmpty } from './utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
type TransformFunction = (value: any) => any;
const defaultTransformer: TransformFunction = (data) => data;

/* eslint-enable */

interface FetcherOptions {
  data?: Record<string, unknown>;
  request?: RequestInit;
  transform?: TransformFunction;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FetcherFunc = <T = any>(url: string, options?: FetcherOptions) => Promise<T>;

/**
 * A react hook that uses session context to provide session tokens
 * to the request.
 *
 * Note that this must be used within a `SessionContext` since it
 * uses tokens from session to set them in headers.
 *
 * As first parameter can be provided an enum value of ServiceTypes,
 * but it's optional. It will be setted by default to SSO.
 *
 * @example
 * const fetcher = useFetcher(ServiceTypes.CMS);
 * await fetcher("/some/url", options)
 */
function useFetcher(serviceType: ServiceTypes = ServiceTypes.SSO): FetcherFunc {
  const { session, apiConfig } = useContext(SessionContext);

  if (!apiConfig) {
    throw new Error('Services configuration was not set, please check Bootstrap component');
  }

  const service = apiConfig[serviceType];
  if (!service) {
    throw new Error(`Service config missing for ${serviceType}`);
  }

  const fetcher = useCallback(
    async <T,>(url: string, opts: FetcherOptions = {}): Promise<T> => {
      const { data: body, request = {}, transform = defaultTransformer } = opts;
      const init = buildRequestInit({
        req: { body: JSON.stringify(removeEmpty(body)), ...request },
        session,
        serviceToken: service?.token,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const req = new Request(`${service!.baseUrl}${url}`, init);
      const res = await fetch(req);

      if (res.status === 204) {
        const value = null as unknown;
        return value as T;
      }

      let data: T;
      try {
        data = await res.json();
      } catch (e) {
        return transform({ success: res.ok });
      }
      if (res.ok) {
        return transform(data);
      }

      // only use fields necessary for error generation
      const r1 = {
        url: req.url,
        method: req.method,
        body: request.body,
      };

      const r2 = {
        status: res.status,
        data,
      };

      throw generateResponseErrorFrom(r1, r2);
    },
    [session, service],
  );

  return fetcher;
}

export default useFetcher;
