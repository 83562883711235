import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Row,
  Col,
  Space,
  Modal,
  Card,
  Icon,
  Alert,
  Radio,
} from 'ebs-design';
import { useLeadsAPI } from 'api';
import { ContactsVisit, VisitType } from 'types';
import { SmartSelect } from 'components/organisms';
import { Trash } from 'resources/icons';
import { ContactForm } from 'features/contacts/ContactForm';

interface DynamicFormProps {
  type?: string;
  form: FormInstance<any>;
  contacts: ContactsVisit[];
  isDuplicate?: (number | string)[];
  setDuplicates: React.Dispatch<React.SetStateAction<(number | string)[] | undefined>>;
  setContacts: React.Dispatch<React.SetStateAction<ContactsVisit[]>>;
}

export const DynamicForm: React.FC<DynamicFormProps> = ({
  type,
  form,
  contacts,
  isDuplicate,
  setDuplicates,
  setContacts,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { getLeadsList } = useLeadsAPI();

  const [contact, setContact] = React.useState<ContactsVisit>({});

  const handleRemoveContact = React.useCallback((i) => {
    setDuplicates((prevState) => prevState?.filter((_, y) => i !== y));
    setContacts((prevState) => prevState.filter((_, y) => i !== y));
    if (form?.getFieldValue('contacts')) {
      form.setFieldsValue({
        contacts: [...form?.getFieldValue('contacts')]?.filter((_, y) => y !== i),
      });
    }
  }, []);

  const handleAddContact = React.useCallback(() => {
    setContacts((prevState) => {
      return [
        ...prevState,
        {
          first_name: '',
          last_name: '',
          phone_number: '',
          landline_number: '',
        },
      ];
    });
  }, []);

  const onClose = (): void => {
    setContact({});
  };

  const handleSuccessUpdate = (): void => {
    onClose();
    queryClient.invalidateQueries(['visit']);
  };

  const onRenderFields = React.useMemo(() => {
    if (type === VisitType.PLANNED) {
      return (
        <Row className="mt-20">
          <Col>
            <Form.Field name="lead" label={t('contacts.nameLead')} rules={[{ required: true }]}>
              <SmartSelect
                search
                queryKey="leads-list-select"
                api={getLeadsList}
                placeholder={t('filter.contactList')}
              />
            </Form.Field>
          </Col>
        </Row>
      );
    }

    return (
      <>
        {contacts.map((contact, i) => (
          <Row className="mt-0" key={i} g={4}>
            <Col size={12} md={6}>
              <Form.Field
                name={['contacts', i, 'first_name']}
                label={t('contacts.firstName')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('contacts.firstName')} />
              </Form.Field>
            </Col>
            <Col size={12} md={6}>
              <Form.Field
                name={['contacts', i, 'last_name']}
                label={t('contacts.lastName')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('contacts.lastName')} />
              </Form.Field>
            </Col>
            <Col size={12} md={6}>
              <Form.Field
                name={['contacts', i, 'phone_number']}
                label={t('contacts.phoneMobile')}
                rules={[{ required: true }]}
              >
                <Input type="number" placeholder={t('contacts.phoneMobile')} />
              </Form.Field>
            </Col>
            <Col size={12} md={6}>
              <Space className="visit-form__landline-number" align="end">
                <Form.Field
                  name={['contacts', i, 'landline_number']}
                  label={t('contacts.landlineNumber')}
                >
                  <Input type="number" placeholder={t('contacts.landlineNumber')} />
                </Form.Field>
                <Button
                  icon={Trash}
                  onClick={() => {
                    handleRemoveContact(i);
                  }}
                />
                {!contact.lead &&
                  (contact.id ? (
                    <Button
                      className="visit-form__add-lead"
                      size="small"
                      type="primary"
                      onClick={() => {
                        setContact(contact);
                      }}
                    >
                      {t('visits.addLead')}
                    </Button>
                  ) : id ? (
                    <Button
                      type="primary"
                      submit
                      size="small"
                      form="create-visit"
                      className="visit-form__add-lead"
                    >
                      {t('buttons.save')}
                    </Button>
                  ) : null)}
              </Space>
            </Col>
            {isDuplicate && isDuplicate[i] === i && (
              <Row className="mt-20">
                <Col>
                  <Alert icon message={t('contacts.duplicateInfo')} outlined type="warning">
                    <Form.Field name={['contacts', i, 'is_duplicate']} className="duplicate-radio">
                      <Radio
                        className="flex-space-between"
                        options={[{ value: 'true', text: t('contacts.duplicateConfirm') }]}
                      />
                    </Form.Field>
                  </Alert>
                </Col>
              </Row>
            )}
            {isDuplicate && typeof isDuplicate[i] === 'string' && (
              <Row className="mt-20">
                <Col>
                  <Alert icon message={isDuplicate[i]?.toString()} outlined type="error" />
                </Col>
              </Row>
            )}
          </Row>
        ))}
        <Row className="mt-20" g={4}>
          <Col>
            <Button onClick={handleAddContact}>{t('buttons.addContact')}</Button>
          </Col>
        </Row>
      </>
    );
  }, [type, contacts, form, isDuplicate]);

  return (
    <div>
      {onRenderFields}
      {Object.keys(contact).length > 0 && (
        <Modal
          closeOnClickOutside={false}
          open
          size="regular"
          onClose={onClose}
          className="custom-period"
        >
          <Card>
            <Card.Body>
              <ContactForm onSuccess={handleSuccessUpdate} contact={contact} />
            </Card.Body>
            <Card.Footer>
              <Space justify="space-between">
                <Space>
                  <Button onClick={onClose}>{t('buttons.cancel')}</Button>
                </Space>
                <Space>
                  <Button
                    type="primary"
                    submit
                    form="lead-edit"
                    prefix={<Icon type="check" model="bold" />}
                  >
                    {t('buttons.save')}
                  </Button>
                </Space>
              </Space>
            </Card.Footer>
          </Card>
        </Modal>
      )}
    </div>
  );
};
