import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Textarea,
  useForm,
  useNotify,
} from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { LoaderContext } from 'contexts/LoaderContext';
import { useContactAPI, useCurrenciesAPI, useProductsAPI, useTaskAPI, useUsersAPI } from 'api';
import { listTransformResults, userTransformResults } from 'utils';
import { dateFormat, dateFormatAPI, dateFormatInput, format } from 'libs';
import { ValidationError } from 'errors';
import { Contact, OpportunityStatus } from 'types';

interface SendOfferProps {
  id?: number;
  leadId: number;
  formId: string;
  onSuccess: () => void;
}

export const SendOffer: React.FC<SendOfferProps> = ({ id, leadId, formId, onSuccess }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getUsers } = useUsersAPI();
  const { patchSendOffer } = useTaskAPI();
  const { getCurrenciesList } = useCurrenciesAPI();
  const { getLeadContacts } = useContactAPI();
  const { getProducts } = useProductsAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);

  const { data: contacts, isLoading: isLoadingContact } = useQuery<Contact[]>(
    ['lead-contacts', { id: leadId }],
    getLeadContacts,
    {
      enabled: Boolean(leadId),
    },
  );

  const mutation = useMutation(patchSendOffer, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({
          title: t('error.someThingIsWrong'),
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      queryClient.invalidateQueries(['tasks']);
      queryClient.invalidateQueries(['opportunities']);
      onSuccess();
    },
  });

  const handleSubmit = (): void => {
    const { close_date, ...rest } = form.getFieldsValue();
    mutation.mutate({
      ...rest,
      close_date: close_date && format(close_date, dateFormatAPI, dateFormat),
      id,
    });
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Row>
        <Col size={6} className="mt-10">
          <Form.Field name="value" label={t('task.value')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="currency" label={t('task.currency')}>
            <SmartSelect
              search
              api={getCurrenciesList}
              mode="single"
              transform={listTransformResults}
              placeholder={t('main.select')}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="contact" label={t('task.contact')}>
            <Select loading={isLoadingContact} placeholder={t('main.select')}>
              <Select.Options>
                {contacts?.map((item, key) => (
                  <Select.Options.Item value={item.id} key={`contact-${key}`}>
                    {[item?.first_name, item?.last_name].join(' ') || '---'}
                  </Select.Options.Item>
                ))}
              </Select.Options>
            </Select>
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="owner" label={t('task.owner')}>
            <SmartSelect
              search
              api={getUsers}
              mode="single"
              placeholder={t('main.select')}
              transform={userTransformResults}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="product" label={t('task.product')}>
            <SmartSelect search api={getProducts} mode="single" placeholder={t('main.select')} />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="close_date" label={t('task.close_date')}>
            <DatePicker dateFormat={dateFormatInput} />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="status" label={t('task.status')}>
            <Select placeholder={t('main.select')}>
              <Select.Options>
                {Object.values(OpportunityStatus).map((item, key) => (
                  <Select.Options.Item value={item} key={`status-${key}`}>
                    {t(`opportunities.${item}`)}
                  </Select.Options.Item>
                ))}
              </Select.Options>
            </Select>
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="term" label={t('task.term')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={12} className="mt-10">
          <Form.Field name="notes" label={t('task.notes')} className="textarea-static-width">
            <Textarea />
          </Form.Field>
        </Col>
      </Row>
    </Form>
  );
};
