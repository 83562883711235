import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Modal } from 'ebs-design';

interface MergeLeadFormToProps {
  id: number | null;
  onClose: () => void;
  onSubmit: () => void;
}
export const MergeLeadForm: React.FC<MergeLeadFormToProps> = ({
  id,
  onClose,
  onSubmit,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closeOnClickOutside={false}
      open
      size="small"
      header={<h3>{t('activities.mergeLead')}</h3>}
      onClose={onClose}
    >
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button type="primary" onClick={onSubmit} disabled={!id}>
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
