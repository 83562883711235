import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Column, FilterType, Lead } from 'types';
import { AddressCell } from 'components/molecules';
import { format, dateTimeFormat } from 'libs';

export interface ColumnType<T> extends Column<T> {
  checked?: boolean;
  value?: string;
}

export const useSearchColumns = (): {
  columns: ColumnType<Lead>[];
  setColumns: (value: string[]) => void;
} => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [checked, setChecked] = React.useState<string[]>([]);

  React.useEffect(
    () => setChecked(params.columns || ['id', 'name', 'status', 'primary_contact']),
    [],
  );

  const handleFilter = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const handleChooseColumn = (options): void => {
    setChecked(options);
    updateQuery({ ...params, columns: options });
  };

  const columns = React.useMemo(
    () => [
      {
        title: t('nomenclature.name'),
        value: 'name',
        checked: checked.includes('name'),
        onFilter: (type) => handleFilter(type, 'name'),
        render: ({ name }): React.ReactNode => name,
      },
      {
        title: t('contacts.status'),
        value: 'status',
        checked: checked.includes('status'),
        onFilter: (type) => handleFilter(type, 'status'),
        render: ({ status }): React.ReactNode => t(`statuses.${status}`),
      },
      {
        title: t('contacts.contactData'),
        dataIndex: 'primary_contact',
        value: 'primary_contact',
        checked: checked.includes('primary_contact'),
        render: (props): React.ReactNode =>
          ((props?.first_name || props?.last_name) && `${props?.first_name} ${props?.last_name}`) ||
          '---',
      },
      {
        title: t('contacts.location'),
        value: 'location',
        checked: checked.includes('location'),
        render: ({
          address_country,
          address_region,
          address_city,
          address_street,
        }): React.ReactNode => (
          <AddressCell
            address_country={address_country}
            address_region={address_region}
            address_city={address_city}
            address_street={address_street}
          />
        ),
      },
      {
        title: t('lead.idnp'),
        dataIndex: 'primary_contact',
        render: ({ idnp }) => idnp || '---',
        value: 'primary_contact.idnp',
        checked: checked.includes('primary_contact.idnp'),
      },
      {
        title: t('lead.phoneNumber'),
        dataIndex: 'primary_contact',
        render: ({ phone_number }) => phone_number || '---',
        value: 'primary_contact.phone_number',
        checked: checked.includes('primary_contact.phone_number'),
      },
      {
        title: t('lead.created_at'),
        render: ({ created_at }) => created_at && format(created_at, dateTimeFormat),
        value: 'created_at',
        checked: checked.includes('created_at'),
      },
      {
        title: t('lead.modified_at'),
        render: ({ modified_at }) => modified_at && format(modified_at, dateTimeFormat),
        value: 'modified_at',
        checked: checked.includes('modified_at'),
      },
    ],
    [checked, params],
  );

  return {
    columns,
    setColumns: handleChooseColumn,
  };
};
