import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader, useNotify } from 'ebs-design';
import { ConfirmModal } from 'components/molecules';
import { TaskForm } from 'components/organisms';
import { ActionCard } from 'features/leads/ActionCard';
import { TaskItem } from './TaskItem';
import { CompleteTask } from './CompleteTaks';
import { useTaskAPI } from 'api';
import { Task } from 'types';

const formId = 'add-task';

export const TaskCard: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getLeadTasks, deleteTask } = useTaskAPI();
  const [deleteId, setDeleteId] = React.useState();
  const [editId, setEditId] = React.useState();
  const [completeTask, setCompleteTask] = React.useState<Task | undefined>();

  const { data, isLoading } = useQuery<Task[]>(
    ['lead-task', { id, uncompleted: 1 }],
    getLeadTasks,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const mutation = useMutation(deleteTask, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['lead-task']);
      queryClient.invalidateQueries(['lead-logs']);
      setDeleteId(undefined);
    },
  });

  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const onEdit = (id): void => {
    setEditId(id);
  };

  const handleComplete = (task: Task): void => {
    setCompleteTask(task);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutation.mutate(deleteId);
    }
  };

  return (
    <>
      <ActionCard
        header={t('lead.task')}
        count={data?.length}
        formId={formId}
        editId={editId}
        unsetEdit={() => setEditId(undefined)}
        FormComponent={TaskForm}
      >
        <Loader loading={isLoading}>
          {data?.length
            ? data.map((task, key) => (
                <TaskItem
                  key={key}
                  task={task}
                  onComplete={handleComplete}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))
            : t('main.noData')}
        </Loader>
      </ActionCard>
      {completeTask && (
        <CompleteTask task={completeTask} leadId={id} onCancel={() => setCompleteTask(undefined)} />
      )}
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}
    </>
  );
};
