import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useLogsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadLogs: ({ queryKey: [, { id, ...query }] }) => {
      return fetcher(stringifyUrl(`/leads/${id}/logs`, query), getRequest);
    },
    postMeeting: (data) => {
      return fetcher('/logs/meeting', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    postNote: (data) => {
      return fetcher('/logs/note', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    putLog: ({ id, ...data }) => {
      return fetcher(`/logs/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    patchLog: ({ id, ...data }) => {
      return fetcher(`/logs/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    deleteLog: (id: number) => {
      return fetcher(`/logs/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
