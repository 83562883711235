import * as React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { Button, ButtonGroup } from 'ebs-design';

interface Props {
  player: React.RefObject<AudioPlayer> | null;
}

// Audio playback rates
const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const Speed: React.FC<Props> = ({ player }) => {
  const [speed, setSpeed] = React.useState(player?.current?.audio.current?.playbackRate || 1);
  const handleChange = (speed: number): void => {
    if (player && player.current) {
      const audio = player.current.audio.current;

      if (audio) {
        audio.playbackRate = speed;
        setSpeed(speed);
      }
    }
  };

  return (
    <div className="audio-player-speed">
      <span>Speed:</span>
      <ButtonGroup>
        {speeds.map((item, key) => (
          <Button
            type={speed === item ? 'primary' : 'ghost'}
            onClick={() => handleChange(item)}
            key={key}
          >
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Speed;
