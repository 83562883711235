import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Form, Icon, Modal, useForm, useNotify } from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { useLeadsAPI, useLeadsListAPI } from 'api';
import { useMutation, useQueryClient } from 'react-query';
import { ValidationError } from 'errors';
import { Properties } from 'types';

interface MoveToProps {
  leads: number[];
  queryKey?: string | string[];
  onClose: () => void;
}

export const MoveTo: React.FC<MoveToProps> = ({ leads, queryKey, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [form] = useForm();
  const { getLeadsList } = useLeadsListAPI();
  const { moveToList } = useLeadsAPI();

  const moveToMutation = useMutation(moveToList, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: (e: Properties) => {
      queryClient.invalidateQueries(queryKey || ['contacts']);
      onClose();
      notify.success({ title: e.message });
    },
  });

  const handleSubmit = (): void => {
    moveToMutation.mutate({ ...form.getFieldsValue(), leads });
  };
  return (
    <Modal
      closeOnClickOutside={false}
      open
      header={<h3>{t('contacts.moveTo')}</h3>}
      onClose={onClose}
    >
      <Modal.Content>
        <Form form={form} id="move-to-form" onFinish={handleSubmit}>
          <Form.Field name="list" label={t('contacts.firstName')}>
            <SmartSelect search api={getLeadsList} mode="single" placeholder={t('main.select')} />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button
            prefix={<Icon type="check" model="bold" />}
            type="primary"
            submit
            form="move-to-form"
          >
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
