import * as React from 'react';
import cn from 'classnames';
import { Button, Card, Icon, Modal, Space } from 'ebs-design';
import { LoaderContext } from 'contexts/LoaderContext';
import { Check, Plus } from 'resources/icons';
import { useTranslation } from 'react-i18next';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';

interface ActionCardProps {
  count?: number;
  header: string;
  className?: string;
  backgroundColor?: string;
  formId?: string;
  editId?: number;
  type?: string;
  unsetEdit?: () => void;
  FormComponent?: any;
  isCheckStatus?: boolean;
  countLastOpportunity?: number;
  isOpenStatusModal?: boolean;
  setIsOpenStatusModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCountLastOpportunity?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const ActionCard: React.FC<ActionCardProps> = ({
  count = 0,
  header,
  className,
  backgroundColor,
  formId,
  editId,
  type,
  unsetEdit,
  FormComponent,
  isCheckStatus,
  countLastOpportunity,
  isOpenStatusModal,
  setIsOpenStatusModal,
  setCountLastOpportunity,
  children,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { loader } = React.useContext(LoaderContext);

  React.useEffect(() => {
    if (countLastOpportunity && !isOpenStatusModal) {
      setOpen(true);
    }
  }, [countLastOpportunity, isOpenStatusModal]);

  const handleOpen = (): void => {
    if (isCheckStatus && setIsOpenStatusModal && setCountLastOpportunity) {
      setCountLastOpportunity(0);
      setIsOpenStatusModal(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    if (unsetEdit) {
      unsetEdit();
    }
  };

  const buttonLoading = React.useMemo(() => {
    if (formId && loader) {
      return loader[formId];
    }
    return false;
  }, [formId, loader]);

  return (
    <>
      <Card size="small" className={cn('action-card', className)}>
        <Card.Header className="action-card__header">
          <span className="action-card__count" style={{ backgroundColor: backgroundColor }}>
            {count}
          </span>
          {header}
          {useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.expert,
          ]) && (
            <div className="action-card__add-new" onClick={handleOpen}>
              <Icon component={Plus} />
            </div>
          )}
        </Card.Header>
        <Card.Body className="action-card__body">{children}</Card.Body>
      </Card>
      {(open || editId) &&
        useRolePermission([
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.expert,
        ]) && (
          <Modal closeOnClickOutside={false} open header={header} onClose={handleClose}>
            <Modal.Content>
              {<FormComponent formId={formId} id={editId} closeModal={handleClose} />}
            </Modal.Content>
            <Modal.Footer>
              <Space justify="space-between">
                <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
                {editId && type && ['contracts'].includes(type) ? (
                  <></>
                ) : (
                  <Button
                    type="primary"
                    submit
                    form={formId}
                    loading={buttonLoading}
                    prefix={<Icon className="action-card__save-button" component={Check} />}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Space>
            </Modal.Footer>
          </Modal>
        )}
    </>
  );
};
