import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Textarea,
  useForm,
  useNotify,
} from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { LoaderContext } from 'contexts/LoaderContext';
import { ValidationError } from 'errors';
import {
  useContractAPI,
  useCurrenciesAPI,
  useOpportunitiesAPI,
  useProductsAPI,
  useUsersAPI,
} from 'api';
import { dateFormat, dateFormatAPI, dateFormatInput, format, toISO } from 'libs';
import { listTransformResults, userTransformResults } from 'utils';
import { Contract, ContractStatus, Opportunity } from 'types';

interface ContractFormProps {
  id?: number;
  formId: string;
  closeModal: () => void;
}

export const ContractForm: React.FC<ContractFormProps> = ({ id, formId, closeModal }) => {
  const { t } = useTranslation();
  const { id: leadId } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getUsers } = useUsersAPI();
  const { getProducts } = useProductsAPI();
  const { getCurrenciesList } = useCurrenciesAPI();
  const { getLeadOpportunities } = useOpportunitiesAPI();
  const { getContractById, postContract, patchContract } = useContractAPI();
  const [form] = useForm();
  const { setLoader } = React.useContext(LoaderContext);

  const { data } = useQuery<Contract>(['contracts', { id }], getContractById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: opportunities, isLoading: isLoadingOpportunities } = useQuery<Opportunity[]>(
    ['opportunities', { id: leadId }],
    getLeadOpportunities,
    {
      enabled: Boolean(leadId),
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    if (data) {
      const { currency, owner, opportunity, product, deadline_at, signed_at, ...formData } = data;
      form.setFieldsValue({
        ...formData,
        currency: currency?.id,
        owner: owner?.id,
        opportunity: opportunity?.id,
        product: product?.id,
        deadline_at: deadline_at && format(deadline_at, dateFormat),
        signed_at: signed_at && format(signed_at, dateFormat),
      });
    }
  }, [data]);

  const mutation = useMutation(id ? patchContract : postContract, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['contracts']);
      closeModal();
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    const { deadline_at, signed_at, ...rest } = form.getFieldsValue();
    mutation.mutate({
      ...rest,
      deadline_at: deadline_at && toISO(format(deadline_at, dateFormatAPI, dateFormat)),
      signed_at: signed_at && toISO(format(signed_at, dateFormatAPI, dateFormat)),
      lead: leadId,
      id,
    });
  };

  React.useEffect(() => {
    setLoader({ [formId]: mutation.isLoading });
  }, [mutation.isLoading]);

  return (
    <Form id={formId} form={form} onFinish={handleSubmit}>
      <Row>
        <Col size={6} className="mt-10">
          <Form.Field name="value" label={t('contracts.value')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="currency" label={t('contracts.currency')}>
            <SmartSelect
              search
              api={getCurrenciesList}
              mode="single"
              transform={listTransformResults}
              placeholder={t('main.select')}
              selected={data?.currency && { value: data.currency.id, text: data.currency.name }}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="number" label={t('contracts.contractNumber')}>
            <Input />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="owner" label={t('contracts.owner')}>
            <SmartSelect
              search
              api={getUsers}
              mode="single"
              placeholder={t('main.select')}
              transform={userTransformResults}
              selected={
                data?.owner && {
                  value: data.owner.id,
                  text: `${data.owner?.first_name} ${data.owner?.last_name}`,
                }
              }
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="opportunity" label={t('contracts.opportunity')}>
            <Select placeholder={t('main.select')} loading={isLoadingOpportunities}>
              <Select.Options>
                {opportunities?.map((item, key) => (
                  <Select.Options.Item value={item.id} key={`status-${key}`}>
                    {`${item.value} ${item.currency.name} - ${
                      item.close_date ? format(item.close_date, dateFormat) : '---'
                    }`}
                  </Select.Options.Item>
                ))}
              </Select.Options>
            </Select>
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="product" label={t('contracts.product')}>
            <SmartSelect
              search
              api={getProducts}
              mode="single"
              placeholder={t('main.select')}
              selected={data?.product && { value: data.product.id, text: data.product.name }}
            />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="status" label={t('contracts.status')}>
            <Select placeholder={t('main.select')}>
              <Select.Options>
                {Object.values(ContractStatus).map((item, key) => (
                  <Select.Options.Item value={item} key={`status-${key}`}>
                    {t(`contracts.${item}`)}
                  </Select.Options.Item>
                ))}
              </Select.Options>
            </Select>
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="signed_at" label={t('contracts.signedAt')}>
            <DatePicker dateFormat={dateFormatInput} />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="deadline_at" label={t('contracts.deadline')}>
            <DatePicker dateFormat={dateFormatInput} />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="balance_loan" label={t('contracts.balanceLoan')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="balance_overdue" label={t('contracts.balanceOverdue')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={6} className="mt-10">
          <Form.Field name="outstanding_days" label={t('contracts.outstandingDays')}>
            <Input type="number" />
          </Form.Field>
        </Col>
        <Col size={12} className="mt-10">
          <Form.Field
            name="pledged_goods"
            label={t('contracts.pledgedGoods')}
            className="textarea-static-width"
          >
            <Textarea />
          </Form.Field>
        </Col>
      </Row>
    </Form>
  );
};
