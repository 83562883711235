import * as React from 'react';
import { DetailsCol } from '../DetailsCol';
import { ActivityCol } from '../ActivityCol';
import { OpportunityContextProvider } from 'contexts/OpportunityContext';

export const LeadsDetails: React.FC = () => {
  return (
    <OpportunityContextProvider>
      <div className="lead-details">
        <div className="lead-details__left-col">
          <DetailsCol />
        </div>
        <div className="lead-details__right-col">
          <ActivityCol />
        </div>
      </div>
    </OpportunityContextProvider>
  );
};
