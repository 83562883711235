import * as React from 'react';
import { useCookie } from 'react-use';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Loader } from 'ebs-design';
import SessionProvider, {
  accessTokenKey,
  refreshTokenKey,
  saasTokenKey,
} from 'contexts/SessionContext/SessionProvider';
import { useDomain } from 'hooks/useDomain';
import { StatusTypes, ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getTokenFromUrl, getBaseUrl } from 'utils';

import { BootstrapProps, BootstrapState } from './interface';
import * as utils from './utils';
import { getUrlDomain } from './utils';

const initialState: BootstrapState = {
  status: StatusTypes.PENDING,
  error: null,
  session: null,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const loginUrl = '/authentication/login';

export const Bootstrap: React.FC<BootstrapProps> = ({
  config,
  options = { hasOrganization: true },
  children,
}) => {
  const { active, getUrl } = useDomain();
  const [, updateCookie] = useCookie('Authorization');
  const [servicesConfig] = React.useState(config);
  const [state, setState] = React.useState<BootstrapState>(initialState);
  const { status, session, error } = state;

  const onRedirect = React.useCallback(
    () =>
      active && getBaseUrl() !== loginUrl && window.open(`${getUrl(active)}${loginUrl}`, '_self'),
    [active],
  );

  const refreshSession = React.useCallback(async (): Promise<void> => {
    const token = window.localStorage.getItem(refreshTokenKey);

    if (token) {
      const [hasError, data] = await utils.refreshToken(servicesConfig[ServiceTypes.SSO], token);

      if (!hasError && data?.access) {
        const tokens = { access: data.access, refresh: token };

        const [hasErrorUser, dataUser] = await utils.fetchUser(
          servicesConfig[ServiceTypes.ORGANIZATION],
          tokens,
        );

        if (!hasErrorUser && dataUser) {
          window.localStorage.setItem(accessTokenKey, tokens.access);
          window.localStorage.setItem(saasTokenKey, getTokenFromUrl());
          updateCookie(tokens.access, { domain: `.${getUrlDomain()}` });

          setState({
            ...state,
            status: StatusTypes.RESOLVED,
            session: {
              data: dataUser,
              tokens,
            },
          });
        }
      } else {
        onRedirect();
      }
    } else {
      onRedirect();
    }
  }, [servicesConfig]);

  React.useEffect(() => {
    utils.handleSessionCheckEffect(servicesConfig, setState, options);
  }, [servicesConfig]);

  React.useEffect(() => {
    if (status === StatusTypes.REJECTED) {
      refreshSession();
    }
  }, [status, error, refreshSession]);

  if (status === StatusTypes.PENDING) {
    return (
      <div className="root-spinner">
        <Loader.Spinner />
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider
        initialSession={session}
        apiConfig={servicesConfig}
        options={options}
        status={status}
        onRedirect={onRedirect}
      >
        {children}
      </SessionProvider>
    </QueryClientProvider>
  );
};
