import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader, Space, Button } from 'ebs-design';
import { useVisitsAPI } from 'api';
import { Column, ContactsVisit, Visit } from 'types';
import { Put } from 'components/atoms';

export const VisitContacts: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const { getVisitById } = useVisitsAPI();

  const { data: visit, isLoading } = useQuery<Visit>(['visit', { id }], getVisitById, {
    enabled: Boolean(id),
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const columns: Column<ContactsVisit>[] = React.useMemo(
    () => [
      {
        title: t('contacts.firstName'),
        render: ({ first_name }) => <Put>{first_name}</Put>,
      },
      {
        title: t('contacts.lastName'),
        render: ({ last_name }) => <Put>{last_name}</Put>,
      },

      {
        title: t('contacts.phoneNumber'),
        render: ({ phone_number }) => <Put>{phone_number}</Put>,
      },
      {
        title: t('contacts.landlineNumber'),
        render: ({ landline_number }) => <Put>{landline_number}</Put>,
      },
    ],
    [t, visit, id],
  );

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <h3 className="title">{`${t(`visits.visitContacts`)} (${
          visit?.contacts?.length || 0
        })`}</h3>
        <Link to={{ pathname: '/visits' }}>
          <Button type="primary">{t('visits.backVisits')}</Button>
        </Link>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            data={visit?.contacts as ContactsVisit[]}
            columns={columns}
            className="contacts-table no-border"
            emptyText={t('main.noData')}
          />
        </Loader>
      </Card>
    </>
  );
};
