import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { List, Results, Properties } from 'types';

export const useActivityDomainsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getActivityDomains: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/activity-domains`, query), getRequest);
    },
    getActivityDomainList: ({ queryKey: [, query] }): Promise<Results<List>> => {
      return fetcher(stringifyUrl(`/activity-domains/list`, query), getRequest);
    },
    getActivityDomainById: ({ queryKey: [, { id }] }) =>
      fetcher(`/activity-domains/${id}`, getRequest),

    createActivityDomain: (data) => {
      return fetcher('/activity-domains', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateActivityDomain: ({ id, ...data }) => {
      return fetcher(`/activity-domains/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteActivityDomain: (id: number) => {
      return fetcher(`/activity-domains/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
