import * as React from 'react';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Select, Space, Button } from 'ebs-design';
import { CustomPeriod, Filters, PeriodType } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { Calendar, List, UserAlone, Plus } from 'resources/icons';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { useUsersAPI } from 'api';
import { defaultNonPagination, getPeriodByName } from 'utils';
import { OpportunityStatus, Results, User } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';

interface KanbanFilterProps {
  count?: string;
}

interface FiltersProps {
  search?: string;
  owner?: string;
  status?: string;
  ordering?: string;
  created_at?: string;
  created_at_from?: string;
  created_at_to?: string;
}

export const KanbanFilter: React.FC<KanbanFilterProps> = ({ count }) => {
  const { t } = useTranslation();

  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getUsers } = useUsersAPI();

  const [openCalendarPicker, setOpenCalendarPicker] = React.useState(false);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [filters, setFilters] = useSetState<FiltersProps>({
    ...params,
  });

  const { data: users } = useQuery<Results<User>>(
    ['users', { ...defaultNonPagination, ...usersFilter }],
    getUsers,
  );

  React.useEffect(() => {
    if (!filters.created_at) {
      updateQuery({ ...filters, created_at_from: undefined, created_at_to: undefined });
    } else {
      updateQuery(filters);
    }
  }, [filters, updateQuery]);

  const handleCustomPeriod = (created_at_from: string, created_at_to: string): void => {
    setFilters({ created_at_from, created_at_to, created_at: 'custom' });
    setOpenCalendarPicker(false);
  };

  const handleFilters = (value): void => {
    if (value?.created_at !== undefined) {
      if (value?.created_at === 'custom') {
        setOpenCalendarPicker(true);
      } else {
        const [created_at_from, created_at_to] = getPeriodByName(value.created_at);
        setFilters({ created_at_from, created_at_to, ...value });
      }
    } else {
      setFilters(value);
    }
  };

  return (
    <>
      {openCalendarPicker && (
        <CustomPeriod
          onClose={() => setOpenCalendarPicker(false)}
          onChange={handleCustomPeriod}
          initialValue={
            [
              params.created_at_from && format(params.created_at_from, dateFormatAPI, dateFormat),
              params.created_at_to && format(params.created_at_to, dateFormatAPI, dateFormat),
            ] as PeriodType
          }
        />
      )}
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`lead.leads`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
          {useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin]) && (
            <Filters.Select
              prefix={<Icon component={UserAlone} />}
              placeholder={t('filter.creditExpert')}
              value={params.owner}
              field="owner"
            >
              <Select.Options>
                {(users?.results || []).map((item, key) => (
                  <Select.Options.Item key={`${key}-user`} value={item.id}>
                    {item?.first_name || item?.last_name
                      ? `${item?.first_name} ${item?.last_name}`
                      : item.email}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Select.Pagination
                count={users?.count || 0}
                limit={usersFilter.per_page}
                page={usersFilter.page}
                setPage={(page) => setUsersFilter({ page })}
                mode="scroll"
              />
            </Filters.Select>
          )}
          <Filters.Select
            prefix={<Icon component={List} />}
            placeholder={t('filter.status')}
            value={params.status}
            field="status"
          >
            <Select.Options>
              {Object.values(OpportunityStatus).map((item, key) => (
                <Select.Options.Item key={`${key}-type`} value={item}>
                  {t(`opportunities.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
          <Filters.Select
            className="period-drop-list"
            prefix={<Icon component={Calendar} />}
            placeholder={t('filter.created_at')}
            field="created_at"
            value={params.created_at}
          >
            <Select.Options>
              <Select.Options.Item value="today">{t('filter.today')}</Select.Options.Item>
              <Select.Options.Item value="week">{t('filter.this_week')}</Select.Options.Item>
              <Select.Options.Item value="month">{t('filter.this_month')}</Select.Options.Item>
              <Select.Options.Item value="quarter">{t('filter.this_quarter')}</Select.Options.Item>
              <Select.Options.Item value="year">{t('filter.this_year')}</Select.Options.Item>
              <Select.Options.Item value="yesterday">{t('filter.yesterday')}</Select.Options.Item>
              <Select.Options.Item value="previous_week" className="period-drop-list__separator">
                {t('filter.previous_week')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_month">
                {t('filter.previous_month')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_year">
                {t('filter.previous_year')}
              </Select.Options.Item>
              <Select.Options.Item value="all_time">{t('filter.all_time')}</Select.Options.Item>
              <Select.Options.Item
                value="custom"
                className="period-drop-list__custom period-drop-list__separator"
              >
                {t('filter.custom')}
              </Select.Options.Item>
            </Select.Options>
          </Filters.Select>
          <Filters.Sort
            options={[
              { title: t('filter.sum'), value: 'opportunity_value' },
              { title: t('filter.createDate'), value: 'created_at' },
            ]}
            value={params.ordering}
            field="ordering"
            placeholder={t('filter.sortBy')}
            sortByTitle={t('filter.by')}
          />
        </Space>
        <Space justify="end">
          <Link to={{ pathname: '/contacts/create', search: '?back=/sales-funnel' }}>
            <Button type="primary" prefix={<Icon component={Plus} />}>
              {t('buttons.addContact')}
            </Button>
          </Link>
        </Space>
      </Filters>
    </>
  );
};
