import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useLeadsAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeads: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/leads`, query), getRequest);
    },
    getLeadsPipeline: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/leads/pipeline`, query), getRequest);
    },
    getLeadsSalesFunnel: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/leads/sales-funnel`, query), getRequest);
    },
    getLeadById: ({ queryKey: [, { id }] }) => fetcher(`/leads/${id}`, getRequest),
    getLeadLists: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/lead-lists`, query), getRequest);
    },
    getLeadsList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/leads/list`, query), getRequest);
    },
    getLeadsAffiliate: ({ queryKey: [, { id }] }) => fetcher(`/leads/${id}/similar`, getRequest),
    postLead: (data) => {
      return fetcher('/leads', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    patchLead: ({ id, ...data }) => {
      return fetcher(`/leads/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    putLead: ({ id, ...data }) => {
      return fetcher(`/leads/${id}`, {
        data,
        request: {
          method: 'PUT',
        },
      });
    },
    deleteLeads: (data: any) => {
      return fetcher(`/leads/bulk-delete`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    assigneeTo: (data: any) => {
      return fetcher('/leads/bulk-assignee', {
        request: {
          method: 'PATCH',
          body: JSON.stringify(data),
        },
      });
    },
    changeStatus: (data: any) => {
      return fetcher('/leads/bulk-change-status', {
        request: {
          method: 'PATCH',
          body: JSON.stringify(data),
        },
      });
    },
    moveToList: (data: any) => {
      return fetcher('/leads/move-to-list', {
        request: {
          method: 'PATCH',
          body: JSON.stringify(data),
        },
      });
    },
    merge: ({ id, ...data }) => {
      return fetcher(`/leads/${id}/merge`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
  };
};
