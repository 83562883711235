import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { stringifyUrl, getRequest } from 'utils';
import { Properties } from 'types';

export const useLeadsListAPI = (): Properties => {
  const fetcher = useFetcher(ServiceTypes.CRM);

  return {
    getLeadsList: ({ queryKey: [, query] }) => {
      return fetcher(stringifyUrl(`/lead-lists`, query), getRequest);
    },
    postLeadList: (data) => {
      return fetcher('/lead-lists', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
  };
};
